import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatchDisplay } from './patch';
import { firstValueFrom } from 'rxjs';
import { PATCH_URL } from '../generic/backend-urls';

/**
 * A service for the patches.
 */
@Injectable({
  providedIn: 'root'
})
export class PatchService {

  /**
   * The constructor.
   *
   * @param http The http client.
   */
  constructor(private http: HttpClient) {

  }

  /**
   * Returns all existing patches.
   *
   * @return All existing patches.
   */
  async findAllPatches(): Promise<PatchDisplay[]> {
    return firstValueFrom(this.http.get<PatchDisplay[]>(PATCH_URL + "/find-all"));
  }

}
