import { booleanAttribute, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotOwnedCommandDisplay } from '../command';
import { Feature } from '../../feature/feature';
import { AuthenticationService } from '../../authentication/authentication.service';
import { UserEdit } from '../../user/user';
import { InternalErrorException } from '../../generic/errors';
import { ACCEPT_IMG, LOADING_GIF, MAGNIFIER_IMG, REMOVE_IMG } from '../../generic/img-pathes';
import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Router } from '@angular/router';
import { UserLevel } from '../userLevel/user-level';
import { NotOwnedCommandsService } from './not-owned-commands.service';

/**
 * The component for the not owned commands.
 */
@Component({
  selector: 'tu-not-owned-commands',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgStyle
  ],
  templateUrl: './not-owned-commands.component.html',
  styleUrl: '../commands.css',
})
export class NotOwnedCommandsComponent implements OnInit {

  /**
   * Holds the path to the magnifier image.
   */
  magnifierImg: string = MAGNIFIER_IMG;

  /**
   * Holds the path to the accept image.
   */
  acceptImg: string = ACCEPT_IMG;

  /**
   * Holds the path to the remove image.
   */
  removeImg: string = REMOVE_IMG;

  /**
   * Holds the gif to display when we are currently loading the commands.
   */
  loadingImg: string = LOADING_GIF;

  /**
   * Holds if this component was created for the not owned commands from the links.
   */
  @Input({alias:'forLinks', transform: booleanAttribute})
  forLinks: boolean = false;

  /**
   * Holds if this component was created for the not owned commands from the channel.
   */
  @Input({alias:'forChannel', transform: booleanAttribute})
  forChannel: boolean = false;

  /**
   * Holds if this component was created for the not owned commands from the channel.
   */
  @Input({alias:'forSearch', transform: booleanAttribute})
  forSearch: boolean = false;

  /**
   * Holds an emitter for adding a not owned command.
   */
  @Output('addRequest')
  addRequest = new EventEmitter<NotOwnedCommandDisplay>();

  /**
   * Holds an emitter for removing a not owned command.
   */
  @Output('removeRequest')
  removeRequest = new EventEmitter<NotOwnedCommandDisplay>();

  /**
   * Holds if the commands are initializing.
   */
  initializingCommands: boolean = true;

  /**
   * The commands which can be selected from the user.
   */
  commands: NotOwnedCommandDisplay[] = [];

  /**
   * The constructor.
   *
   * @param notOwnedCommandsService The not owned commands service.
   * @param authenticationService   The authentication service.
   * @param router                  The router.
   */
  constructor(private notOwnedCommandsService: NotOwnedCommandsService, private authenticationService: AuthenticationService, private router: Router) {

  }

  ngOnInit(): void {
    this.initCommands();
    this.authenticationService.subscribeToUserChange((user: UserEdit) => {
      this.initCommands();
    });
  }

  /**
   * Initializes the commands and everything needed for them.
   *
   * @param notOwnedCommandsFromUser The commands not owned by the user but used by him/her.
   */
  initCommands() {
    this.initializingCommands = true;
    if (this.forSearch) {
      this.notOwnedCommandsService.findNotOwnedCommandsForSearch(this.forLinks, this.forChannel)
        .then((notOwnedCommands) => {
          this.commands = notOwnedCommands;
          this.initializingCommands = false;
        });
    } else if (this.forLinks) {
      this.commands = this.notOwnedCommandsService.findNotOwnedCommandsForLinks();
      this.initializingCommands = false;
    } else if(this.forChannel) {
      this.commands = this.notOwnedCommandsService.findNotOwnedCommandsForChannel();
      this.initializingCommands = false;
    } else {
      this.commands = this.notOwnedCommandsService.findNotOwnedCommandsForUser();
      this.initializingCommands = false;
    }
  }

  /**
   * Moves to the search page for the commands.
   */
  goToSearch() {
    let routeUrl: string;
    if (this.forChannel) {
      routeUrl = "channel"
    } else if (this.forLinks) {
      routeUrl = "links"
    } else {
      routeUrl = "commands"
    }
    const withForLinks = this.forLinks.toString();
    const withForChannel = this.forChannel.toString();
    this.router.navigate(['commands/search'], {queryParams: {routeURL: routeUrl, forLinks: withForLinks, forChannel: withForChannel}});
  }
g
  /**
   * Adds the provided command to the user.
   *
   * @param command The command.
   */
  addCommand(command: NotOwnedCommandDisplay) {
    if (this.addRequest.observed) {
      this.addRequest.emit(command);
    } else {
      throw new InternalErrorException("addCommand was called without an addRequest");
    }
  }

  /**
   * Removes the provided command from the user.
   *
   * @param command The command.
   */
  removeCommand(command: NotOwnedCommandDisplay) {
    if (this.removeRequest.observed) {
      this.removeRequest.emit(command);
    } else {
      throw new InternalErrorException("removeCommand was called without a removeRequest");
    }
  }

  /**
   * Returns the value of the provided user level.
   *
   * @returns The value of the provided user level.
   */
  getUserLevelValue(userLevel: UserLevel): UserLevel {
      return UserLevel[userLevel];
  }

  /**
   * Returns the value of the provided feature.
   *
   * @returns The value of the provided Feature.
   */
  getFeatureValue(feature: Feature): Feature {
      return Feature[feature];
  }

  /**
   * Returns the style to be used for the provided command.
   *
   * @param notOwnedCommand The command.
   * @returns The style to be used for the provided command.
   */
  createCellStyle(notOwnedCommand: NotOwnedCommandDisplay): Object {
    return {'background-color': this.disableCommand(notOwnedCommand) ? 'gray' : 'deeppink'};
  }

  /**
   * Returns if the command shall be disabled.
   *
   * @param notOwnedCommand The command.
   * @returns true if the command shall be disabled, false otherwise.
   */
  disableCommand(notOwnedCommand: NotOwnedCommandDisplay): boolean {
    if (this.forSearch) {
      const doesUserHaveCommand: boolean = this.alreadyUsed(notOwnedCommand);
      if (doesUserHaveCommand) {
        return true;
      }
    }

    const allowed: boolean = this.notOwnedCommandsService.isCommandAllowed(notOwnedCommand);
    if (!allowed) {
      return true;
    }
    return false;
  }

  /**
   * Returns if the user already uses the provided command.
   *
   * @param notOwnedCommand The command.
   * @returns true if the user already uses the provided command, false otherwise.
   */
  alreadyUsed(notOwnedCommand: NotOwnedCommandDisplay): boolean {
    return this.notOwnedCommandsService.doesUserUseCommand(notOwnedCommand);
  }

  /**
   * Returns the name of the missing feature for the provided command, or null if non exists.
   *
   * @param notOwnedCommand The not owned command to check.
   * @returns The name missing feature for the provided command, or null if non exists.
   */
  findMissingFeature(notOwnedCommand: NotOwnedCommandDisplay): string {
    let missingFeature: Feature = this.notOwnedCommandsService.findMissingFeature(notOwnedCommand);
    if (missingFeature == null) {
      return null;
    }
    return Feature[missingFeature];
  }
}
