import { IdentifiedEntity } from "./identified-entity";

/**
 * Filters the provided identified from the array. It creates a new one.
 *
 * @param array      The array.
 * @param identified The identified.
 * @returns The filtered array. It creates a new one!
 */
export function filterIdentified<T extends I, I extends IdentifiedEntity>(array: T[], identified: I): T[] {
    return array.filter(ident => ident.id != identified.id);
}

/**
 * Pushes the provided identified to the array if it isn't contained already.
 *
 * @param array      The array.
 * @param identified The identified.
 */
export function pushIdentifiedIfAbsent<T extends I, I extends IdentifiedEntity>(array: T[], identified: T): void {
    if (!array.some(ident => ident.id == identified.id)) {
        array.push(identified);
    }
}

/**
 * Returns if the provided identified is inside the array.
 *
 * @param array      The array.
 * @param identified The identified.
 * @returns true if the provided identified is inside the array, false otherwise.
 */
export function containsIdentified<T extends I, I extends IdentifiedEntity>(array: T[], identified: I): boolean {
    return array.some(ident => ident.id == identified.id);
}

/**
 * Returns if the provided value is inside the array.
 *
 * @param array The array.
 * @param value The value.
 * @returns true if the provided value is inside the array, false otherwise.
 * @deprecated Use array.some(v => v == value) instead.
 */
export function arrayContains<T>(array: T[], value: T): boolean {
    return array.some(v => v == value);
}
