import { Directive, Input, numberAttribute } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * A validator for a name.
 */
@Directive({
  selector: '[nameValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NameValidator,
      multi: true,
    },
  ],
  standalone: true,
})
export class NameValidator implements Validator {

  /**
   * Holds the max length of the name.
   * Default: 16
   */
  @Input({alias:'maxLength', transform: numberAttribute})
  maxLength: number = 16;

  /**
   * Holds the already existing names.
   * Default: empty
   */
  @Input("existingNames")
  existingNames: Map<number, string> = new Map<number, string>();

  /**
   * Holds the id of the object to check.
   */
  @Input("toCheckId")
  toCheckId: number;

  validate(control: AbstractControl): ValidationErrors | null {
    const nameToCheck: string = control.value;
    if (nameToCheck == null) {
      return null;
    }

    const startsExclamation: boolean = nameToCheck.startsWith("!");
    const containsBlank: boolean = nameToCheck.includes(" ");
    const toLong: boolean = nameToCheck.length > this.maxLength;
    let alreadyExists: boolean = false;
    for (let [id, name] of this.existingNames.entries()) {
      if (this.toCheckId && id == this.toCheckId) {
        continue;
      }
      if (nameToCheck == name) {
        alreadyExists = true;
        break;
      }
    }

    if (startsExclamation || containsBlank || toLong || alreadyExists) {
      return {
        startsExclamation: startsExclamation,
        containsBlank: containsBlank,
        toLong: toLong,
        alreadyExists: alreadyExists
      };
    }

    return null;
  }
}
