import { Injectable } from '@angular/core';
import { CounterEdit } from './counter';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { COUNTER_URL } from '../generic/backend-urls';
import { UserEdit } from '../user/user';
import { AuthenticationService } from '../authentication/authentication.service';
import { InternalErrorException } from '../generic/errors';
import { NOT_ACCEPTABLE } from '../generic/http-status';

/**
 * A service for the counters.
 */
@Injectable({
  providedIn: 'root'
})
export class CountersService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   */
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {

  }

  /**
   * Saves the provided counter.
   *
   * @param counter The counter.
   * @returns The promise from saving the counter.
   */
  save(counter: CounterEdit): Promise<UserEdit> {
    const result: Promise<UserEdit> = firstValueFrom(this.http.post<UserEdit>(COUNTER_URL + "/save", counter));
    result.then(user => {
      this.authenticationService.refreshUser(user);
    })
    .catch((err: HttpErrorResponse) => {
      let status = Number(err.status);
      if (status == NOT_ACCEPTABLE) {
        this.authenticationService.reloadUser();
      } else {
        const errorMessage = this.createErrorMessage(counter, "save");
        throw new InternalErrorException(errorMessage, err)
      }
    });
    return result;
  }

  /**
   * Deletes the provided counter.
   *
   * @param counter The counter.
   * @returns The promise from the deletion of the counter.
   */
  delete(counter: CounterEdit): Promise<UserEdit> {
    const errorMessage = this.createErrorMessage(counter, "delete");
    const result: Promise<UserEdit> = firstValueFrom<UserEdit>(this.http.post<UserEdit>(COUNTER_URL + "/delete", counter));
    result.then(user => {
      this.authenticationService.refreshUser(user);
    })
    .catch((err: HttpErrorResponse) => {
      let status = Number(err.status);
      if (status == NOT_ACCEPTABLE) {
        this.authenticationService.reloadUser();
      } else {
        throw new InternalErrorException(errorMessage, err)
      }
    });

    return result;
  }


  /**
   * Creates an error message for the provided command with the reason inside it.
   *
   * @param counter The counter.
   * @param reason  The reason.
   * @returns An error message.
   */
  private createErrorMessage(counter: CounterEdit, reason: string): string {
    const isCreated = counter.id == -1;
    const counterMessage = reason + " the counter '" + counter.name  + (!isCreated ? " (ID: " + counter.id + ") " : "");
    const errorMessage = "An error occurred for user " + this.authenticationService.getUser().id + " while trying to " + counterMessage;
    return errorMessage;
  }
}
