@if (!loading) {
    <h1>Was noch kommt?</h1>
    Auf dieser Seite siehst du, was für die Zukunft geplant ist. Es wird in der Reihenfolge vollbracht, in welcher es hier aufgelistet ist und es kann jederzeit etwas dazu kommen.<br>
    <span *ngIf="isLoggedIn()">Solltest du etwas bevorzugen, dann gib ihm einen Daumen nach oben, sollte dir etwas unwichtig erscheinen, einen Daumen nach unten.
    Ich kann dir nicht versprechen, dass ich es dadurch direkt ändere, sondern nur, wenn es von mehreren gewählt wird oder es mir Sinnvoll erscheint.</span>
    Falls dir irgendwas einfällt, was du hier gerne dabei hättest, dann schreib eine E-Mail an features&#64;pinkpanth3r.de.

    @for (whatsNext of whatsNexts; track $index; let i = $index) {
        <div class="whats-next-header">
            <span class="enumeration">{{i + 1}})</span> {{whatsNext.name}}
            @if (isLoggedIn()) {
                <img *ngIf="!isVotedUp(whatsNext)" [src]="thumbsUpImg" (click)="updateVote(whatsNext, true)" class="button-img" alt="Daumen hoch">
                <img *ngIf="isVotedUp(whatsNext)" [src]="thumbsUpFilledImg" (click)="unvote(whatsNext)" class="button-img" alt="Daumen hoch">
                <img *ngIf="!isVotedDown(whatsNext)" [src]="thumbsDownImg" (click)="updateVote(whatsNext, false)" class="button-img" alt="Daumen runter">
                <img *ngIf="isVotedDown(whatsNext)" [src]="thumbsDownFilledImg" (click)="unvote(whatsNext)" class="button-img" alt="Daumen runter">
            }
        </div>
        <div [innerHTML]="whatsNext.text"></div>
    }
} @else {
    <img [src]="loadingImg" class="loading-img-centered" alt="Lade What's Next">
}

