import { booleanAttribute, Component, Input } from '@angular/core';
import { IdentifiedCommand, NotOwnedCommandDisplay } from '../command';
import { CommandSearchService } from './command-search.service';
import { Router } from '@angular/router';

/**
 * The component for the command search.
 */
@Component({
  selector: 'tu-search',
  templateUrl: './command-search.component.html',
  styleUrl: '../commands.css',
  standalone: false
})
export class CommandSearchComponent {

  /**
   * The URL for the router to go to when the user clicks on the back button.
   */
  @Input('routeURL') routeURL: string;

  /**
   * Holds if the search was created for links.
   */
  @Input({alias:'forLinks', transform: booleanAttribute})
  forLinks: boolean = false;

  /**
   * Holds if the search was created for a channel.
   */
  @Input({alias:'forChannel', transform: booleanAttribute})
  forChannel: boolean = false;

  /**
   * Holds if an error occurred for the command.
   */
  hasError: boolean = false;

  /**
   * Holds the message to display for the error.
   */
  errorMessage: string = "";

  /**
   * The constructor.
   *
   * @param commandSearchService The command search service.
   * @param router               The router.
   */
  constructor(private commandSearchService: CommandSearchService, private router: Router) {

  }

  /**
   * Adds the provided command to the user.
   *
   * @param command The command.
   */
  addCommand(command: IdentifiedCommand) {
    const notOwnedCommand: NotOwnedCommandDisplay = command as NotOwnedCommandDisplay;
    if (!this.isCommandValidForUser(notOwnedCommand)) {
      return;
    }

    this.commandSearchService.addCommand(notOwnedCommand);
  }

  /**
   * Returns if the provided command is valid.
   *
   * @param toValidate The command to validate.
   * @returns true if the provided command is valid, false otherwise.
   */
  private isCommandValidForUser(toValidate: NotOwnedCommandDisplay): boolean {
    const errorMessage = this.commandSearchService.isCommandValid(toValidate);
    if (errorMessage != null) {
      this.hasError = true;
      this.errorMessage = errorMessage;
    } else {
      this.hasError = false;
      this.errorMessage = "";
    }
    return errorMessage == null;
  }

  /**
   * Get's called when the user clicks on the back button and returns to the {@link routeURL}.
   */
  back() {
    if (this.routeURL == null) {
      throw new Error('The back function was called while the redirectURL is null');
    }
    this.router.navigate([this.routeURL]);
  }
}
