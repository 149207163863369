/**
 * Holds all possible math signs.
 */
export enum MathSign {
    
    /**
     * The + sing.
     */
    PLUS = "+",

    /**
     * The - sing.
     */
    MINUS = "-",

    /**
     * The * sing.
     */
    MULTIPLY = "*",

    /**
     * The / sing.
     */
    DIVIDE = "/",

    /**
     * This is a special math sign, that can be used, if the counter should only be displayed.
     */
    DISPLAY = "Anzeigen",

}