<form name="editCountersForm" #editCountersForm="ngForm" (ngSubmit)="areCountersValid()" novalidate>
    @if (hasExisting) {
        <table  class="default-sub-table">
            <tr>
                <th class="default-sub-table-header" style="padding: 0 10px 0 10px;">Name</th>
                <th class="default-sub-table-header" style="padding: 0 10px 0 10px;">Berechnung</th>
                <th class="default-sub-table-header" style="padding: 0 10px 0 10px;">Wert</th>
                <th class="default-sub-table-header"></th>
            </tr>
            @for (commandCounter of command.counters; track $index) {
                @if (!isCreatedCounter(commandCounter)) {
                    <tr>
                        <td class="default-sub-table-cell" style="min-width: 200px;">
                            @if (counterIsEditing(commandCounter)) {
                                <input type="text" #name="ngModel" name="name" [(ngModel)]="commandCounter.counter.name" style="width: 95%;"
                                    nameValidator [toCheckId]="commandCounter.counter.id" [existingNames]="existingCounterNamesById" required>
                                 <div *ngIf="name.invalid && (name.dirty || name.touched)">
                                    <div *ngIf="name.errors?.['startsExclamation']" class="note" style="padding-top: 5px;" >
                                        Der Name darf nie mit einem ! beginnen.
                                    </div>
                                    <div *ngIf="name.errors?.['containsBlank']" class="note" style="padding-top: 5px;" >
                                        Der Name darf nie ein Leerzeichen enthalten.
                                    </div>
                                    <div *ngIf="name.errors?.['toLong']" class="note" style="padding-top: 5px;" >
                                        Der Name darf maximal 16 Zeichen lang sein.
                                    </div>
                                    <div *ngIf="name.errors?.['alreadyExists']" class="note" style="padding-top: 5px;" >
                                        Es existiert bereits ein Counter mit dem selben Namen bei diesem Befehl.
                                    </div>
                                 </div>
                            } @else {
                                {{commandCounter.counter.name}}
                            }
                        </td>
                        <td class="default-sub-table-cell">
                            <span *ngIf="!hasDisplayMathSign(commandCounter)">{{commandCounter.counter.baseValue}} </span>{{getMathSignValue(commandCounter.mathSign)}}<span *ngIf="!hasDisplayMathSign(commandCounter)"> {{commandCounter.toAdd}}</span>
                        </td>
                        <td class="default-sub-table-cell">
                            {{commandCounter.counter.currentValue}}
                        </td>
                        <td class="button-cell default-sub-table-cell">
                            @if (counterIsEditing(commandCounter)) {
                                <img *ngIf="!editCountersForm.form.valid || !isCounterValid(commandCounter)" [src]="acceptInactiveImg" class="button-img-centered" style="cursor: initial;" alt="Speichern Inaktiv">
                                <img *ngIf="editCountersForm.form.valid && isCounterValid(commandCounter)" [src]="acceptImg" (click)="save(commandCounter)" class="button-img-centered" alt="Speichern">
                                <img [src]="cancelImg" (click)="cancelCreateOrChangeCounter(commandCounter)" class="button-img-centered" alt="Abbrechen">
                            } @else {
                                <img [src]="editImg" (click)="editCounter(commandCounter)" class="button-img-centered" alt="Bearbeiten">
                                <img [src]="deleteImg" (click)="delete(commandCounter)" class="button-img-centered" alt="Löschen">
                            }
                        </td>
                    </tr>
                }
            }
        </table>
    }

    @if (createdCounters.length > 0) {
        <h4 *ngIf="hasExisting">Neue Counter</h4>
        <table class="default-sub-table">
            <tr>
                <th class="default-sub-table-header" style="padding: 0 10px 0 10px;">Name</th>
                <th class="default-sub-table-header" style="padding: 0 10px 0 10px;" colspan="3">Berechnung</th>
                <th class="default-sub-table-header"></th>
            </tr>
            <tr *ngFor="let commandCounter of createdCounters">
                <td class="default-sub-table-cell">
                    @if (counterIsEditing(commandCounter)) {
                        @if (counterIsFromUnused(commandCounter)) {
                            <select name="counter" (change)="selectedUnusedCounter($event, commandCounter)" style="width: 100px;">
                                <option value disabled selected style="display: none"></option>
                                @for (unusedCounter of displayedUnusedCounters; track $index) {
                                    <option [ngValue]="unusedCounter">{{ unusedCounter.name }}</option>
                                }
                            </select>
                        } @else {
                            <input type="text" #name="ngModel" name="name" [(ngModel)]="commandCounter.counter.name"
                            nameValidator [toCheckId]="commandCounter.counter.id" [existingNames]="existingCounterNamesById" required>
                            <div *ngIf="name.invalid && (name.dirty || name.touched)">
                                <div *ngIf="name.errors?.['startsExclamation']" class="note" style="padding-top: 5px;" >
                                    Der Name darf nie mit einem ! beginnen.
                                </div>
                                <div *ngIf="name.errors?.['containsBlank']" class="note" style="padding-top: 5px;" >
                                    Der Name darf nie ein Leerzeichen enthalten.
                                </div>
                                <div *ngIf="name.errors?.['toLong']" class="note" style="padding-top: 5px;" >
                                    Der Name darf maximal 16 Zeichen lang sein.
                                </div>
                                <div *ngIf="name.errors?.['alreadyExists']" class="note" style="padding-top: 5px;" >
                                    Es existiert bereits ein Counter mit dem selben Namen bei diesem Befehl.
                                </div>
                            </div>
                        }
                    } @else {
                        {{commandCounter.counter.name}}
                    }
                </td>
                <td class="default-sub-table-cell">
                    @if (!hasDisplayMathSign(commandCounter) && counterIsEditing(commandCounter) && !counterIsFromUnused(commandCounter)) {
                        <input type="text" #baseValue="ngModel" name="baseValue" isNumberValidator [notZero]="true" [(ngModel)]="commandCounter.counter.baseValue" required style="width: 100px;">
                        <div *ngIf="baseValue.errors?.['noNumber']" class="note">
                            Bitte gib eine Nummer ein
                        </div>
                        <div *ngIf="baseValue.errors?.['isZero']" class="note">
                            Bitte gib etwas anderes als 0 ein
                        </div>
                    } @else {
                        <div style="text-align: center;">
                            @if (counterIsFromUnused(commandCounter)) {
                                {{commandCounter.counter.currentValue}}
                            } @else {
                                {{commandCounter.counter.baseValue}}
                            }
                        </div>
                    }
                </td>
                <td class="default-sub-table-cell" [attr.colspan]="!hasDisplayMathSign(commandCounter) ? 1 : 2" >
                    @if (counterIsEditing(commandCounter)) {
                        <select (change)="mathSingChanged(commandCounter, $event)">
                            @for (mathSign of mathSigns; track $index) {
                                <option [selected]="counterHasMathSign(commandCounter, mathSign)" [ngValue]="mathSign">
                                    {{mathSign}}
                                </option>
                            }
                        </select>
                    } @else {
                        <div style="text-align: center;">{{getMathSignValue(commandCounter.mathSign)}}</div>
                    }
                </td>
                <td class="default-sub-table-cell" *ngIf="!hasDisplayMathSign(commandCounter)">
                    @if (counterIsEditing(commandCounter)) {
                        <input type="text" #toAdd="ngModel" name="toAdd" isNumberValidator  [notZero]="true" [(ngModel)]="commandCounter.toAdd" required style=" width: 75px;">
                        <div *ngIf="toAdd.errors?.['noNumber']" class="note">
                            Bitte gib eine Nummer ein
                        </div>
                        <div *ngIf="toAdd.errors?.['isZero']" class="note">
                            Bitte gib etwas anderes als 0 ein
                        </div>
                    } @else {
                        <div style="text-align: center;">{{commandCounter.toAdd}}</div>
                    }
                </td>
                <td class="button-cell default-sub-table-cell">
                    @if (counterIsEditing(commandCounter)) {
                        <img *ngIf="!editCountersForm.form.valid || !isCounterValid(commandCounter)" [src]="acceptInactiveImg" class="button-img-centered" style="cursor: initial;" alt="Speichern Inaktiv">
                        <img *ngIf="editCountersForm.form.valid && isCounterValid(commandCounter)" [src]="acceptImg" (click)="save(commandCounter)" class="button-img-centered" alt="Speichern">
                        <img [src]="cancelImg" (click)="cancelCreateOrChangeCounter(commandCounter)" class="button-img-centered" alt="Abbrechen">
                    } @else {
                        <img [src]="editImg" (click)="editCounter(commandCounter)" class="button-img-centered" alt="Bearbeiten">
                        <img [src]="deleteImg" (click)="delete(commandCounter)" class="button-img-centered" alt="Löschen">
                    }
                </td>
            </tr>
        </table>
    }

    <img [src]="addImg" (click)="createCommandCounter()" class="button-img" style="float: left;" alt="Erstellen">
    @if (displayedUnusedCounters.length != 0) {
        <img [src]="addOuterImg" (click)="addCommandCounter()" class="button-img" style="float: left;" alt="Hinzufügen">
    }
</form>
