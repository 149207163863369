import { Injectable } from '@angular/core';
import { AbstractCommand, CommandEdit, CommandKey, IdentifiedCommand, NotOwnedCommandDisplay } from './command';
import { AuthenticationService } from '../authentication/authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { COMMAND_URL } from '../generic/backend-urls';
import { Feature } from '../feature/feature';
import { UserService } from '../user/user.service';
import { CommandEditService as CommandEditService } from './edit/command-edit.service';
import { Router } from '@angular/router';
import { getEnumFromKey } from '../generic/enum-utils';
import { firstValueFrom } from 'rxjs';
import { UserEdit } from '../user/user';
import { InternalErrorException } from '../generic/errors';
import { NOT_ACCEPTABLE } from '../generic/http-status';

/**
 * A service for the commands.
 */
@Injectable({
  providedIn: 'root'
})
export class CommandsService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   * @param userService           The user service.
   * @param commandEditService    The command edit service.
   * @param router                The router.
   */
  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private commandEditService: CommandEditService,
              private router: Router) { }

  /**
   * Returns the commands owned by the links.
   *
   * @returns The commands owned by the links.
   */
  findOwnedCommandsForLinks(): CommandEdit[] {
    const user = this.authenticationService.getUser();
    const result: CommandEdit[] = [];
    for (let command of user.ownedCommands) {
      if (command.feature == getEnumFromKey(Feature, Feature.TWITCH_LINKS)) {
        result.push(command);
      }
    }
    return result;
  }

  /**
   * Returns the commands owned by the channel.
   *
   * @returns The commands owned by the channel.
   */
  findOwnedCommandsForChannel(): CommandEdit[] {
    const user = this.authenticationService.getUser();
    const result: CommandEdit[] = [];
    for (let command of user.ownedCommands) {
      if (command.feature == getEnumFromKey(Feature, Feature.TWITCH_BOT)) {
        result.push(command);
      }
    }
    return result;
  }

  /**
   * Returns the commands owned by the user.
   *
   * @returns The commands owned by the user.
   */
  findOwnedCommandsForUser(): CommandEdit[] {
    const user = this.authenticationService.getUser();
    return user.ownedCommands;
  }

  /**
   * Saves the provided command.
   *
   * @param command The command.
   */
  save(command: CommandEdit): Promise<UserEdit> {
    const isCreated = command.id == -1;
    const errorMessage = this.createErrorMessage(command, (isCreated ? "create" : "update"));
    const result: Promise<UserEdit> = firstValueFrom<UserEdit>(this.http.post<UserEdit>(COMMAND_URL + "/save", command));
    result.then(user => {
        this.authenticationService.refreshUser(user)
        this.router.navigate(["commands"])
      })
      .catch((err: HttpErrorResponse) => {
        let status = Number(err.status);
        if (status == NOT_ACCEPTABLE) {
          this.authenticationService.reloadUser();
          return;
        }
        throw new InternalErrorException(errorMessage, err);
      });

    return result;
  }

  /**
   * Creates a command.
   */
  createCommand(withForLinks: boolean, withForChannel: boolean): void {
    let url: string;
    if (withForLinks) {
      url = "links";
    } else if (withForChannel) {
      url = "channel";
    } else {
      url = "commands";
    }
    this.router.navigate(["commands/create"], {queryParams: {routeURL: url, forCreate: true, forLinks: withForLinks, forChannel: withForChannel}});
  }

  /**
   * Edits the provided command.
   *
   * @param command        The command.
   * @param withForLinks   true if the command was edited for links, false otherwise.
   * @param withForChannel true if the command was edited for a channel, false otherwise.
   */
  editCommand(command: CommandEdit, withForLinks: boolean, withForChannel: boolean): void {
    let url: string;
    if (withForLinks) {
      url = "links";
    } else if (withForChannel) {
      url = "channel";
    } else {
      url = "commands";
    }
    this.router.navigate(["commands/edit"], {queryParams: {routeURL: url, command: command.id, forLinks: withForLinks, forChannel: withForChannel}});
  }

  /**
   * Returns a promise for the reloaded version of the provided command.
   *
   * @param command The command to reload.
   * @returns The promise for the reloaded command.
   */
  reloadCommand(command: IdentifiedCommand): Promise<CommandEdit> {
      return this.commandEditService.reloadCommand(command);
  }

  /**
   * Deletes the provided command.
   *
   * @param command The command.
   * @returns The promise from the deletion of the command.
   */
  delete(command: CommandEdit): Promise<UserEdit> {
    const errorMessage = this.createErrorMessage(command, "delete");
    const result: Promise<UserEdit> = firstValueFrom<UserEdit>(this.http.post<UserEdit>(COMMAND_URL + "/delete", command));
    result.then(user => {
      this.authenticationService.refreshUser(user);
    })
    .catch((err: HttpErrorResponse) => {
      let status = Number(err.status);
      if (status == NOT_ACCEPTABLE) {
        this.authenticationService.reloadUser();
      } else {
        throw new InternalErrorException(errorMessage, err);
      }
    });

    return result;
  }

  /**
   * Removes the provided command from the user.
   *
   * @param command The command.
   */
  removeNotOwned(command: NotOwnedCommandDisplay): void {
    const commandKey = new CommandKey(command.id);
    firstValueFrom<UserEdit>(this.http.post<UserEdit>(COMMAND_URL + "/remove", commandKey))
      .then(user => {
        this.authenticationService.refreshUser(user);
      })
      .catch((err: HttpErrorResponse) => {
        let status = Number(err.status);
        if (status == NOT_ACCEPTABLE) {
          this.authenticationService.reloadUser();
          return;
        }
        const errorMessage: string = this.createErrorMessage(command, "remove");
        throw new InternalErrorException(errorMessage, err);
      });
  }

  /**
   * Adds the provided command to the user.
   *
   * @param command The command.
   */
  addToUser(command: NotOwnedCommandDisplay): void {
    this.userService.addCommand(command);
  }

  /**
   * Creates an error message for the provided command with the reason inside it.
   *
   * @param command The command.
   * @param reason  The reason.
   * @returns An error message.
   */
  private createErrorMessage(command: AbstractCommand, reason: string): string {
    const isCreated = command.id == -1;
    const commandMessage = reason  + " the command '" + command.name  + "'" + (!isCreated ? " (ID: " + command.id + ") " : " ") + "with the text " + command.text;
    const errorMessage = "An error occurred for user " + this.authenticationService.getUser().id + " while trying to " + commandMessage;
    return errorMessage;
  }

}
