import { IdentifiedEntity } from "../generic/identified-entity";
import { AbstractKey } from "../generic/abstract-key";
import { Versionable } from "../generic/versionable";
import { IdentifiedUser } from "../user/user";

/**
 * The key for a counter.
 */
export class CounterKey extends AbstractKey implements IdentifiedCounter {

  /**
   * The constructor.
   *
   * @param id The id.
   */
    constructor(id: number) {
        super(id);
    }
}

/**
 * The versionable key for a counter.
 */
export class CounterVersionableKey extends CounterKey implements IdentifiedVersionableCounter {

  /**
   * Holds the version.
   */
  readonly version: number;

  /**
   * The constructor.
   *
   * @param id The id.
   * @param version The version.
   */
  constructor(id: number, version: number) {
    super(id);
    this.version = version;
  }

}

/**
 * An edit object for a counter.
 */
export class CounterEdit extends CounterVersionableKey {

    /**
     * Holds the name of the counter.
     */
    name: string;

    /**
     * Holds the base value of the counter.
     */
    baseValue: number;

    /**
     * Holds the current value of the counter.
     */
    currentValue: number;

    /**
     * Holds if the counter is at the start.
     */
    atStart: boolean;

    /**
     * Holds the user owning this counter.
     */
    owner: IdentifiedUser;

    /**
     * The constructor.
     *
     * @param id           The id.
     * @param version      The version.
     * @param name         The name.
     * @param baseValue    The base value.
     * @param currentValue The current value.
     * @param atStart      true if the counter is at the start, false.
     * @param owner        The user owning this counter.
     */
    constructor(id: number,
                version: number,
                name: string,
                baseValue: number,
                currentValue: number,
                atStart: boolean,
                owner: IdentifiedUser) {
        super(id, version);

        this.name = name;
        this.baseValue = baseValue;
        this.currentValue = currentValue;
        this.atStart = atStart;
        this.owner = owner;
    }

    /**
     * Returns a copy of the provided counter.
     *
     * @param counter The counter to copy.
     * @returns A copy of the provided counter.
     */
    public static copy(counter: CounterEdit): CounterEdit {
      return new CounterEdit(counter.id,
                             counter.version,
                             counter.name,
                             counter.baseValue,
                             counter.currentValue,
                             counter.atStart,
                             counter.owner);
    }
}

/**
 * A display object for a counter.
 */
export class CounterDisplay extends CounterVersionableKey {

    /**
     * Holds the name of the counter.
     */
    readonly name: string;

    /**
     * Holds the base value of the counter.
     */
    readonly baseValue: number;

    /**
     * Holds the current value of the counter.
     */
    readonly currentValue: number;

    /**
     * Holds if the counter is at the start.
     */
    readonly atStart: boolean;

    /**
     * Holds the user owning this counter.
     */
    readonly owner: IdentifiedUser;

    /**
     * The constructor.
     *
     * @param id           The id.
     * @param version      The version.
     * @param name         The name.
     * @param baseValue    The base value.
     * @param currentValue The current value.
     * @param atStart      true if the counter is at the start, false.
     * @param owner        The user owning this counter.
     */
    constructor(id: number,
                version: number,
                name: string,
                baseValue: number,
                currentValue: number,
                atStart: boolean,
                owner: IdentifiedUser) {
        super(id, version);

        this.name = name;
        this.baseValue = baseValue;
        this.currentValue = currentValue;
        this.atStart = atStart;
        this.owner = owner;
    }

}

/**
 * The identified entity for counters.
 */
export interface IdentifiedCounter extends IdentifiedEntity {

}

/**
 * The versionable identified entity for counters.
 */
export interface IdentifiedVersionableCounter extends IdentifiedCounter, Versionable {

}
