
<table>
    @for (userLevel of command.userLevels; track $index; let i= $index) {
        <tr>
            <td>
                <select style="width: 100px;" (change)="userLevelChanged($event, i)">
                    @for (ul of possibleUserLevel; track $index) {
                        <option [selected]="isValueOf(ul, userLevel)" [ngValue]="ul">
                            {{ul}}
                        </option>
                    }
                </select>
            </td>
            @if (command.userLevels.length > 1) {
                <td class="button-cell">
                    <img [src]="deleteImg" (click)="delete(i)" class="button-img-centered" alt="Löschen">
                </td>
            }
        </tr>
    }
</table>
@if (!everyoneSelected) {
    <img (click)="addUserLevel()" [src]="addImg" class="button-img" style="float: left;" alt="Hinzufügen">
}

