import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommandEdit } from '../../command';
import { CounterEdit } from '../../../counter/counter';
import { COUNTER_URL } from '../../../generic/backend-urls';
import { firstValueFrom } from 'rxjs';

/**
 * A service for the counter edits.
 */
@Injectable({
  providedIn: 'root'
})
export class CounterEditService {

  /**
   * The constructor.
   *
   * @param http The http client.
   */
  constructor(private http: HttpClient) {

  }

  /**
   * Returns all counters from the owner that are not used by the command yet.
   *
   * @param owner   The owner of the counters.
   * @param command The command.
   * @return All counters from the owner that are not used by the command yet.
   */
  findUnusedCounters(command: CommandEdit): Promise<CounterEdit[]> {
    return firstValueFrom(this.http.post<CounterEdit[]>(COUNTER_URL + "/unused-counters", command));
  }

}
