import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CounterEdit, CounterKey, IdentifiedCounter } from './counter';
import { CountersService } from './counters.service';
import { NgIf } from '@angular/common';
import { ACCEPT_IMG, ACCEPT_INACTIVE_IMG, ADD_IMG, CANCEL_IMG, DELETE_IMG, EDIT_IMG, LOADING_GIF } from '../generic/img-pathes';
import { filterIdentified } from '../generic/array-utils';
import { NOT_ACCEPTABLE } from '../generic/http-status';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserEdit, UserKey } from '../user/user';
import { NameValidator } from '../generic/name-validator.directive';
import { IsNumberValidator } from '../generic/is-number-validator.directive';
import { getEnumFromKey } from '../generic/enum-utils';
import { Feature } from '../feature/feature';
import { RouterModule } from '@angular/router';
import { CommandEdit } from '../command/command';

/**
 * The component for the counters.
 */
@Component({
  selector: 'app-counters',
  imports: [FormsModule, NgIf, NameValidator, IsNumberValidator, RouterModule],
  templateUrl: './counters.component.html',
})
export class CountersComponent implements OnInit {

  /**
   * Holds the path to the add image.
   */
  addImg: string = ADD_IMG;

  /**
   * Holds the path to the accept image.
   */
  acceptImg: string = ACCEPT_IMG;

  /**
   * Holds the path to the inactive accept image.
   */
  acceptInactiveImg: string = ACCEPT_INACTIVE_IMG;

  /**
   * Holds the path to the edit image.
   */
  editImg: string = EDIT_IMG;

  /**
   * Holds the path to the cancel image.
   */
  cancelImg: string = CANCEL_IMG;

  /**
   * Holds the path to the delete image.
   */
  deleteImg: string = DELETE_IMG;

  /**
   * Holds path to the loading gif.
   */
  loadingGif: string = LOADING_GIF;

  /**
   * Holds if a counter had a wrong version.
   */
  wrongVersion: boolean = false;

  /**
   * Holds all existing names for the current counters.
   */
  existingNamesById: Map<number, string> = new Map<number, string>();

  /**
   * Holds the counters.
   */
  counters: CounterEdit[] = [];

  /**
   * Holds all counters to create.
   */
  countersToCreate: CounterEdit[] = [];

  /**
   * Holds the counters currently deleting.
   */
  countersToDelete: IdentifiedCounter[] = [];

  /**
   * Holds the counters which are currently editing.
   */
  countersToEdit: CounterEdit[] = [];

  /**
   * Holds the counters currently saving.
   */
  countersToSave: IdentifiedCounter[] = [];

  /**
   * Holds all counters by their id.
   */
  countersById: Map<number, CounterEdit> = new Map<number, CounterEdit>();

  /**
   * Holds all commands for the counter ids.
   */
  commandsForCounter: Map<number, CommandEdit[]> = new Map<number, CommandEdit[]>();

  /**
   * The constructor.
   *
   * @param countersService       The counter service.
   * @param authenticationService The authentication service.
   */
  constructor(private countersService: CountersService, private authenticationService: AuthenticationService) {

  }

  ngOnInit(): void {
    this.initCounters();
    this.authenticationService.subscribeToUserChange((user: UserEdit) => {
      this.initCounters()
    });
  }

  /**
   * Initializes the counters.
   */
  private initCounters(): void {
    const user: UserEdit = this.authenticationService.getUser();
    this.counters = user.counters;
    this.existingNamesById.clear()
    this.countersById.clear()
    this.commandsForCounter.clear();
    this.counters.forEach(cnt => {
      this.existingNamesById.set(cnt.id, cnt.name);
      this.countersById.set(cnt.id, CounterEdit.copy(cnt));
      for (let counter of this.counters) {
        const commands: CommandEdit[] = [];
        for (let command of user.ownedCommands) {
          for (let commandCounter of command.counters) {
            if (commandCounter.counter.id == counter.id) {
              commands.push(command);
            }
          }
        }
        this.commandsForCounter.set(counter.id, commands);
      }
    });
  }

  /**
   * Saves the counter.
   */
  save(counter: CounterEdit): void {
    this.countersToSave.push(counter);
    if (this.countersToCreate.some(cnt => cnt.id == counter.id)) {
      counter.currentValue = counter.baseValue;
    }
    this.countersService.save(counter)
      .then(user => {
        this.setWrongVersion(counter, false);
        this.countersToCreate = this.countersToCreate.filter(cmd => cmd.id != counter.id);
        this.countersToSave = filterIdentified(this.countersToSave, counter as IdentifiedCounter);
        this.countersToEdit = this.countersToEdit.filter(cmd => cmd.id != counter.id);
        this.countersById.set(counter.id, CounterEdit.copy(counter));
      })
      .catch(err => {
        this.countersToSave = filterIdentified(this.countersToSave, counter as IdentifiedCounter);
        let status = Number(err.status);
        if (status == NOT_ACCEPTABLE) {
          this.setWrongVersion(counter, true);
          return
        }
      });
  }


  /**
   * Returns if the provided counter was changed.
   *
   * @param counter The counter to check.
   * @returns true if the provided counter was changed, false otherwise.
   */
  counterHasChanged(counter: CounterEdit): boolean {
    if (counter.id == -1) {
      return true;
    }
    const previouseCounter: CounterEdit = this.countersById.get(counter.id);
    if (previouseCounter == null) {
      return true;
    }
    if (previouseCounter.name.trim() != counter.name.trim()) {
      return true;
    }
    return false;
  }

  /**
   * Creates the query params for the link to the provided command
   *
   * @param command The command.
   * @returns The query params for the link to the provided command.
   */
  createQueryParams(command: CommandEdit): any {
    const forLinks = command.feature == getEnumFromKey(Feature, Feature.TWITCH_LINKS);
    const forChannel = command.feature == getEnumFromKey(Feature, Feature.TWITCH_BOT);
    let linkString = '<a href="/commands/edit" [queryParams]="{routeURL: \'counters\', command: ' + command.id;
    linkString += ', forLinks: ' + forLinks;
    linkString += ', forChannel: ' + forChannel;
    linkString += '}">'
    return {
      routeURL: 'counters',
      command: command.id,
      forLinks: forLinks,
      forChannel: forChannel
    };
  }

  /**
   * Returns the commands for the provided counter.
   *
   * @param counter The counter.
   * @returns The commands.
   */
  getCommands(counter: CounterEdit): CommandEdit[] {
    return this.commandsForCounter.get(counter.id);
  }

  /**
   * Cancels the current creation/changing of the provided counter.
   *
   * @param counter The counter.
   */
  cancelCreateOrChangeCounter(counter: CounterEdit): void {
    const createCounterIndex = this.countersToCreate.indexOf(counter);
    if (createCounterIndex != -1) {
      this.countersToCreate.splice(createCounterIndex, 1);
    }
    const editCounterIndex = this.countersToEdit.indexOf(counter);
    if (editCounterIndex != -1) {
      this.countersToEdit.splice(editCounterIndex, 1);
    }
  }

  /**
   * Delete the provided counter.
   *
   * @param counter The counter.
   */
  delete(counter: CounterEdit): void {
    this.countersToDelete.push(new CounterKey(counter.id));
    this.countersService.delete(counter)
      .then(user => {
          this.countersToDelete = filterIdentified(this.countersToDelete, counter as IdentifiedCounter)
          this.existingNamesById.delete(counter.id);
          this.countersById.delete(counter.id);
          this.commandsForCounter.delete(counter.id);
          this.setWrongVersion(counter, false);
      })
      .catch(err => {
          this.countersToDelete = filterIdentified(this.countersToDelete, counter as IdentifiedCounter)
          let status = Number(err.status);
          if (status == NOT_ACCEPTABLE) {
              this.setWrongVersion(counter, true);
          }
      });
  }

  /**
   * Sets if the counter had a wrong version.
   *
   * @param counter      The counter.
   * @param wrongVersion true if the counter had a wrong version, false otherwise.
   */
  private setWrongVersion(counter: IdentifiedCounter, wrongVersion: boolean) {
    this.wrongVersion = wrongVersion;
    if (wrongVersion) {
      this.countersToDelete = this.countersToDelete.filter(cmd => cmd.id != counter.id);
      this.countersToSave = filterIdentified(this.countersToSave, counter as IdentifiedCounter);
    }
  }

  /**
   * Creates a new counter.
   */
  createCounter() {
    const counter: CounterEdit = new CounterEdit(-1,
                                                  0,
                                                  "",
                                                  1,
                                                  1,
                                                  true,
                                                  new UserKey(this.authenticationService.getUser().id));
    this.countersToCreate.push(counter);
  }

  /**
   * Makes the provided counter editable.
   *
   * @param counter The counter.
   */
  editCounter(counter: CounterEdit) {
    this.countersToEdit.push(counter);
  }

  /**
   * Returns if the provided counter is edited at the moment.
   *
   * @param counter The counter.
   * @returns true if the provided counter is edited at the moment, false otherwise.
   */
  isEditingCounter(counter: CounterEdit): boolean {
    return this.countersToEdit.some(cnt => cnt.id == counter.id);
  }

  /**
   * Returns if the provided counter is saving or deleting at the moment, false otherwise.
   *
   * @param counter The counter.
   * @returns true if the provided counter is saving or deleting at the moment, false otherwise.
   */
  isSavingOrDeletingCounter(counter: CounterEdit): boolean {
    return this.countersToSave.some(cnt => cnt.id == counter.id) ||
      this.countersToDelete.some(cnt => cnt.id == counter.id);
  }
}
