import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

/**
 * A validator for a number.
 */
@Directive({
  selector: '[isNumberValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: IsNumberValidator,
      multi: true,
    },
  ],
  standalone: true,
})
export class IsNumberValidator implements Validator {

  /**
   * Holds if the value might be zero.
   * Default: false
   */
  @Input("notZero")
  notZero: boolean = false;

  validate(control: AbstractControl): { [key: string]: any } | null {
    const value: string = control.value;
    if (value == null || value.length == 0) {
      return null;
    }

    const noNumber: boolean = isNaN(+value);
    let isZero: boolean = false;
    if (this.notZero && !noNumber) {
      const valueNr: number = +value;
      isZero = valueNr == 0;
    }

    if (noNumber || isZero) {
      return {
        noNumber: noNumber,
        isZero: isZero
      }
    }
    return null;
  }
}
