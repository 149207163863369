import { AfterViewInit, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ACCEPT_IMG, ACCEPT_INACTIVE_IMG, ADD_IMG, ADD_OUTER_IMG, CANCEL_IMG, DELETE_IMG, EDIT_IMG } from '../../../generic/img-pathes';
import { CounterEdit, IdentifiedCounter } from '../../../counter/counter';
import { CommandEdit, CommandKey } from '../../command';
import { containsIdentified, filterIdentified, pushIdentifiedIfAbsent } from '../../../generic/array-utils';
import { NEW_COMMAND_COUNTER_BASE_ID, NEW_COUNTER_BASE_ID } from '../counter-basics';
import { InternalErrorException } from '../../../generic/errors';
import { NgFor, NgIf } from '@angular/common';
import { AbstractStandaloneComponent } from '../../../generic/standalone-component';
import { MathSign } from '../math-sign';
import { getEnumFromKey } from '../../../generic/enum-utils';
import { FormsModule } from '@angular/forms';
import { IsNumberValidator } from '../../../generic/is-number-validator.directive';
import { CommandCounterEdit, IdentifiedCommandCounter } from '../command-counter';
import { UserKey } from '../../../user/user';
import { CounterEditService } from './command-counter-edit.service';
import { NameValidator } from '../../../generic/name-validator.directive';

/**
 * The component for creating/editing counters of a command.
 */
@Component({
  selector: 'tu-counter-edit',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    FormsModule,
    IsNumberValidator,
    NameValidator,
  ],
  templateUrl: './command-counter-edit.component.html',
  styleUrl: '../../../command/commands.css',
})
export class CommandCounterEditComponent extends AbstractStandaloneComponent implements OnInit, AfterViewInit {

  /**
   * Holds the path to the accept image.
   */
  acceptImg: string = ACCEPT_IMG;

  /**
   * Holds the path to the inactive accept image.
   */
  acceptInactiveImg: string = ACCEPT_INACTIVE_IMG;

  /**
   * Holds the path to the add image.
   */
  addImg: string = ADD_IMG;
  
  /**
   * Holds the path to the add outer image.
   */
  addOuterImg: string = ADD_OUTER_IMG;

  /**
   * Holds the path to the cancel image.
   */
  cancelImg: string = CANCEL_IMG;

  /**
   * Holds the path to the delete image.
   */
  deleteImg: string = DELETE_IMG;

  /**
   * Holds the path to the edit image.
   */
  editImg: string = EDIT_IMG;

  /**
   * Holds the method to get called whenever the save button shall be disabled.
   */
  @Output("saveButtonDisabled")
  disableSaveButton: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Holds the method to get called whenever a counter has been changed.
   */
  @Output("counterChanged")
  setCounterChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Holds the command of the counters.
   */
  @Input({alias:"command", required: true})
  command: CommandEdit;

  /**
   * Holds the counter edit service.
   */
  private counterEditService: CounterEditService = inject(CounterEditService);

  /**
   * Holds the id for new created command counters.
   */
  private nextCommandCounterId: number = NEW_COMMAND_COUNTER_BASE_ID;

  /**
   * Holds the id for new created counters.
   */
  private nextCounterId: number = NEW_COUNTER_BASE_ID;

  /**
   * Holds the math signs.
   */
  mathSigns: MathSign[] = Object.values(MathSign);

  /**
   * Holds the command counters for their id.
   */
  countersById: Map<number, CommandCounterEdit> = new Map<number, CommandCounterEdit>();

  /**
   * Holds all existing names for the current counters.
   */
  existingCounterNamesById: Map<number, string> = new Map<number, string>();

  /**
   * Holds the command counters which are currently editing.
   */
  editingCounters: CommandCounterEdit[] = [];

  /**
   * Holds the command counters which are create for an unused counter.
   * @see unusedCounters
   */
  fromUnusedCounters: CommandCounterEdit[] = [];

  /**
   * Holds the command counters which where saved.
   */
  savedCounters: Map<number, CommandCounterEdit> = new Map<number, CommandCounterEdit>();

  /**
   * Holds all command counters that where created.
   */
  createdCounters: CommandCounterEdit[] = [];

  /**
   * Holds the command counters which where changed.
   */
  changedCounters: CommandCounterEdit[] = [];

  /**
   * Holds the unused counters which can be used for creating a command counter.
   */
  unusedCounters: CounterEdit[] = [];

  /**
   * Holds the unused counters which are displayed.
   */
  displayedUnusedCounters: CounterEdit[] = [];

  /**
   * Holds if a counter already exist on the command.
   */
  hasExisting: boolean = false;

  ngOnInit(): void {
    for(let commandCounter of this.command.counters) {
      this.countersById.set(commandCounter.id, CommandCounterEdit.copy(commandCounter));
      this.existingCounterNamesById.set(commandCounter.counter.id, commandCounter.counter.name);
    }
    this.hasExisting = this.command.counters.length > 0;

    this.counterEditService.findUnusedCounters(this.command)
      .then((unusedCounters) => {
        this.unusedCounters = unusedCounters.sort((cnt1, cnt2) => {
          return cnt1.name.localeCompare(cnt2.name);
        });
        this.unusedCounters.forEach(cnt => {
          this.displayedUnusedCounters.push(cnt);
          this.existingCounterNamesById.set(cnt.id, cnt.name);
        })
      });
  }

  ngAfterViewInit(): void {
    if (!this.disableSaveButton.observed) {
      throw new InternalErrorException("A disableSaveButton should always been provided");
    }
    if (!this.setCounterChanged.observed) {
      throw new InternalErrorException("A setCounterChanged should always been provided");
    }
  }

  /**
   * Saves the command counter.
   */
  save(commandCounter: CommandCounterEdit) {
    const counter: CounterEdit = commandCounter.counter;
    if (this.isCreatedCounter(commandCounter)) {
      counter.currentValue = counter.baseValue;
    }
    this.command.counters = this.command.counters.map((cnt) => cnt.id == commandCounter.id ? commandCounter : cnt);
    pushIdentifiedIfAbsent(this.command.counters, commandCounter);
    let allSaved = true;
    for (let cnt of this.changedCounters) {
      if (!this.savedCounters.has(cnt.id)) {
        allSaved = false;
        break;
      }
    }
    this.savedCounters.set(commandCounter.id, CommandCounterEdit.copy(commandCounter));
    if (!this.changedCounters.some(cnt => cnt.id == commandCounter.id)) {
      this.changedCounters.push(commandCounter);
    }
    const commandCounterCounterIndex: number = this.displayedUnusedCounters.indexOf(counter);
    if (commandCounterCounterIndex != -1) {
      this.displayedUnusedCounters.splice(commandCounterCounterIndex, 1);
    }
    this.existingCounterNamesById.set(counter.id, counter.name);
    this.editingCounters = filterIdentified(this.editingCounters, commandCounter);
    this.setCounterChanged.emit(true);
    this.disableSaveButton.emit(!allSaved);
  }

  /**
   * Creates a new command counter.
   */
  createCommandCounter(): void {
    const counter: CounterEdit = new CounterEdit(this.nextCounterId,
                                                 0,
                                                 "",
                                                 1,
                                                 1,
                                                 true,
                                                 new UserKey(this.command.owner.id));
    const commandCounter: CommandCounterEdit = new CommandCounterEdit(this.nextCommandCounterId,
                                                                      0,
                                                                      1,
                                                                      getEnumFromKey(MathSign, MathSign.PLUS),
                                                                      new CommandKey(this.command.id),
                                                                      counter);
    this.nextCommandCounterId = this.nextCommandCounterId + 1;
    this.nextCounterId = this.nextCounterId + 1;
    this.changedCounters.push(commandCounter);
    this.createdCounters.push(commandCounter);
    this.editingCounters.push(commandCounter);
    this.disableSaveButton.emit(true);
  }

  /**
   * Creates a new command counter for selecting an unused counter.
   */
  addCommandCounter(): void {
    const counter: CounterEdit = new CounterEdit(-1,
                                                 0,
                                                 "",
                                                 0,
                                                 0,
                                                 true,
                                                 new UserKey(this.command.owner.id));
    const commandCounter: CommandCounterEdit = new CommandCounterEdit(this.nextCommandCounterId,
                                                                      0,
                                                                      1,
                                                                      getEnumFromKey(MathSign, MathSign.PLUS),
                                                                      new CommandKey(this.command.id),
                                                                      counter);
    this.nextCommandCounterId = this.nextCommandCounterId + 1;
    this.changedCounters.push(commandCounter);
    this.createdCounters.push(commandCounter);
    this.editingCounters.push(commandCounter);
    this.fromUnusedCounters.push(commandCounter);
    this.disableSaveButton.emit(true);
  }

  /**
   * Gets called when a counter has been changed for an added command counter.
   *
   * @param $event         The event of the change.
   * @param commandCounter The command counter the counter was selected for.
   * @see addCommandCounter
   */
  selectedUnusedCounter($event: Event, commandCounter: CommandCounterEdit) {
    if (this.unusedCounters.some(cnt => cnt.id == commandCounter.counter.id) &&
        !this.displayedUnusedCounters.some(cnt => cnt.id == commandCounter.counter.id)) {
      this.displayedUnusedCounters.push(commandCounter.counter);
    }
    const options: HTMLCollectionOf<HTMLOptionElement> = ($event.target as HTMLSelectElement).selectedOptions;
    const counter: CounterEdit = this.displayedUnusedCounters[options.item(0).index - 1]; // we need this -1 here because of the invisible option
    commandCounter.counter = counter;
  }

  /**
   * Gets called when a math sign gets changed for a counter.
   *
   * @param counter The counter.
   * @param $event  The event.
   */
  mathSingChanged(counter: IdentifiedCounter, $event: any): void {
    const mathSignValue = this.getCurrentValueForSelect($event);
    const newMathSign: MathSign = getEnumFromKey(MathSign, mathSignValue);
    if (this.isCreatedCounter(counter)) {
      this.createdCounters.forEach((cnt) => {
        if (cnt.id == counter.id) {
          cnt.mathSign = newMathSign;
        }
      });
    }
    this.command.counters.forEach((cnt) => {
      if (cnt.id == counter.id) {
        cnt.mathSign = newMathSign;
      }
    });
  }

  /**
   * Returns if the provided command counter has the {@link MathSign.DISPLAY DISPLAY math sign}.
   *
   * @param commandCounter The command counter.
   * @returns ture if the command counter has the DISPLAY math sign, false otherwise.
   */
  hasDisplayMathSign(commandCounter: CommandCounterEdit): boolean {
    return commandCounter.mathSign == getEnumFromKey(MathSign, MathSign.DISPLAY);
  }

  /**
   * Returns if the provided command counter has the math sign.
   *
   * @param commandCounter  The command counter.
   * @param mathSign        The math sign.
   * @returns true if the provided command counter has the math sign, false otherwise.
   */
  counterHasMathSign(commandCounter: CommandCounterEdit, mathSign: MathSign): boolean {
    const toTest: MathSign = getEnumFromKey(MathSign, mathSign);
    return commandCounter.mathSign == toTest;
  }

  /**
   * Makes the provided command counter editable.
   *
   * @param counter The command counter.
   */
  editCounter(counter: CommandCounterEdit) {
    this.editingCounters.push(counter);
  }

  /**
   * Returns if the command counter is edited at the moment.
   *
   * @param commandCounter The command counter.
   * @returns true if the command counter is edited at the moment, false otherwise.
   */
  counterIsEditing(commandCounter: CommandCounterEdit): boolean {
    return containsIdentified(this.editingCounters, commandCounter);
  }

  /**
   * Returns if the command counter is from a known counter.
   *
   * @param commandCounter The command counter.
   * @returns true if the command counter is from a known counter, false otherwise.
   */
  counterIsFromUnused(commandCounter: CommandCounterEdit): boolean {
    return containsIdentified(this.fromUnusedCounters, commandCounter);
  }

  /**
   * Returns the value of the provided math sign.
   *
   * @returns The value of the provided math sign.
   */
  getMathSignValue(mathSign: MathSign): MathSign {
      return MathSign[mathSign];
  }

  /**
   * Checks if the provided command counter has been edited.
   *
   * @param commandCounter The command counter.
   * @returns true if the command counter has been changed, false otherwise.
   */
  private counterHasChanged(commandCounter: CommandCounterEdit): boolean {
    let previousCommandCounter: CommandCounterEdit = this.savedCounters.get(commandCounter.id);
    if (previousCommandCounter == null && !this.isCreatedCounter(commandCounter)) {
      previousCommandCounter = this.countersById.get(commandCounter.id);
    }
    const counter: CounterEdit = commandCounter.counter;
    const previousCounter: CounterEdit = previousCommandCounter != null ? previousCommandCounter.counter : null;
    let result: boolean = false;
    if (previousCommandCounter == null) {
      result = true;
    } else if (counter.name.trim() != previousCounter.name.trim()) {
      result = true;
    } else if (counter.baseValue != previousCounter.baseValue) {
      result = true;
    } else if (counter.currentValue != previousCounter.currentValue) {
      result = true;
    } else if (commandCounter.toAdd != previousCommandCounter.toAdd) {
      result = true;
    } else if (commandCounter.mathSign != previousCommandCounter.mathSign) {
      result = true;
    }

    const filteredChangedCounters = filterIdentified(this.changedCounters, commandCounter);
    if (!result) {
      this.changedCounters = filteredChangedCounters;
      if (filteredChangedCounters.length == 0) {
        this.disableSaveButton.emit(false);
      }
    } else {
      this.disableSaveButton.emit(true);
      if (filteredChangedCounters.length == this.changedCounters.length) {
        this.changedCounters.push(commandCounter);
      }
    }
    if (this.changedCounters.length == 0) {
      this.setCounterChanged.emit(false)
    } else {
      this.setCounterChanged.emit(true)
    }
    return result;
  }

  /**
   * Returns if the provided counter was created.
   *
   * @param counter The counter.
   * @returns true if the provided counter was created, false otherwise.
   */
  isCreatedCounter(counter: IdentifiedCommandCounter): boolean {
    return this.createdCounters.some(cnt => cnt.id == counter.id)
  }

  /**
   * Returns if the current counters are valid.
   *
   * @returns true if the current counters are valid, false otherwise.
   */
  areCountersValid(): boolean {
    for(let counter of this.command.counters) {
      if (!this.isCounterValid(counter)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Returns if the provided command counter is valid.
   *
   * @param commandCounter The command counter.
   * @returns true if the provided command counter is valid, false otherwise.
   */
  isCounterValid(commandCounter: CommandCounterEdit): boolean {
    if (!this.counterHasChanged(commandCounter)) {
      return false;
    }
    const counter: CounterEdit = commandCounter.counter;
    if (counter.name.trim() == "") {
      return false;
    }

    if (counter.baseValue == 0) {
      return false;
    }
    return true;
  }

  /**
   * Deletes the provided command counter.
   *
   * @param commandCounter The command counter.
   */
  delete(commandCounter: CommandCounterEdit) {
    this.command.counters = filterIdentified(this.command.counters, commandCounter);
    this.hasExisting = !this.command.counters.every((cnt) => this.createdCounters.includes(cnt));
    this.existingCounterNamesById.delete(commandCounter.id);
    const counterToDelete: CounterEdit = commandCounter.counter;
    if (!this.isCreatedCounter(commandCounter)) {
      if (!this.changedCounters.some(changedCounter => changedCounter.id == commandCounter.id)) {
        this.changedCounters.push(commandCounter);
      }
      if (!this.savedCounters.has(commandCounter.id)) {
        this.savedCounters.set(commandCounter.id, commandCounter);
      }
      if (!this.unusedCounters.some(cnt => cnt.id == counterToDelete.id)) {
        this.unusedCounters.push(counterToDelete);
      }
      if (!this.displayedUnusedCounters.some(cnt => cnt.id == counterToDelete.id)) {
        this.displayedUnusedCounters.push(counterToDelete);
      }
    } else {
      this.createdCounters = filterIdentified(this.createdCounters, commandCounter);
      this.savedCounters.delete(commandCounter.id);
      this.changedCounters = filterIdentified(this.changedCounters, commandCounter);
    }
    this.setCounterChanged.emit(this.changedCounters.length != 0);
  }

  /**
   * Cancels the current creation/changing of the provided counter.
   *
   * @param commandCounter The command counter.
   */
  cancelCreateOrChangeCounter(commandCounter: CommandCounterEdit): void {
    this.changedCounters = filterIdentified(this.changedCounters, commandCounter);
    this.setCounterChanged.emit(this.changedCounters.length != 0);
    if (this.changedCounters.length == 0) {
      this.disableSaveButton.emit(false);
    }
    if (this.isCreatedCounter(commandCounter) && !this.command.counters.some(v => v.id == commandCounter.id)) {
        this.createdCounters = filterIdentified(this.createdCounters, commandCounter);
        this.fromUnusedCounters = filterIdentified(this.fromUnusedCounters, commandCounter);
        return;
    }
    this.editingCounters = filterIdentified(this.editingCounters, commandCounter);
    this.fromUnusedCounters = filterIdentified(this.fromUnusedCounters, commandCounter);
    let previousCommandCounter: CommandCounterEdit = this.savedCounters.get(commandCounter.id);
    if(previousCommandCounter == null) {
      previousCommandCounter = this.countersById.get(commandCounter.id);
    }
    if (previousCommandCounter == null) {
      throw new InternalErrorException("Unable to find any previousCommand");
    }

    const counter: CounterEdit = commandCounter.counter;
    const previousCounter: CounterEdit = previousCommandCounter.counter;
    counter.name = previousCounter.name;
    counter.baseValue = previousCounter.baseValue;
    counter.currentValue = previousCounter.currentValue;
    commandCounter.toAdd = previousCommandCounter.toAdd;
    commandCounter.mathSign = previousCommandCounter.mathSign;
  }
 }
