import { Injectable } from '@angular/core';
import { WhatsNextDisplay } from './whats-next';
import { HttpClient } from '@angular/common/http';
import { WHATS_NEXT_URL } from '../generic/backend-urls';
import { firstValueFrom } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { WhatsNextVoteEdit } from './vote/whats-next-vote';
import { WhatsNextVoteService } from './vote/whats-next-vote.service';

/**
 * A service for the what's next.
 */
@Injectable({
  providedIn: 'root'
})
export class WhatsNextService {

  /**
   * The constructor.
   *
   * @param whatsNextVoteService  The what's next vote service.
   * @param authenticationService The authentication service.
   * @param http                  The http client.
   */
  constructor(private whatsNextVoteService: WhatsNextVoteService, private authenticationService: AuthenticationService, private http: HttpClient) {

  }

  /**
   * Returns if a user is currently logged in.
   *
   * @returns true if a user is logged in, false otherwise.
   */
  isLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  /**
   * Returns all existing what's nexts.
   *
   * @return All existing what's nexts.
   */
  async findWhatsNexts(): Promise<WhatsNextDisplay[]> {
    return firstValueFrom(this.http.get<WhatsNextDisplay[]>(WHATS_NEXT_URL + "/find"));
  }

  /**
   * Returns all existing what's next votes if the user is logged in else nothing.
   *
   * @return All existing what's next votes if the user is logged in else nothing.
   */
  async findWhatsNextVotes(): Promise<WhatsNextVoteEdit[]> {
    return this.whatsNextVoteService.findWhatsNextVotes();
  }

  /**
   * Deletes the provided what's next vote.
   *
   * @param whatsNextVote The what's next vote.
   * @return A promise from the deletion.
   */
  deleteVote(vote: WhatsNextVoteEdit): Promise<any> {
    return this.whatsNextVoteService.delete(vote);
  }

  /**
   * Saves the provided what's next vote.
   *
   * @param whatsNextVote The what's next vote.
   * @return The saved what's next vote.
   */
  saveVote(vote: WhatsNextVoteEdit): Promise<WhatsNextVoteEdit> {
    return this.whatsNextVoteService.save(vote);
  }

  /**
   * Create a what's next vote for the provided what's next with the do next.
   * 
   * @param toVote The what's next to vote for.
   * @param doNext true if this what's next shell be done next, false otherwise.
   * @returns The created what's next vote.
   */
  createVote(toVote: WhatsNextDisplay, doNext: boolean): WhatsNextVoteEdit {
    return this.whatsNextVoteService.createVote(toVote, doNext);
  }
}
