import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * An interceptor for json dates.
 */
@Injectable()
export class JsonDateInterceptor implements HttpInterceptor {
    
    /**
     * Holds the ISO date format. All provided dates need to use this format.
     */
    private isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;
  
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(map( (val: HttpEvent<any>) => {
            if (val instanceof HttpResponse) {
                const body = val.body;
                this.convert(body);
            }
            return val;
        }));  
    }  
    
    /**
     * Converts all strings in the body which represent a date to a real {@link Date date}.
     *
     * @param body The body.
     */
    private convert(body: any): void {
        if (body === null || body === undefined ) {
            return body;
        }
        if (typeof body !== 'object' ) {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (this.isIsoDateString(value)) {
                body[key] = new Date(value);
            } else if (typeof value === 'object') {
                this.convert(value);
            }
        }
    }

    /**
     * Checks if the provided value is a string matching the {@link isoDateFormat ISO date format}, false otherwise.
     *
     * @param value The value to check.
     * @returns true if the value is a string matching the ISO date format, false otherwise.
     */
    private isIsoDateString(value: any): boolean {
        if (value === null || value === undefined) {
        return false;
        }
        if (typeof value === 'string'){
        return this.isoDateFormat.test(value);
        }
        return false;
    }
}