import { Component, OnInit } from '@angular/core';
import { PatchDisplay } from './patch';
import { PatchService } from './patch.service';
import { LOADING_GIF } from '../generic/img-pathes';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * The component for the patches.
 */
@Component({
  selector: 'app-patch',
  imports: [],
  templateUrl: './patch.component.html',
  styleUrl: './patch.component.css',
})
export class PatchComponent implements OnInit {

  /**
   * Holds the gif to display when we are loading the patches.
   */
  loadingImg: string = LOADING_GIF;

  /**
   * Holds if the patches are loading.
   */
  loading: boolean = true;

  /**
   * Holds the patches.
   */
  patches: PatchDisplay[] = [];

  /**
   * The constructor.
   *
   * @param patchService The patch service.
   * @param sanitizer    The sanitizer to use for the html {@link PatchDisplay.text text} of the patches.
   */
  constructor(private patchService: PatchService, private sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
    this.patchService.findAllPatches()
      .then(patches => {
        this.patches = patches.sort((p1, p2) => p1.releaseDate < p2.releaseDate ? 1 : -1);
        this.loading = false;
      });
  }

  /**
   * Returns the safe html text from the provided patch.<br>
   * We need this for being able to use e.g. style="...".
   *
   * @param patch The patch.
   * @returns The safe html text.
   */
  getPatchText(patch: PatchDisplay): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(patch.text);
  }

  /**
   * Returns the {@link PatchDisplay.releaseDate release date} of the provided patch as a string.
   *
   * @param patch The patch.
   * @returns The release date as a string.
   */
  getReleaseDate(patch: PatchDisplay): string {
    const releaseDate: Date = patch.releaseDate;
    let result: string = releaseDate.getDate() + '.';
    result = result + (releaseDate.getMonth() + 1) + '.';
    result = result + releaseDate.getFullYear();
    return result;
  }

}
