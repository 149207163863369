import { booleanAttribute, Component, Input, OnInit } from '@angular/core';
import { CommandEditService as CommandEditService } from './command-edit.service';
import { CommandEdit, CommandKey } from '../command';
import { LOADING_GIF } from '../../generic/img-pathes';
import { Router } from '@angular/router';
import { Feature } from '../../feature/feature';
import { IdentifiedUser, UserKey } from '../../user/user';
import { AuthenticationService } from '../../authentication/authentication.service';
import { InternalErrorException } from '../../generic/errors';
import { NEW_COMMAND_COUNTER_BASE_ID } from '../counter/counter-basics';
import { FormsModule } from '@angular/forms';
import { CommandCounterEditComponent } from '../counter/edit/command-counter-edit.component';
import { NgClass, NgIf } from '@angular/common';
import { NameValidator } from '../../generic/name-validator.directive';
import { UserLevel } from '../userLevel/user-level';
import { AbstractStandaloneComponent } from '../../generic/standalone-component';
import { arrayContains } from '../../generic/array-utils';
import { UserLevelEditComponent } from '../userLevel/edit/user-level-edit.component';
import { getEnumFromKey } from '../../generic/enum-utils';
import { CommandCounterEdit, IdentifiedCommandCounter } from '../counter/command-counter';
import { NOT_ACCEPTABLE } from '../../generic/http-status';
import { HttpErrorResponse } from '@angular/common/http';
import { NAME_MAX_LENGTH, TEXT_MAX_LENGTH } from '../command-basics';


/**
 * The component for creating/editing a command.
 */
@Component({
  selector: 'tu-edit-command',
  templateUrl: './command-edit.component.html',
  styleUrl:'../commands.css',
  standalone: true,
  imports: [UserLevelEditComponent, CommandCounterEditComponent, NgIf, NgClass, FormsModule, NameValidator]
})
export class CommandEditComponent extends AbstractStandaloneComponent implements OnInit {

  /**
   * Holds the max length for the name of a command.
   */
  nameMaxLength: number = NAME_MAX_LENGTH;

  /**
   * Holds the max length for the text of a command.
   */
  textMaxLength: number = TEXT_MAX_LENGTH;


  /**
   * Holds the gif to display when we are saving the command.
   */
  savingImg: string = LOADING_GIF;

  /**
   * Holds the gif to display when we are loading the command.
   */
  loadingCommandImg: string = LOADING_GIF;

  /**
   * The URL for the router to go to when the user clicks on the back button.
   */
  @Input('routeURL')
  routeURL: string;

  /**
   * Holds if this view was opend for creating a command.
   */
  @Input('forCreate')
  isForCreate: boolean = false;

  /**
   * Holds the id of the command to edit.
   */
  @Input('command')
  commandId: number;

  /**
   * Holds if the command will be created/edited for a link.
   */
  @Input({alias:'forLinks', transform: booleanAttribute})
  forLinks: boolean = false;

  /**
   * Holds if the command will be created/edited for a channel.
   */
  @Input({alias:'forChannel', transform: booleanAttribute})
  forChannel: boolean = false;

  /**
   * Holds the command of the provided commandId.
   */
  providedCommand: CommandEdit;

  /**
   * Holds if an error occurred for the command.
   */
  hasError: boolean = false;

  /**
   * Holds the message to display for the error.
   */
  errorMessage: string = "";

  /**
   * Holds the command to edit.
   */
  command: CommandEdit;

  /**
   * Holds if the save button shall be disabled by the counters.
   */
  counterDisabledSaveButton: boolean = false;

  /**
   * Holds if the save button shall be disabled by the user levels.
   */
  userLevelDisabledSaveButton: boolean = false;

  /**
   * Holds if a counter has been changed.
   */
  counterChanged: boolean = false;

  /**
   * Holds if we are loading the command.
   */
  loadingCommand: boolean = true;

  /**
   * Holds the possible user levels.
   */
  possibleFeatures: Feature[] = [];

  /**
   * Holds if the command is saving.
   */
  saving: boolean = false;

  /**
   * The constructor.
   *
   * @param commandEditService    The command edit service.
   * @param router                The router.
   * @param authenticationService The authentication service.
   */
  constructor(private commandEditService: CommandEditService, private router: Router, private authenticationService: AuthenticationService) {
    super();
  }

  ngOnInit(): void {
    let user = this.authenticationService.getUser();
    let possibleFeatures = Object.values(Feature);
    possibleFeatures = possibleFeatures.filter((feature) => feature != Feature.SPOTIFY && arrayContains(user.features, getEnumFromKey(Feature, feature)));
    this.possibleFeatures = possibleFeatures;
    if (this.commandId == null && !this.isForCreate) {
      this.commandEditService.redirectTo(null, null, false);
      return;
    }

    if (this.commandId != null && this.isForCreate) {
      this.commandEditService.redirectTo("command/create", this.routeURL, true);
      return;
    }

    if (this.commandId != null) {
      this.commandId = Number(this.commandId);
      this.initProvidedCommandEdit()
      return;
    }

    const owner: IdentifiedUser = this.authenticationService.getUser();
    const feature: Feature = this.forLinks ? Feature.TWITCH_LINKS : this.forChannel ? Feature.TWITCH_BOT :  Feature.TWITCH_LINKS;
    this.command = new CommandEdit(-1,
                                  0,
                                  "",
                                  "",
                                  [],
                                  new UserKey(owner.id),
                                  getEnumFromKey(Feature, feature),
                                  [getEnumFromKey(UserLevel, UserLevel.EVERYONE)],
                                  false);
    this.loadingCommand = false;
  }

  /**
   * Initializes the providedCommandEdit.
   */
  private initProvidedCommandEdit() {
    this.loadingCommand = true;
    this.commandEditService.reloadCommand(new CommandKey(this.commandId))
      .then((providedCommandEdit) => {
        this.providedCommand = providedCommandEdit;
        this.command = CommandEdit.copy(this.providedCommand);
        this.loadingCommand = false;
        return providedCommandEdit;
      });
  }

  /**
   * Returns if the provided feature is the current one of the command.
   *
   * @param feature The feature.
   * @returns true if the provided feature is the current one of the command, false otherwise.
   */
  isCommandFeature(feature: Feature): boolean {
    return this.command.feature == getEnumFromKey(Feature, feature);
  }

  /**
   * Gets called when the feature has changed.
   *
   * @param $event The event of the change with the feature.
   */
  featureChanged($event: any): void {
    const featureValue: Feature = this.getCurrentValueForSelect($event);
    const newFeature: Feature = getEnumFromKey(Feature, featureValue);
    this.command.feature = newFeature;
  }


  /**
   * Sets if the button shall be disabled by the counters.
   *
   * @param counterDisabledSaveButton true if the button shall be disabled by the counters, false otherwise.
   */
  setCounterDisabledSaveButton(counterDisabledSaveButton: boolean): void {
    this.counterDisabledSaveButton = counterDisabledSaveButton;
  }

  /**
   * Sets if the button shall be disabled by the user levels.
   *
   * @param userLevelDisabledSaveButton true if the button shall be disabled by the user levels, false otherwise.
   */
  setUserLevelDisabledSaveButton(userLevelDisabledSaveButton: boolean): void {
    this.userLevelDisabledSaveButton = userLevelDisabledSaveButton;
  }

  /**
   * Sets if a counter has been change.
   *
   * @param counterChanged true if a counter has been change, false otherwise.
   */
  setCounterChanged(counterChanged: boolean): void {
    this.counterChanged = counterChanged;
  }

  /**
   * Saves the command.
   */
  save() {
    if (!this.isCommandValid()) {
       return;
    }
    this.saving = true;
    const commandCounters: CommandCounterEdit[] = [];
    for (let commandCounter of this.command.counters) {
      if (this.isCreatedCounter(commandCounter)) {
        const cnt: CommandCounterEdit = new CommandCounterEdit(-1, 0, commandCounter.toAdd, commandCounter.mathSign, commandCounter.command, commandCounter.counter);
        commandCounters.push(cnt);
      } else {
        commandCounters.push(commandCounter);
      }
    }
    this.command.counters = commandCounters;
    this.commandEditService.save(this.command)
      .then(user => {
        this.authenticationService.refreshUser(user)
        this.saving = false;
        this.setWrongVersion(false);
        this.router.navigate([this.routeURL]);
      })
      .catch((err: HttpErrorResponse) => {
        this.saving = false;
        const userId: number = this.authenticationService.getUser().id;
        let status = Number(err.status);
        if (status == NOT_ACCEPTABLE) {
          this.authenticationService.reloadUser();
          this.setWrongVersion(true);
          return
        }
        const errorMessage = "An error occurred for user " + userId + " while trying to create/update the command " + JSON.stringify(this.command);
        throw new InternalErrorException(errorMessage, err)
      });
  }

  /**
   * Returns if the command is valid.
   *
   * @returns true if the command is valid, false otherwise.
   */
  private isCommandValid(): boolean {
    const failureMessage = this.commandEditService.isCommandValid(this.command);
    if (failureMessage != null) {
      this.hasError = true;
      this.errorMessage = failureMessage;
    } else {
      this.hasError = false;
      this.errorMessage = "";
    }
    return failureMessage == null;
  }

  /**
   * Sets if the command had a wrong version.
   *
   * @param wrongVersion true if the command had a wrong version, false otherwise.
   */
  private setWrongVersion(wrongVersion: boolean) {
    this.hasError = wrongVersion;
    if (wrongVersion) {
      this.errorMessage = "Der Befehl war leider von einer älteren version. Bitte versuch es erneut.";
      this.initProvidedCommandEdit();
    }
  }

  /**
   * Gets called when the public state of a command has been changed.
   *
   * @param event   The event that led to this method call.
   * @param command The command where the state was changed.
   */
  publicChanged(event: any, command: CommandEdit) {
    command.public = event.target.checked;
  }

  /**
   * Returns if the provided command counter was created.
   *
   * @param commandCounter The command counter.
   * @returns true if the provided command counter was created, false otherwise.
   */
  private isCreatedCounter(commandCounter: IdentifiedCommandCounter): boolean {
    return commandCounter.id >= NEW_COMMAND_COUNTER_BASE_ID;
  }

  /**
   * Get's called when the user clicks on the back button and returns to the {@link routeURL}.
   */
  back() {
    if (this.routeURL == null) {
      throw new InternalErrorException("The back function was called while the redirectURL is null");
    }
    this.router.navigate([this.routeURL]);
  }

  /**
   * Returns if the command hast been changed.
   *
   * @returns true if the command hast been changed, false otherwise.
   */
  commandHasChanged(): boolean {
    if (this.providedCommand == null) {
      return true;
    }

    if (this.command.name != this.providedCommand.name) {
      return true;
    }
    if (this.command.text != this.providedCommand.text) {
      return true;
    }
    if (this.command.public != this.providedCommand.public) {
      return true;
    }

    const currentUserLevels: UserLevel[] = this.command.userLevels;
    const providedUserLevels: UserLevel[] = this.providedCommand.userLevels;
    if (currentUserLevels.length != providedUserLevels.length) {
      return true;
    }
    for (let userLevel of currentUserLevels) {
      if (!arrayContains(providedUserLevels, userLevel)) {
        return true;
      }
    }

    if (this.command.feature != this.providedCommand.feature) {
      return true;
    }
    if (this.counterChanged) {
      return true;
    }

    return false;
  }

  /**
   * Returns if the user has a channel.
   *
   * @returns true if the user has a channel, false otherwise.
   */
  hasChannel(): boolean {
    return this.authenticationService.getUser().channel != null
  }

  /**
   * Returns the value from the provided feature.
   *
   * @param feature The feature to get the value from.
   * @returns The value of the provided feature.
   */
  getFeatureValue(feature: Feature): Feature {
    return Feature[feature];
  }
}
