import { booleanAttribute, Component, Input, OnInit } from '@angular/core';
import { CommandEdit, CommandKey, IdentifiedCommand, NotOwnedCommandDisplay } from './command';
import { CommandsService } from './commands.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserEdit } from '../user/user';
import { Router } from '@angular/router';
import { Feature } from '../feature/feature';
import { ADD_IMG, DELETE_IMG, EDIT_IMG, LOADING_GIF } from '../generic/img-pathes';
import { UserLevel } from './userLevel/user-level';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NotOwnedCommandsComponent } from './notOwnedCommands/not-owned-commands.component';
import { NOT_ACCEPTABLE } from '../generic/http-status';
import { filterIdentified } from '../generic/array-utils';

/**
 * The component for the commands.
 */
@Component({
    selector: 'tu-commands',
    templateUrl: './commands.component.html',
    styleUrl: './commands.css',
    imports: [ NotOwnedCommandsComponent, NgIf, NgFor, FormsModule]
})
export class CommandsComponent implements OnInit {

    /**
     * Holds the default header to display.
     */
    defaultHeader: string = "Befehle";

    /**
     * Holds if the command will be created/edited for link.
     */
    @Input({alias:'forLinks', transform: booleanAttribute})
    forLinks: boolean = false;

    /**
     * Holds if the command will be created/edited for a channel.
     */
    @Input({alias:'forChannel', transform: booleanAttribute})
    forChannel: boolean = false;

    /**
     * Holds the header to display.
     */
    @Input('header')
    header: string = "Befehle";

    /**
     * Holds the path to the add image.
     */
    addImg: string = ADD_IMG;

    /**
     * Holds the path to the edit image.
     */
    editImg: string = EDIT_IMG;

    /**
     * Holds the path to the delete image.
     */
    deleteImg: string = DELETE_IMG;

  /**
   * Holds the gif to display when we are currently deleting.
   */
    deletingImg: string = LOADING_GIF;

    /**
     * Holds the features by its names.
     */
    featureNames = new Map<string, Feature>

    /**
     * Holds the commands owned by the user.
     */
    ownedCommands: CommandEdit[] = [];

    /**
     * Holds the commands for their id.
     */
    commandsById: Map<number, CommandEdit> = new Map<number, CommandEdit>();

    /**
     * Holds if the command had a wrong version.
     */
    wrongVersion: boolean = false;

    /**
     * Holds the commands currently deleting.
     */
    commandsToDelete: IdentifiedCommand[];

    /**
     * The constructor.
     *
     * @param authenticationService The authentication service.
     * @param commandsService       The command service.
     * @param router                The router.
     */
    constructor(private authenticationService: AuthenticationService, private commandsService: CommandsService, private router: Router) {

    }

    ngOnInit(): void {
        this.initCommands();
        this.authenticationService.subscribeToUserChange((user: UserEdit) => {
            this.initCommands();
        });

        for (let [key, value] of Object.entries(Feature)) {
          this.featureNames.set(value, key as Feature);
        }
    }

    /**
     * Initializes the commands and everything needed for them.
     */
    initCommands(): void {
        this.commandsToDelete = [];
        if (this.forChannel) {
            this.ownedCommands = this.commandsService.findOwnedCommandsForChannel();
        } else if (this.forLinks) {
            this.ownedCommands = this.commandsService.findOwnedCommandsForLinks();
        } else {
            this.ownedCommands = this.commandsService.findOwnedCommandsForUser();
        }
        this.commandsById = new Map();
        for(let command of this.ownedCommands) {
            this.commandsById.set(command.id, command);
        }
    }

    /**
     * Returns the value of the provided user level.
     *
     * @returns The value of the provided user level.
     */
    getUserLevelValue(userLevel: UserLevel): UserLevel {
        return UserLevel[userLevel];
    }

    /**
     * Returns the value of the provided feature.
     *
     * @returns The value of the provided Feature.
     */
    getFeatureValue(feature: Feature): Feature {
        return Feature[feature];
    }

    /**
     * Creates a command.
     */
    createCommand(): void {
        this.commandsService.createCommand(this.forLinks, this.forChannel);
    }

    /**
     * Edits the provided command.
     *
     * @param command The command.
     */
    editCommand(command: CommandEdit): void {
        this.commandsService.editCommand(command, this.forLinks, this.forChannel);
    }

    /**
     * Gets called when the public state of a command has been changed.
     *
     * @param event   The event that led to this method call.
     * @param command The command where the state was changed.
     */
    publicChanged(event: any, command: CommandEdit) {
        command.public = event.target.checked;
        this.commandsService.save(command)
          .then(user => {
              this.wrongVersion = false;
          })
          .catch(err => {
              let status = Number(err.status);
              if (status == NOT_ACCEPTABLE) {
                  this.wrongVersion = true;
              }
          });
    }

    /**
     * Returns if the provided command is deleating at the moment.
     *
     * @param command The command.
     * @returns true if the provided command is deleating at the moment, false otherwise.
     */
    isDeletingCommand(command: CommandEdit): boolean {
        return this.commandsToDelete.filter(cmd => cmd.id == command.id).length == 1;
    }

    /**
     * Deletes the provided command.
     *
     * @param command The command.
     */
    delete(command: CommandEdit) {
        this.commandsToDelete.push(new CommandKey(command.id));
        this.commandsService.delete(command)
          .then(user => {
             this.commandsToDelete = filterIdentified(this.commandsToDelete, command as IdentifiedCommand)
              this.setWrongVersionForDelete(command, false);
          })
          .catch(err => {
              let status = Number(err.status);
              if (status == NOT_ACCEPTABLE) {
                  this.setWrongVersionForDelete(command, true);
              }
          });
    }

    /**
     * Sets if the command to delete had a wrong version.
     *
     * @param command The command.
     * @param wrongVersion true if the command to delete had a wrong version, false otherwise.
     */
    private setWrongVersionForDelete(command: IdentifiedCommand, wrongVersion: boolean) {
        this.wrongVersion = wrongVersion;
        if (wrongVersion) {
            this.commandsToDelete = this.commandsToDelete.filter(cmd => cmd.id != command.id);
            this.commandsService.reloadCommand(command);
        }
    }

    /**
     * Removes the provided command from the user.
     *
     * @param command The command.
     */
    removeCommand(command: NotOwnedCommandDisplay) {
        this.commandsService.removeNotOwned(command);
    }

    /**
     * Checks if the provided command has been edited.
     *
     * @param command The command.
     * @returns true if the command has been changed, false otherwise.
     */
    commandHasChanged(command: CommandEdit): boolean {
        if (command.id == -1) {
            return true;
        }
        const previousCommand: CommandEdit = this.commandsById.get(command.id);
        if (command.name.trim() != previousCommand.name.trim()) {
            return true;
        }
        if (command.text.trim() != previousCommand.text.trim()) {
            return true;
        }
        if (command.public != previousCommand.public) {
            return true;
        }
        return false;
    }
}
