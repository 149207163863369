import { Injectable } from '@angular/core';
import { WhatsNextVoteEdit } from './whats-next-vote';
import { AuthenticationService } from '../../authentication/authentication.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { WHATS_NEXT_VOTE_URL } from '../../generic/backend-urls';
import { WhatsNextDisplay } from '../whats-next';

/**
 * A service for the what's next vote.
 */
@Injectable({
  providedIn: 'root'
})
export class WhatsNextVoteService {

  /**
   * The constructor.
   * 
   * @param authenticationService The authentication service.
   * @param http                  The http client.
   */
  constructor(private authenticationService: AuthenticationService, private http: HttpClient) {

  }

  /**
   * Returns all existing what's next votes if the user is logged in else nothing.
   *
   * @return All existing what's next votes if the user is logged in else nothing.
   */
  async findWhatsNextVotes(): Promise<WhatsNextVoteEdit[]> {
    if (!this.authenticationService.isLoggedIn()) {
      return [];
    }
    return firstValueFrom(this.http.get<WhatsNextVoteEdit[]>(WHATS_NEXT_VOTE_URL + "/find"));
  }

  /**
   * Deletes the provided what's next vote.
   *
   * @param whatsNextVote The what's next vote.
   * @return A promise from the deletion.
   */
  delete(vote: WhatsNextVoteEdit): Promise<any> {
    return firstValueFrom(this.http.post<any>(WHATS_NEXT_VOTE_URL + "/delete", vote));
  }

  /**
   * Saves the provided what's next vote.
   *
   * @param whatsNextVote The what's next vote.
   * @return The saved what's next vote.
   */
  save(vote: WhatsNextVoteEdit): Promise<WhatsNextVoteEdit> {
    return firstValueFrom(this.http.post<WhatsNextVoteEdit>(WHATS_NEXT_VOTE_URL + "/save", vote));
  }
  
  /**
   * Create a what's next vote for the provided what's next with the do next.
   * 
   * @param toVote The what's next to vote for.
   * @param doNext true if this what's next shell be done next, false otherwise.
   * @returns The created what's next vote.
   */
  createVote(toVote: WhatsNextDisplay, doNext: boolean): WhatsNextVoteEdit {
    return new WhatsNextVoteEdit(-1, this.authenticationService.getUser(), toVote, doNext);
  }

}
