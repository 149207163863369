import { Component, Input, OnInit } from '@angular/core';
import { Feature } from '../../feature/feature';
import { Router } from '@angular/router';
import { getEnumFromKey } from '../../generic/enum-utils';
import { AuthenticationService } from '../authentication.service';
import { UserEdit } from '../../user/user';
import { InternalErrorException } from '../../generic/errors';
import { CreateChannelService } from '../../channel/create/create-channel.service';
import { LOADING_GIF } from '../../generic/img-pathes';

/**
 * This component gets called from the backend after the login of a feature.
 */
@Component({
  selector: 'app-logged-in',
  imports: [],
  template: '<img [src]="loadingGif" class="loading-img-centered" alt="Logging in">',
})
export class LoggedInComponent implements OnInit {

  /**
   * Holds the gif to display when we are currently redirecting.
   */
  loadingGif: string = LOADING_GIF;

  /**
   * The feature that was logged in.
   */
  @Input({alias: "feature", required: true})
  feature: Feature;

  /**
   * The feature that was logged in.
   */
  @Input("channelName")
  channelName?: string;

  /**
   * The constructor.
   *
   * @param authenticationService The authentication service.
   * @param createChannelService  The create channel service .
   * @param router                The router.
   */
  constructor(private authenticationService: AuthenticationService,
              private createChannelService: CreateChannelService,
              private router: Router) {

  }

  ngOnInit(): void {
    if (this.feature == getEnumFromKey(Feature, Feature.TWITCH_BOT)) {
      const user: UserEdit = this.authenticationService.getUser();
      if (user.channel == null) {
        if(this.channelName == null) {
          throw new InternalErrorException("No channel name was set while logging in to the Feature.TWITCH_BOT with a user without a channel");
        }
        this.createChannelService.save(this.channelName)
          .then(value => {
            this.router.navigate(["profile"], {queryParams: {channelCreated: true}});
          });
        return;
      }
    }
    this.router.navigate(["profile"]);
  }
}
