import { Injectable } from '@angular/core';
import { ChannelEdit } from '../channel';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../authentication/authentication.service';
import { CHANNEL_URL } from '../../generic/backend-urls';
import { UserEdit, UserKey } from '../../user/user';
import { firstValueFrom } from 'rxjs';
import { InternalErrorException } from '../../generic/errors';

/**
 * A service for the creation of a channel.
 */
@Injectable({
  providedIn: 'root'
})
export class CreateChannelService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   */
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  /**
   * Creates a channel with the provided values.
   *
   * @param channelName The name of the channel.
   * @returns A promise from saving the channel.
   */
  save(channelName: string): Promise<any> {
    const loggedInUser = this.authenticationService.getUser();
    const channelToSave = new ChannelEdit(-1, 0, channelName, [], new UserKey(loggedInUser.id));
    const errorMessage = "An error occurred for user " + loggedInUser.id + " while trying to create a chanel with the name " + channelName;
    const result = firstValueFrom<UserEdit>(this.http.post<UserEdit>(CHANNEL_URL + "/save", channelToSave));
    result.then(user => {
      this.authenticationService.refreshUser(user)
    })
    .catch((err: Error) => {
      throw new InternalErrorException(errorMessage, err);
    });
    return result;
  }

}
