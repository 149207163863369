import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AUTHENTICATION_URL } from '../../generic/backend-urls';
import { firstValueFrom } from 'rxjs';

/**
 * A service for the password reset.
 */
@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  /**
   * The constructor.
   *
   * @param http The http client.
   */
  constructor(private http: HttpClient) { }

  /**
   * Changes the password from the current one to the new if it is correct.
   *
   * @param currentPw The current password.
   * @param newPw     The new password.
   * @returns The response from the change request.
   */
  changePassword(currentPw: string, newPw: string): Promise<any> {
    return firstValueFrom(this.http.post(AUTHENTICATION_URL + "/change-pw", { currentPw: currentPw, newPw: newPw }));
  }

}
