import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * The error handler for this app.
 */
@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  /**
   * The constructor.
   *
   * @param router The router.
   */
  constructor(private router: Router) {
    super();
  }

  handleError(error: Error) {
    console.error(error);
    if (error instanceof TypeError && error.message == "n.valueAccessor is null") { // We weren't able to find the reason for this yet
      return;
    }
    this.router.navigate(['error'], {queryParams: {errorMessage: error.message}});
  }
}
