<h1>Links</h1>
Ich beschreibe hier wie du die Links bei <a class="default-link" href="https://www.nightbot.tv" target="_blank">Nightbot</a> verwenden kannst.<br>
Es kann bestimmt auch von anderen Bots genutzt werden, aber ich habe bisher nur Nightbot verwendet.<br>
<br>
<img [src]="createCommand" class="img-border" alt="Befehl erstellen Beispiel"><br>
<br>
Um diesen Befehl auszuführen kannst du folgenden Command bei Nightbot erstellen:<br>
<img [src]="createNightbotCommand" alt="Befehl erstellen Beispiel bei Nightbot"><br>
<ul>
    <li>
        <span class="enumeration">$(urlfetch ...)</span>: Gibt den Wert zurück, welcher von der übergebenen URL erhalten wird.
    </li>
    <li>
        <span class="enumeration">https://pinkpanth3r.de/twitch/links/find</span>: Gibt das Ergebnis von dem gesuchten Befehl zurück.
    </li>
    <li>
        <span class="enumeration">user_token</span>: Diesen musst du durch deinen eigenen ersetzen. Du findest ihn in deinem
        <a *ngIf="isLoggedIn" routerLink="/profile" class="default-link">Profil</a>
        <span *ngIf="!isLoggedIn">Profil</span>
        und du kannst ihn jederzeit in den 
        <a *ngIf="isLoggedIn" routerLink="/settings" class="default-link">Einstellungen</a>
        <span *ngIf="!isLoggedIn">Einstellungen</span>
        ändern.
        Diesen benötige ich, um dich auf dem Server zu verifizieren.
    </li>
    <li>
        <span class="enumeration">name</span>: Der Name von dem Befehl, welchen du ausführen möchtest.
    </li>
    <li>
        <span class="enumeration">parameters</span>: Die Parameter, welche du für den Befehl übergeben willst.
    </li>
</ul>
Hier siehst du, wie der Befehlt ausgeführt wird:<br>
<img [src]="executedCommand" alt="Befehl ausführen Beispiel"><br>
<ul>
    <li>
        Das <span style="color: deeppink;">$(1)</span> wird durch das ersetzt, was nach dem Befehl eingegeben wurde.<br>
        <span class="note">Wenn mehrere Parameter verwendet werden, dann kannst du diese, durch ein Leerzeichen getrennt, dahinter eingeben.</span>
    </li>
    <li>
        Das <span style="color: deeppink;">$(user)</span> wird durch denjenigen ersetzt, welcher den Befehlt abgeschickt hat.
    </li>
</ul>
Weitere geplante möglichkeiten siehst du unter <a class="default-link" routerLink="/whats-next" target="_blank">Was kommt noch?</a><br>
Wenn dir irgendwas einfällt, was du hier gerne dabei hättest, dann schreibe gerne eine E-Mail an features&#64;pinkpanth3r.de.
