
/**
 * Returns the enum from the key for the provided value.
 * 
 * @param enumeration The enum to handle.
 * @param enumValue   The value of the enum.
 * @returns The enum from the key for the provided value.
 */
export function getEnumFromKey<E>(enumeration: any, enumValue: any): E {
    for (let [key, value] of Object.entries(enumeration)) {
      if (value == enumValue) {
        return key as E;
      }
    }
    return null;
  }

  