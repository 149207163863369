import { Component, Input } from '@angular/core';

/**
 * This page gets called when an error occurred.
 */
@Component({
  selector: 'app-error',
  imports: [],
  templateUrl: './error.component.html',
})
export class ErrorComponent {

  /**
   * Holds the message of the error that occurred.
   */
  @Input({alias: "errorMessage", required: true})
  errorMessage: string;

}
