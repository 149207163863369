import { Component, OnInit } from '@angular/core';
import { UserEdit } from '../user/user';
import { AuthenticationService } from '../authentication/authentication.service';
import { Language } from '../generic/language';
import { Feature } from '../feature/feature';
import { SettingsService } from './settings.service';
import { AuthenticationException } from '../generic/errors';
import { LOADING_GIF } from '../generic/img-pathes';
import { getEnumFromKey } from '../generic/enum-utils';
import { Router } from '@angular/router';

/**
 * The component for the settings.
 */
@Component({
  selector: 'tu-settings',
  templateUrl: './settings.component.html',
  standalone: false
})
export class SettingsComponent implements OnInit {

  /**
   * Holds the default token. This will be displayed, before the user decides to show it.
   */
  private hiddenToken: string = "**************";

  /**
   * Holds the user for the settings.
   */
  settingsUser: UserEdit;

  /**
   * Holds the token.
   */
  displayedToken: string = this.hiddenToken;

  /**
   * Holds if the token is displayied.
   */
  isTokenDisplayed: boolean = false;

  /**
   * Holds the currently selected language.
   */
  selectedLanguage: Language;

  /**
   * Holds the features to use.
   */
  features: Feature[] = [Feature.SPOTIFY, Feature.TWITCH_LINKS, Feature.TWITCH_BOT];

  /**
   * Holds if an error occurred for the command.
   */
  hasError: boolean = false;

  /**
   * Holds the message to display for the error.
   */
  errorMessage: string = "";

  /**
   * Holds the gif to display instead of the save button while saving.
   */
  savingGif: string = LOADING_GIF;

  /**
   * Holds the gif to display when generating a token.
   */
  generatingTokenGif: string = LOADING_GIF;

  /**
   * Holds if the component is saving at the moment.
   */
  saving: boolean = false;

  /**
   * Holds if we are generating a token.
   */
  generatingToken: boolean = false;

  /**
   * The constructor.
   *
   * @param authenticationService The authentication service.
   * @param settingsService       The settings service.
   * @param router                The router.
   */
  constructor(private authenticationService: AuthenticationService, private settingsService: SettingsService, private router: Router) {

  }

  ngOnInit(): void {
    let user = this.authenticationService.getUser();
    if (user == null) {
      throw new AuthenticationException("No user is logged in while someone was able to go to the settings page");
    }

    this.authenticationService.subscribeToUserChange((user: UserEdit) => {
      this.settingsUser = user;
    });

    this.settingsUser = UserEdit.copy(user);
    this.selectedLanguage = Language[user.language];
  }

  /**
   * Saves the user.
   */
  save() {
    this.saving = true;
    this.areSettingsValid().then(settingsValid => {
      if(!settingsValid) {
        this.saving = false;
        this.hasError = true;
        this.errorMessage = "Es existiert bereits ein Benutzer mit diesem Namen";
        return;
      }
      this.hasError = false;
      this.errorMessage = "";
      this.settingsService.save(this.settingsUser, this.setWrongVersion.bind(this));
      this.saving = false;
    })
  }

  /**
   * Navigates to the reset password component.
   */
  changePassword() {
    this.router.navigate(["/settings/reset-password"]);
  }

  /**
   * Returns a promise if the settings are valid.
   *
   * @returns A promise if the settings are valid.
   */
  private async areSettingsValid(): Promise<boolean> {
    return this.settingsService.checkNameExists(this.settingsUser.username)
      .then(exists => {
        if(exists) {
          return false;
        } else {
          return true;
        }
      });
  }

  /**
   * Sets if the user had a wrong version.
   *
   * @param wrongVersion true if the user had a wrong version, false otherwise.
   */
  private setWrongVersion(wrongVersion: boolean) {
    this.hasError = wrongVersion;
    if (wrongVersion) {
      this.errorMessage = "Die Version von den Einstellungen war leider nicht korrekt.<br>Bitte versuch es erneut."
      this.settingsUser = UserEdit.copy(this.authenticationService.getUser());
      this.selectedLanguage = Language[this.settingsUser.language];
    }
  }

  /**
   * Returns the languages.
   *
   * @returns The languages.
   */
  languages(): Language[] {
    return Object.values(Language);
  }

  /**
   * Returns if the provided feature active.
   *
   * @param feature The feature.
   * @returns true if the provided feature is active, false otherwise.
   */
  isFeatureActive(feature: Feature): boolean {
    return this.settingsUser.features.some((f) => f == getEnumFromKey(Feature, feature));
  }

  /**
   * Gets called when a feature was add/removed.
   */
  featuresChanged(event: any, featureName: Feature) {
    const feature : Feature = getEnumFromKey(Feature, featureName);
    if(event.target.checked) {
      this.settingsUser.features.push(feature);
    } else {
      this.settingsUser.features = this.settingsUser.features.filter(toFilter => toFilter != feature);
    }
  }

  /**
   * Gets called when the selected language has changed.
   */
  languageChanged() {
    this.settingsUser.language = getEnumFromKey(Language, this.selectedLanguage);
  }

  /**
   * Returns if the user has been changed.
   *
   * @returns true if he user has been changed, false otherwise.
   */
  hasUserChanged(): boolean {
    let user = this.authenticationService.getUser();
    if (this.settingsUser.username != user.username) {
      return true;
    }
    if (this.settingsUser.email != user.email) {
      return true;
    }
    if (this.settingsUser.token != user.token) {
      return true;
    }
    if (this.settingsUser.language != user.language) {
      return true;
    }

    const settingFeatures: Feature[] = this.settingsUser.features;
    const userFeatures: Feature[] = user.features;
    if (settingFeatures.length != userFeatures.length) {
      return true;
    }
    for (let userFeature of userFeatures) {
      let notContained = !settingFeatures.some((feature) => feature == userFeature);
      if (notContained) {
        return true;
      }
    }

    return false;
  }

  /**
   * Returns if the links feature is active.
   *
   * @returns true if the links feature is active, false otherwise.
   */
  hasLinksFeature(): boolean {
    return this.authenticationService.hasFeature(Feature.TWITCH_LINKS);
  }

  /**
   * Changes the {@link displayedToken} to the real token of the {@link settingsUser}.
   */
  displayToken(): void {
    this.displayedToken = this.settingsUser.token;
    this.isTokenDisplayed = true;
  }

  /**
   * Changes the {@link displayedToken} to the {@link hiddenToken} token.
   */
  hideToken(): void {
    this.displayedToken = this.hiddenToken;
    this.isTokenDisplayed = false;
  }

  /**
   * Generates a token for a user.
   */
  generateToken() {
    this.generatingToken = true;
    this.settingsService.generateToken()
      .then(token => {
        if (this.isTokenDisplayed) {
          this.displayedToken = token;
        }
        this.settingsUser.token = token;
        this.generatingToken = false;
      });
  }

}
