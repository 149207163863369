import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SpotifyDescComponent } from './desc/spotify/spotify-desc.component';
import { DoubleLoginActive } from './authentication/double-login.active';
import { ProfileComponent } from './profile/profile.component';
import { LoginActive } from './authentication/login.active';
import { SettingsComponent } from './settings/settings.component';
import { ChannelComponent } from './channel/channel.component';
import { CommandsComponent } from './command/commands.component';
import { CommandSearchComponent } from './command/search/command-search.component';
import { CommandEditComponent } from './command/edit/command-edit.component';
import { BotDescComponent } from './desc/bot/bot-desc.component';
import { LinksDescComponent } from './desc/links/links-desc.component';
import { CommandsDescComponent } from './desc/commands/commands-desc.component';
import { WhatsNextComponent } from './whats-next/whats-next.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LinksComponent } from './links/links.component';
import { ResetPasswordComponent } from './settings/reset-password/reset-password.component';
import { PatchComponent } from './patch/patch.component';
import { CountersComponent } from './counter/counters.component';
import { LoggedInComponent } from './authentication/logged-in/logged-in.component';
import { ErrorComponent } from './error/error.component';

/**
 * Holds the routes for the app.
 */
export const appRoutes: Routes = [
  { path: 'desc/commands', component: CommandsDescComponent },
  { path: 'desc/spotify', component: SpotifyDescComponent },
  { path: 'desc/bot', component: BotDescComponent },
  { path: 'desc/links', component: LinksDescComponent },
  { path: 'whats-next', component: WhatsNextComponent },
  { path: 'login', component: LoginComponent, canActivate: [DoubleLoginActive] },
  { path: 'profile', component: ProfileComponent, canActivate:[LoginActive] },
  { path: 'channel', component: ChannelComponent, canActivate:[LoginActive] },
  { path: 'links', component: LinksComponent, canActivate:[LoginActive] },
  { path: 'commands/search', component: CommandSearchComponent, canActivate:[LoginActive] },
  { path: 'commands/create', component: CommandEditComponent, canActivate:[LoginActive] },
  { path: 'commands/edit', component: CommandEditComponent, canActivate:[LoginActive] },
  { path: 'commands', component: CommandsComponent, canActivate:[LoginActive] },
  { path: 'counters', component: CountersComponent, canActivate:[LoginActive] },
  { path: 'settings', component: SettingsComponent, canActivate:[LoginActive] },
  { path: 'settings/reset-password', component: ResetPasswordComponent },
  { path: 'patches', component: PatchComponent },
  { path: 'logged-in', component: LoggedInComponent },
  { path: 'error', component: ErrorComponent },
  { path: '', component: HomeComponent },
  { path: '**', component: PageNotFoundComponent }
];

/**
 * Holds the routes
 */
export const routes = RouterModule.forRoot(appRoutes);
