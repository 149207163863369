import { Injectable } from '@angular/core';
import { IdentifiedCommand, NotOwnedCommandDisplay } from '../command';
import { AuthenticationService } from '../../authentication/authentication.service';
import { UserEdit } from '../../user/user';
import { Feature } from '../../feature/feature';
import { firstValueFrom } from 'rxjs';
import { COMMAND_URL } from '../../generic/backend-urls';
import { HttpClient } from '@angular/common/http';
import { getEnumFromKey } from '../../generic/enum-utils';

/**
 * A service for the not owned commands.
 */
@Injectable({
  providedIn: 'root'
})
export class NotOwnedCommandsService {

  /**
   * The constructor.
   * 
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   */
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  /**
   * Returns the commands not owned for the links.
   *
   * @returns The commands owned for the links.
   */
  findNotOwnedCommandsForLinks(): NotOwnedCommandDisplay[] {
    return this.findNotOwnedCommandsForFeature(Feature.TWITCH_LINKS)
  }

  /**
   * Returns the commands not owned for the channel.
   *
   * @returns The commands not owned for the channel.
   */
  findNotOwnedCommandsForChannel(): NotOwnedCommandDisplay[] {
    return this.findNotOwnedCommandsForFeature(Feature.TWITCH_BOT)
  }

  /**
   * Returns the commands not owned for the provided feature.
   *
   * @param feature The feature.
   * @returns The commands not owned for the provided feature.
   */
  private findNotOwnedCommandsForFeature(feature: Feature): NotOwnedCommandDisplay[] {
    const user: UserEdit = this.authenticationService.getUser();
    const result: NotOwnedCommandDisplay[] = [];
    const commands = user.notOwnedCommands.filter(cmd => 
      cmd.feature == getEnumFromKey(Feature, feature) ||
      cmd.feature == getEnumFromKey(Feature, Feature.SPOTIFY));
    commands.forEach(cmd => result.push(cmd));
    return result;
  }

  /**
   * Returns the commands not owned by the user.
   *
   * @returns The commands not owned by the user.
   */
  findNotOwnedCommandsForUser(): NotOwnedCommandDisplay[] {
    const user: UserEdit = this.authenticationService.getUser();
    return user.notOwnedCommands;
  }
  

  /**
   * Searches the not owned commands for the user.
   */
  async findNotOwnedCommandsForSearch(forLinks: boolean, forChannel: boolean): Promise<NotOwnedCommandDisplay[]> {
    let url: string = COMMAND_URL;
    if (forLinks) {
      url += '/findLinksNotOwned';
    } else if (forChannel) {
      url += '/findChannelNotOwned';
    } else {
      url += '/findUserNotOwned';
    }
    return firstValueFrom<NotOwnedCommandDisplay[]>(this.http.post<NotOwnedCommandDisplay[]>(url, {}));
  }

  

  /**
   * Returns if the user is is allowed to use this command.
   *
   * @param notOwnedCommand The command.
   * @returns true if the user is allowed to use this command, false otherwise.
   */
  isCommandAllowed(notOwnedCommand: NotOwnedCommandDisplay): boolean {
    const userFeatures = this.authenticationService.getUser().features;
    const commandFeature = notOwnedCommand.feature;

    if (userFeatures.includes(commandFeature)) {
      return true;
    }
    return false;
  }
  

  /**
   * Returns if the user already uses this command.
   *
   * @param command The not owned command to check.
   * @returns true if the user already uses this command, false otherwise.
   */
  doesUserUseCommand(command: IdentifiedCommand): boolean {
    let userNotOwnedCommands = this.authenticationService.getUser().notOwnedCommands;
    for (let userNotOwnedCommand of userNotOwnedCommands) {
        if (userNotOwnedCommand.id == command.id) {
          return true;
        }
    }
    return false;
  }


  /**
   * Returns the missing feature for the provided command, or null if non exists.
   *
   * @param notOwnedCommand The not owned command to check.
   * @returns The missing feature for the provided command, or null if non exists.
   */
  findMissingFeature(notOwnedCommand: NotOwnedCommandDisplay): Feature {
    const userFeatures = this.authenticationService.getUser().features;
    const commandFeature = notOwnedCommand.feature;

    if (!userFeatures.includes(commandFeature)) {
      return commandFeature;
    }
    return null;
  }

}
