<h1>Befehle suchen</h1>
<div *ngIf="hasError" class="error-message" [innerHTML]="errorMessage">
  {{errorMessage}}
</div>
@if (forLinks) {
  <tu-not-owned-commands forLinks="true" forSearch="true" (addRequest)="addCommand($event)"></tu-not-owned-commands>
} @else if (forChannel) {
  <tu-not-owned-commands forChannel="true" forSearch="true" (addRequest)="addCommand($event)"></tu-not-owned-commands>
} @else{
  <tu-not-owned-commands forSearch="true" (addRequest)="addCommand($event)"></tu-not-owned-commands>
}
<button *ngIf="routeURL != null" (click)="back()" style="margin-top: 15px;">Zurück</button>
