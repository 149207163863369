import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PasswordValidator } from './password-validator.directive';
import { ResetPasswordService } from './reset-password.service';
import { NOT_ACCEPTABLE } from '../../generic/http-status';
import { Router } from '@angular/router';
import { LOADING_GIF } from '../../generic/img-pathes';

/**
 * The component for resetting the password.
 */
@Component({
  selector: 'tu-reset-password',
  imports: [FormsModule, PasswordValidator, NgIf],
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {

  /**
   * Holds a gif to display while saving.
   * @see saving
   */
  savingGif: string = LOADING_GIF;

  /**
   * Holds the current password entered by the user.
   */
  currentPw: string = "";

  /**
   * Holds the new password entered by the user at the first input.
   */
  newPwOne: string = "";

  /**
   * Holds the new password entered by the user at the second input.
   */
  newPwTwo: string = "";

  /**
   * Holds if the password is currently saving.
   */
  saving: boolean = false;

  /**
   * An error that occurred while saving.
   */
  saveError: string;

  /**
   * The constructor.
   *
   * @param resetPasswordService The rest password service.
   * @param router               The router.
   */
  constructor(private resetPasswordService: ResetPasswordService, private router: Router) {

  }

  /**
   * Saves the password.
   */
  save(): void {
    this.saving = true;
    this.saveError = null
    this.resetPasswordService.changePassword(this.currentPw, this.newPwOne)
      .then(response => {
        this.saving = false;
        this.router.navigate(["settings"]);
      })
      .catch(reason => {
        this.saving = false;
        if (reason.status == NOT_ACCEPTABLE) {
          this.saveError = "Das derzeit verwendete Password ist nicht korrekt.";
        } else {
          console.log(reason);
          this.saveError = "Ein mir unbekannter fehler ist aufgetreten. Sollte dies erneut passieren, dann melde dich gerne bei support@pinkpanth3r.de.";
        }
      });
  }

  /**
   * Gets called when something was changed in the inputs.
   */
  onChange(): void {
    this.saveError = null;
  }

  /**
   * Cancels the password reset.
   */
  cancel(): void {
    this.router.navigate(["settings"]);
  }

}
