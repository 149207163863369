import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserLevel } from '../user-level';
import { AbstractStandaloneComponent } from '../../../generic/standalone-component';
import { FormsModule } from '@angular/forms';
import { CommandEdit } from '../../command';
import { ADD_IMG, DELETE_IMG } from '../../../generic/img-pathes';
import { InternalErrorException } from '../../../generic/errors';
import { arrayContains } from '../../../generic/array-utils';
import { getEnumFromKey } from '../../../generic/enum-utils';

/**
 * The component for editing the user levels on a command.
 */
@Component({
  selector: 'tu-user-level-edit',
  standalone: true,
  imports: [FormsModule],
  styleUrl: '../../commands.css',
  templateUrl: './user-level-edit.component.html',
})
export class UserLevelEditComponent extends AbstractStandaloneComponent implements AfterViewInit, OnInit {

  /**
   * Holds the path to the add image.
   */
  addImg: string = ADD_IMG;

  /**
   * Holds the path to the delete image.
   */
  deleteImg: string = DELETE_IMG;

  /**
   * Holds the possible user levels.
   */
  possibleUserLevel = Object.values(UserLevel);

  /**
   * Holds the method to get called whenever the save button shall be disabled.
   */
  @Output("saveButtonDisabled")
  disableSaveButton: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Holds the command of the user levels.
   */
  @Input({alias:"command", required: true})
  command: CommandEdit;

  /**
   * Holds if {@link UserLevel.EVERYONE} is selected.
   */
  everyoneSelected: boolean = false;

  /**
   * The constructor.
   */
  constructor() {
    super()
  }

  ngOnInit(): void {
    this.everyoneSelected = arrayContains(this.command.userLevels, getEnumFromKey(UserLevel, UserLevel.EVERYONE));
  }

  ngAfterViewInit(): void {
    if (!this.disableSaveButton.observed) {
      throw new InternalErrorException("A disableSaveButton should always been provided");
    }
  }

  /**
   * Adds a user level.
   */
  addUserLevel(): void {
    this.command.userLevels.push(getEnumFromKey(UserLevel, UserLevel.OWNER));
    if (this.command.userLevels.length == Object.keys(UserLevel).length - 1) {
      this.command.userLevels = [getEnumFromKey(UserLevel, UserLevel.EVERYONE)];
    }
    this.checkUserLevels();
  }

  /**
   * Gets called when a user level has changed.
   *
   * @param $event The event of the change with the new user level.
   * @param index  The index of the current user level.
   */
  userLevelChanged($event: any, index: number): void {
    const newUserLevelKeyValue: UserLevel = this.getCurrentValueForSelect($event);
    let newUserLevelValue: UserLevel = getEnumFromKey(UserLevel, newUserLevelKeyValue);
    this.command.userLevels[index] = newUserLevelValue;
    this.checkUserLevels();
  }

  /**
   * Checks if the user levels are valid.
   */
  private checkUserLevels(): void {
    if (arrayContains(this.command.userLevels, getEnumFromKey(UserLevel, UserLevel.EVERYONE))) {
      this.everyoneSelected = true;
      if (this.command.userLevels.length > 1) {
        this.command.userLevels = this.command.userLevels.filter((ul) => ul == getEnumFromKey(UserLevel, UserLevel.EVERYONE));
      }
      this.disableSaveButton.emit(false);
      return;
    }
    this.everyoneSelected = false;
    const selectedUserLevels: UserLevel[] = [];
    for (let userLevel of this.command.userLevels) {
      if (arrayContains(selectedUserLevels, userLevel)) {
        this.disableSaveButton.emit(true);
        return;
      }
      selectedUserLevels.push(userLevel);
    }
    this.disableSaveButton.emit(false);
  }

  /**
   * Returs if the provided value is the one of the provided user level.
   * 
   * @returns true if the provided value is the one of the provided user level, false otherwise.
   */
  isValueOf(value: String, userLevel: UserLevel): boolean {
    return value == UserLevel[userLevel];
  }

  /**
   * Returns the value of the user level.
   *
   * @param userLevel The user level.
   * @returns The value of the user level.
   */
  getUserLevelValue(userLevel: string): UserLevel {
    return UserLevel[userLevel];
  }

  /**
   * Deletes the user level at the provided index.
   *
   * @param index The index of the user level to delete.
   */
  delete(index: number): void {
    this.command.userLevels.splice(index, 1);
    this.checkUserLevels()
  }
 }
