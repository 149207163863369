import { Injectable } from '@angular/core';
import { UserEdit } from '../user/user';
import { UserResponse } from '../user/user-response';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AUTHENTICATION_URL, SPOTIFY_URL, TWITCH_BOT_URL, TWITCH_LINKS_URL } from '../generic/backend-urls';
import { InternalErrorException } from '../generic/errors';
import { Feature } from '../feature/feature';
import { arrayContains } from '../generic/array-utils';
import { getEnumFromKey } from '../generic/enum-utils';

/**
 * A service for the authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  /**
   * Holds the subject for changes on the logged-in user.
   */
  private userChangedSub = new Subject<UserEdit>();


  /**
   * Holds the observable of the subject for changes on the logged-in user.
   */
  private userChangedSubObs: Observable<UserEdit>;

  /**
   * The constructor.
   *
   * @param http   The http client.
   */
  constructor(private http: HttpClient) {
    this.userChangedSubObs = this.userChangedSub.asObservable();
   }

   /**
    * Adds the provided listener to changes on the user.
    *
    * @param listener The listener to add to changes on the user.
    */
  subscribeToUserChange(listener: (data: UserEdit) => void) {
    this.userChangedSubObs.subscribe(listener);
  }

  /**
   * Returns the currently logged in user.
   *
   * @returns The user.
   */
  getUser(): UserEdit {
    return JSON.parse(localStorage.getItem('user'));
  }

  /**
   * Returns the token of the logged in user.
   *
   * @returns The token.
   */
  private getToken(): String {
    return localStorage.getItem('token');
  }

  /**
   * Refreshes the currently logged-in user with the provided user and the login token.
   *
   * @param user       The user to use for the refresh.
   * @param loginToken The login token to use for the refresh.
   */
  refreshUser(user: UserEdit, loginToken?: string): void {
    localStorage.setItem("user", JSON.stringify(user));
    if (loginToken != null) {
      localStorage.setItem("token", loginToken);
    }

    this.userChangedSub.next(user);

  }

  /**
   * Returns a promise with a token generated for a user.
   *
   * @return The promise with a token generated for a user.
   */
  generateToken(): Promise<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return firstValueFrom(this.http.post<string>(AUTHENTICATION_URL + "/generateToken", {}, { headers, responseType: 'text' as 'json' }))
  }

  /**
   * Sets the currently logged in user from the provided user response.
   *
   * @param userResponse The user response.
   */
  setLoggedIn(userResponse: UserResponse): void {
    let token = userResponse.token;
    let user = userResponse.user;
    if (token == null) {
      throw new InternalErrorException("The userResponse should contain a token");
    }
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    this.userChangedSub.next(user);
  }

  /**
   * Logs out the currently logged in user.
   */
  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  /**
   * Returns if a user is currently logged in.
   *
   * @returns true if a user is logged in, false otherwise.
   */
  isLoggedIn(): boolean {
    let user = this.getUser();
    let token =  this.getToken();
    if (token == null && user == null) {
      return false;
    } else if (token == null || user == null) {
      this.logout();
      return false;
    }
    return true;
  }


  /**
   * Returns the promise for the logged-in features.
   *
   * @return The promise for the logged-in features.
   */
  async findLoggedInFeatures(): Promise<Feature[]> {
      return await firstValueFrom<Feature[]>(this.http.post<Feature[]>(AUTHENTICATION_URL + "/logged-in", {}));
  }

  /**
   * Logs in the user to the provided feature.
   *
   * @param feature The feature.
   */
  login(feature: Feature): void {
      let featureToLogin = Feature[feature];
      let url: string = '';
      if (featureToLogin == Feature.SPOTIFY) {
          url = SPOTIFY_URL;
      } else if (featureToLogin == Feature.TWITCH_LINKS) {
          url = TWITCH_LINKS_URL;
      } else if (featureToLogin == Feature.TWITCH_BOT) {
          url = TWITCH_BOT_URL;
      } else {
          throw new Error("A unknown feature was provided: " + feature);
      }
      let user: UserEdit = this.getUser();
      url = url +  "/login?user_token=" + user.token + "&from_frontend=true";
      window.location.href=url;
  }

  /**
   * Logs out the user from the provided feature.
   *
   * @param feature The feature.
   * @returns The promise from the request to logout.
   */
  async logoutFeature(feature: Feature): Promise<Object> {
      let url: string = AUTHENTICATION_URL + "/logout?feature=" + feature;
      return firstValueFrom(this.http.post(url, {}));
  }

  /**
   * Reloads the user.
   */
  reloadUser(): void {
    firstValueFrom(this.http.post<UserEdit>(AUTHENTICATION_URL + '/findUser', {}))
      .then((user: UserEdit) => {
        this.refreshUser(user);
      })
      .catch((err: Error) => {
        const errorMessage = "An error occurred while trying to reload the user";
        console.error(errorMessage, err);
        throw new InternalErrorException(errorMessage, err)
      }) ;
  }

  /**
   * Returns if the user has the provided feature.
   *
   * @param feature The feature.
   * @returns true if the user has the provided feature, false otherwise.
   */
  hasFeature(feature: Feature): boolean {
    return arrayContains(this.getUser().features, getEnumFromKey(Feature, feature));
  }

}
