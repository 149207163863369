<h1>Counters</h1>
<div *ngIf="wrongVersion" class="error-message">
  Die Version von dem Counter war leider nicht korrekt.<br>
  Bitte überprüfe, dass sich nichts Entscheidendes daran geändert hat. Dann versuch es erneut.
</div>
<form name="countersForm" #countersForm="ngForm" novalidate>
    <table class="default-table">
        <tr>
            <th class="default-table-header">Name</th>
            <th class="default-table-header">Start<br>Wert</th>
            <th class="default-table-header">Momentaner<br>Wert</th>
            <th class="default-table-header">Befehle</th>
            <th class="default-table-header">
                <img (click)="createCounter()" class="button-img" alt="Erstellen" style="float: right;" [src]="addImg">
            </th>
        </tr>
        
        @for (counter of counters; track $index) {
            <tr>
                @if (countersToEdit.includes(counter)) {
                    <td class="default-table-cell" style="padding-left: 10px;">
                        <input type="text" #name="ngModel" name="name" [(ngModel)]="counter.name" nameValidator [toCheckId]="counter.id" [existingNames]="existingNamesById" style="width: 95%;" required>
                        <div *ngIf="name.invalid && (name.dirty || name.touched)">
                        <div *ngIf="name.errors?.['startsExclamation']" class="note" style="padding-top: 5px;" >
                            Der Name darf nie mit einem ! beginnen.
                        </div>
                        <div *ngIf="name.errors?.['containsBlank']" class="note" style="padding-top: 5px;" >
                            Der Name darf nie ein Leerzeichen enthalten.
                        </div>
                        <div *ngIf="name.errors?.['toLong']" class="note" style="padding-top: 5px;" >
                            Der Name darf maximal 16 Zeichen lang sein.
                        </div>
                        <div *ngIf="name.errors?.['alreadyExists']" class="note" style="padding-top: 5px;" >
                            Es existiert bereits ein Counter mit dem selben Namen.
                        </div>
                        </div>
                    </td>
                } @else {
                    <td class="default-table-cell" style="padding-left: 10px;">
                        {{counter.name}}
                    </td>
                }
                <td class="default-table-cell" style="padding-left: 10px;">
                    {{counter.baseValue}}
                </td>
                <td class="default-table-cell" style="padding-left: 10px;">
                    {{counter.currentValue}}
                </td>
                <td class="default-table-cell" style="padding-left: 10px;">
                    @for (command of getCommands(counter); track $index) {
                        @if ($index > 0) {
                            <br>
                        }
                        <a routerLink="/commands/edit" [queryParams]="createQueryParams(command)" style="color: white;">{{command.name}}</a>
                    }
                </td>
                <td class="button-cell default-table-cell">
                    @if (countersToEdit.includes(counter)) {
                        <img *ngIf="!countersForm.form.valid || !counterHasChanged(counter)" [src]="acceptInactiveImg" class="button-img-centered" style="cursor: initial;" alt="Speichern Inaktiv">
                        <img *ngIf="countersForm.form.valid && counterHasChanged(counter)" [src]="acceptImg" (click)="save(counter)" class="button-img-centered" alt="Speichern">
                        <img [src]="cancelImg" (click)="cancelCreateOrChangeCounter(counter)" class="button-img-centered" alt="Abbrechen">
                    } @else {
                        @if (!isSavingOrDeletingCounter(counter)) {
                            <img [src]="editImg" (click)="editCounter(counter)" class="button-img-centered" alt="Bearbeiten">
                            <img [src]="deleteImg" (click)="delete(counter)" class="button-img-centered" alt="Löschen">
                        } @else {
                            <img [src]="loadingGif" class="button-img-centered" alt="Laden">
                        }
                    }
                </td>
            </tr>
        }
        @for (counter of countersToCreate; track $index) {
            <tr>
                <td class="default-table-cell" style="padding-left: 10px;">
                    <input type="text" #name="ngModel" name="name" [(ngModel)]="counter.name" nameValidator [toCheckId]="counter.id" [existingNames]="existingNamesById" style="width: 95%;" required>
                    <div *ngIf="name.invalid && (name.dirty || name.touched)">
                    <div *ngIf="name.errors?.['startsExclamation']" class="note" style="padding-top: 5px;" >
                        Der Name darf nie mit einem ! beginnen.
                    </div>
                    <div *ngIf="name.errors?.['containsBlank']" class="note" style="padding-top: 5px;" >
                        Der Name darf nie ein Leerzeichen enthalten.
                    </div>
                    <div *ngIf="name.errors?.['toLong']" class="note" style="padding-top: 5px;" >
                        Der Name darf maximal 16 Zeichen lang sein.
                    </div>
                    <div *ngIf="name.errors?.['alreadyExists']" class="note" style="padding-top: 5px;" >
                        Es existiert bereits ein Counter mit dem selben Namen.
                    </div>
                    </div>
                </td>
                <td class="default-table-cell" style="padding-left: 10px;">
                    <input type="text" #baseValue="ngModel" name="baseValue" isNumberValidator [notZero]="true" [(ngModel)]="counter.baseValue" required style="width: 100px;">
                    <div *ngIf="baseValue.errors?.['noNumber']" class="note">
                        Bitte gib eine Nummer ein
                    </div>
                    <div *ngIf="baseValue.errors?.['isZero']" class="note">
                        Bitte gib etwas anderes als 0 ein
                    </div>
                </td>
                <td class="default-table-cell" style="padding-left: 10px;">
                    {{counter.baseValue}}
                </td>
                <td class="default-table-cell" style="padding-left: 10px;">

                </td>
                <td class="button-cell default-table-cell">
                    <img *ngIf="!countersForm.form.valid" [src]="acceptInactiveImg" class="button-img-centered" style="cursor: initial;" alt="Speichern Inaktiv">
                    <img *ngIf="countersForm.form.valid" [src]="acceptImg" (click)="save(counter)" class="button-img-centered" alt="Speichern">
                    <img [src]="cancelImg" (click)="cancelCreateOrChangeCounter(counter)" class="button-img-centered" alt="Abbrechen">
                </td>
            </tr>
        }
    </table>
</form>