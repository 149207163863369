<img [src]="loadingImg" *ngIf="initializingCommands" class="loading-img-centered" alt="Laden">
<table *ngIf="!initializingCommands" class="default-table" style="width: 100%;">
    <tr *ngIf="!forSearch">
        <td [attr.colspan]="!(forChannel || forLinks) ? 7 : 6" class="sub-th default-table-cell">
            <div class="row">
                <div class="column">Befehle von anderen</div>
                <div class="column">
                    <img (click)="goToSearch()" class="button-img" style="float: right;" alt="Such Befehl" [src]="magnifierImg">
                </div>
            </div>
        </td>   
    </tr>
    <tr *ngIf="commands.length > 0">
        <th id="first-column" class="default-table-header">Name</th>
        <th id="second-column" class="default-table-header">Text</th>
        <th class="default-table-header" style="width: 10px; padding: 0 10px 0 10px;">Zähler</th>
        <th class="default-table-header" style="width: 10px; padding: 0 10px 0 10px;">Ränge</th>
        <th *ngIf="!(forChannel || forLinks)" class="default-table-header"style=" width: 10px; padding: 0 10px 0 10px;">Feature</th>
        <th class="default-table-header" style="width: 175px;">Von</th>
        <th class="default-table-header"></th>
    </tr>
    <tr *ngFor="let command of commands">
        <td class="default-table-cell" [ngStyle]="createCellStyle(command)">
            <div class="command-output">{{command.name}}</div>
        </td>
        <td class="default-table-cell" [ngStyle]="createCellStyle(command)" style="max-width: 100px;">
            <div class="command-output" *ngIf="!disableCommand(command)" style="word-wrap: break-word; overflow-wrap: break-word;">{{command.text}}</div>
            <div class="command-output" *ngIf="forSearch && alreadyUsed(command)">Du besitzt diesen Befehl bereits</div>
            <div class="command-output" *ngIf="findMissingFeature(command); let missingFeature">Du brauchst das Feature "{{missingFeature}}" um diesen Befehl zu benutzen</div>
        </td>
        <td class="default-table-cell" [ngStyle]="createCellStyle(command)">
            <div *ngFor="let commandCounter of command.counters" class="command-output">
                {{commandCounter.counter.name + ": " + commandCounter.counter.currentValue}}
            </div>
        </td>
        <td [ngStyle]="createCellStyle(command)" class="command-output default-table-cell" >
            <span *ngFor="let userLevel of command.userLevels">
                {{getUserLevelValue(userLevel)}}<br>
            </span>
        </td>
        <td *ngIf="!(forChannel || forLinks)" [ngStyle]="createCellStyle(command)" class="button-cell default-table-cell">
            {{getFeatureValue(command.feature)}}
        </td>
        <td [ngStyle]="createCellStyle(command)" class="default-table-cell" style="padding-left: 10px;">
            {{command.owner}}
        </td>
        <td class="button-cell default-table-cell" [ngStyle]="createCellStyle(command)">
            <img *ngIf="forSearch && !disableCommand(command)" [src]="acceptImg" (click)="addCommand(command)" class="button-img-centered" alt="Hinzufügen">
            <img *ngIf="!forSearch" [src]="removeImg"  (click)="removeCommand(command)" class="button-img-centered" alt="Entfernen">
        </td>
    </tr>
</table>
