<h1>Spotify</h1>
Zunächst: Wenn du dieses Feature benutzen willst, dann musst du hier dieselbe E-Mail-Adresse benutzen, wie bei deinem Spotify Account.
Diese benötige ich, weil ich sie bei den Benutzern für die Spotify Api eintragen muss.<br>
<b>Das ändert sich,</b> sobald dieses Projekt tatsächlich released wird. Dann werde ich auch den development mode bei Spotify deaktivieren.<br>
<br>
Um dieses Feature zu benutzen, kannst du es - sobald du dich registriert hast - unter
<a *ngIf="isLoggedIn" routerLink="/settings" class="default-link">Einstellungen</a>
<span *ngIf="!isLoggedIn">Einstellungen</span>
aktivieren.
Dann werde ich deine E-Mail-Adresse bei Spotify eintragen, was maximal 24 Stunden dauern sollte.<br>
Danach musst du dich noch, in deinem
<a *ngIf="isLoggedIn" routerLink="/profile" class="default-link">Profil</a>
<span *ngIf="!isLoggedIn">Profil</span>
, dabei Anmelden. Dabei wirst du zu einer Seite von Spotify weitergeleitet, auf welcher du dich anmelden musst,
um zu bestätigen, dass du es wirklich erlauben willst, dass ich die unten aufgeführten Sachen tun darf.<br>
<br>
<b>Dabei kann ich dein Passwort nicht auslesen!</b> Es ist, wie gesagt, eine Seite von Spotify, was ihr an der URL erkennen könnt.<br>
<br>
Sobald du dich angemeldet hast, kannst du folgende Parameter in deinen Befehlen benutzen:
<ul>
  <li>
    <span class="enumeration">$&#123;SONG&#125;</span>: Gibt den Namen von dem Song zurück, den du gerade hörst.
  </li>
  <li>
    <span class="enumeration">$&#123;SONGWL&#125;</span>: Dasselbe wie $&#123;SONG&#125;, allerdings mit dem link zu dem Song.
  </li>
  <li>
    <span class="enumeration">$&#123;ARTIST&#125;</span>: Gibt den Namen des Künstlers von dem momentan gehörten Song zurück.
  </li>
  <li>
    <span class="enumeration">$&#123;ARTISTWL&#125;</span>: Dasselbe wie $&#123;ARTIST&#125;, allerdings mit dem link zu dem Künstler.
  </li>
  <li>
    <span class="enumeration">$&#123;PLAYLIST&#125;</span>: Gibt den Namen von der momentan gehörten Playlist zurück. Dies beinhaltet Playlists, Alben und Künstler
  </li>
  <li>
    <span class="enumeration">$&#123;PLAYLISTWL&#125;</span>: Dasselbe wie $&#123;PLAYLIST&#125;, allerdings mit dem link zu der/dem Playlist/Album/Künstler.
  </li>
</ul>
<img [src]="createCommand" class="img-border" alt="Befehl erstellen Beispiel"><br>
<br>
Hier siehst du, wie der Befehlt ausgeführt wird:<br>
<img [src]="executeCommand" class="img-border" alt="Befehl ausführen Beispiel"><br>
<ul>
    <li>
        Das <span style="color: deeppink;">$&#123;SONGWL&#125;</span> wird durch den Namen von dem momentan gehörten Song, gefolgt von dem Link, ersetzt.<br>
    </li>
    <li>
      Das <span style="color: deeppink;">$&#123;ARTIST&#125;</span> wird durch den Künstler, von den momentan gehörten Song, ersetzt.
    </li>
</ul>

Ich habe derzeit keinen Zugriff auf irgendetwas anderes, als deinen momentan abgespielten Song bei Spotify.<br>
Sollte sich das ändern, werde ich dich darüber per E-Mail informieren und dich an dem Tag der Veröffentlichung automatisch beim Spotify Feature abmelden.
Wenn du dich dann neu anmeldest, dann stimmst du den neuen Funktionen zu.<br>
<br>
Weitere geplante möglichkeiten siehst du unter <a class="default-link" routerLink="/whats-next" target="_blank">Was kommt noch?</a><br>
Wenn dir irgendwas einfällt, was du hier gerne dabei hättest, dann schreibe gerne eine E-Mail an features&#64;pinkpanth3r.de.
