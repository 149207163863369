import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { FORBIDDEN, UNAUTHORIZED } from '../generic/http-status';

/**
 * An interceptor for the authentication.
 */
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  /**
   * Holds the router.
   */
  private router: Router = inject(Router);

  /**
   * Holds the authentication service.
   */
  private authService: AuthenticationService = inject(AuthenticationService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request).pipe(catchError(err=> this.handleAuthError(err)));
  }

  /**
   * Handles an error in the requests with this interceptor.<br>
   * When it is an {@link UNAUTHORIZED} or {@link FORBIDDEN} error, it logs out and navigates to the login page.
   *
   * @param err The error.
   * @returns An observable for the error.
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === UNAUTHORIZED || err.status === FORBIDDEN) {
      this.authService.logout();
      this.router.navigate(["login"]);
      return of(err.message);
    }
    return throwError(() => err);
  }
}