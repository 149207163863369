import { AbstractKey } from "../../generic/abstract-key";
import { IdentifiedEntity } from "../../generic/identified-entity";
import { IdentifiedUser } from "../../user/user";
import { IdentifiedWhatsNext } from "../whats-next";

/**
 * The key for a what's next vote.
 */
export class WhatsNextVoteKey extends AbstractKey implements IdentifiedWhatsNextVote {

}

/**
 * An edit object for a what's next vote.
 */
export class WhatsNextVoteEdit extends WhatsNextVoteKey {

    /**
     * Holds the user of the vote.
     */
    user: IdentifiedUser;

    /**
     * Holds the what's next of the vote.
     */
    whatsNext: IdentifiedWhatsNext;

    /**
     * Holds if this what's next shall be done next.
     */
    doNext: boolean;

    /**
     * The constructor.
     *
     * @param id        The id.
     * @param user      The user of this vote.
     * @param whatsNext The voted what's next.
     * @param doNext    true if this what's next shall be done next, false otherwise.
     */
    constructor(id: number, user: IdentifiedUser, whatsNext: IdentifiedWhatsNext, doNext: boolean) {
        super(id);
        this.user = user;
        this.whatsNext = whatsNext;
        this.doNext = doNext;
    }
}

/**
 * The identified entity for what's next vote.
 */
export interface IdentifiedWhatsNextVote extends IdentifiedEntity {

}
