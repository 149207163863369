@if (!loading) {
    <h1>Patches</h1>
    @for (patch of patches; track $index) {
        <div class="patch-header">
            {{patch.patchVersion}}
            <div class="note">
                Release Datum: {{getReleaseDate(patch)}}
            </div>
        </div>
        <div [innerHTML]="getPatchText(patch)"></div>
    }
} @else {
    <img [src]="loadingImg" class="loading-img-centered" alt="Lade Patches">
}