
<h1 *ngIf="isForCreate">Befehl Erstellen</h1>
<h1 *ngIf="!isForCreate">Befehl Bearbeiten</h1>
<div *ngIf="hasError" class="error-message" [innerHTML]="errorMessage">
</div>
@if (!saving) {
    <form (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
        <img *ngIf="loadingCommand" [src]="loadingCommandImg" class="loading-img-centered" alt="Lade Befehel">
        <table *ngIf="!loadingCommand">
            <tr>
                <td>
                    <label for="commandName"><b>Name:</b></label>
                </td>
                <td *ngIf="command != null">
                    <input type="text" name="commandName" #commandName="ngModel" nameValidator [maxLength]="nameMaxLength" [(ngModel)]="command.name"
                    [ngClass]="{ 'has-error': !commandName.valid }" maxlength="{{nameMaxLength}}" style="min-width: 300px;" required>
                    <div class="note">
                        {{command.name.length}}/{{nameMaxLength}}
                    </div>
                    <div *ngIf="commandName.invalid && (commandName.dirty || commandName.touched)">
                        <div *ngIf="commandName.errors?.['startsExclamation']" class="note" style="padding-top: 5px;" >
                            Der Name darf nie mit einem ! beginnen.<span *ngIf="hasChannel()"> Bei denen für deinen Kanal wird dies automathisch hinzugefügt.</span>
                        </div>
                        <div *ngIf="commandName.errors?.['containsBlank']" class="note" style="padding-top: 5px;" >
                            Der Name darf nie ein Leerzeichen enthalten.
                        </div>
                        <div *ngIf="commandName.errors?.['toLong']" class="note" style="padding-top: 5px;" >
                            Der Name darf maximal 20 Zeichen lang sein.
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <label for="commandText"><b>Text:</b></label>
                </td>
                <td *ngIf="command != null">
                    <div>
                        <textarea type="text" name="commandText" #text="ngModel" [(ngModel)]="command.text" [ngClass]="{ 'has-error': !text.valid }"
                            style="min-width: 300px; min-height: 100px;" maxlength="{{textMaxLength}}" required></textarea>
                        <div class="note">
                            {{command.text.length}}/{{textMaxLength}}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <label for="commandPublic"><b>Öffentlich:</b></label>
                </td>
                <td *ngIf="command != null">
                    <input type="checkbox" name="commandPublic" [defaultChecked]="command.public" (change)="publicChanged($event, command)">
                </td>
            </tr>
            @if (!(forLinks || forChannel)) {
                <tr>
                    <td style="vertical-align: top;">
                        <b>Feature:</b>
                    </td>
                    <td>
                        @if (isForCreate && possibleFeatures.length > 1) {
                            <select (change)="featureChanged($event)" [disabled]="!isForCreate">
                                @for (feature of possibleFeatures; track $index) {
                                    <option [selected]="isCommandFeature(feature)" [ngValue]="feature">
                                        {{feature}}
                                    </option>
                                }
                            </select>
                        } @else {
                            {{getFeatureValue(command.feature)}}
                        }
                    </td>
                </tr>
            }
            <tr>
                <td style="vertical-align: top;">
                    <label for="userLevels"><b>Ränge:</b></label>
                </td>
                <td>
                    <tu-user-level-edit [command]="command" (saveButtonDisabled)="setUserLevelDisabledSaveButton($event)"></tu-user-level-edit>
                </td>
            </tr>
            <tr>
                <td style="vertical-align: top;">
                    <label><b>Counters:</b></label>
                </td>
                <td *ngIf="command != null">
                    <tu-counter-edit [command]="command" (saveButtonDisabled)="setCounterDisabledSaveButton($event)" (counterChanged)="setCounterChanged($event)"></tu-counter-edit>
                </td>
            </tr>
            <tr>
                <td>
                    <button (click)="save()" [disabled]="counterDisabledSaveButton || userLevelDisabledSaveButton || !f.form.valid || !commandHasChanged()" style="margin-top: 15px;">Speichern</button>
                </td>
                <td>
                    <button *ngIf="routeURL != null" (click)="back()" style="margin-top: 15px;">{{commandHasChanged() ? "Abbrechen" : "Zurück"}}</button>
                </td>
            </tr>
        </table>
    </form>
} @else {
    <div>
        <img [src]="savingImg" class="loading-img-centered" alt="Speichere Befehl">
    </div>
}