
/**
 * The features existing in the twitch utils.
 */
export enum Feature {
    
    /**
     * The spotify feature.
     */
    SPOTIFY = "Spotify",
    
    /**
     * The twitch links feature.
     */
    TWITCH_LINKS = "Links",
    
    /**
     * The twitch bot feature.
     */
    TWITCH_BOT = "Bot",
}