import { Component } from '@angular/core';
import { CommandsComponent } from '../command/commands.component';

@Component({
  selector: 'tu-links',
  standalone: true,
  imports: [CommandsComponent],
  templateUrl: './links.component.html',
})
export class LinksComponent {

}
