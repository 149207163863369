import { AbstractKey } from "../generic/abstract-key";
import { IdentifiedEntity } from "../generic/identified-entity";
import { Versionable } from "../generic/versionable";
import { IdentifiedUser } from "../user/user";
import { UserLevel } from "./userLevel/user-level";
import { Feature } from "../feature/feature";
import { CommandCounterDisplay, CommandCounterEdit } from "./counter/command-counter";

/**
 * The key for a command.
 */
export class CommandKey extends AbstractKey implements IdentifiedCommand {

  /**
   * The constructor.
   *
   * @param id The id.
   */
    constructor(id: number) {
        super(id);
    }
}

/**
 * The versionable key for a command.
 */
export class CommandVersionableKey extends CommandKey implements IdentifiedVersionableCommand {

  /**
   * Holds the version.
   */
  readonly version: number;

  /**
   * The constructor.
   *
   * @param id      The id.
   * @param version The version.
   */
  constructor(id: number, version: number) {
    super(id);

    this.version = version;
  }

}

/**
 * An abstract class for a command.
 */
export class AbstractCommand extends CommandKey {

  /**
   * Holds the name.
   */
  name: string;

  /**
   * Holds the text.
   */
  text: string;

  /**
   * Holds the feature.
   */
  feature: Feature;

  /**
   * Holds the user levels.
   */
  userLevels: UserLevel[];

  /**
   * The constructor.
   *
   * @param id         The id.
   * @param name       The name.
   * @param text       The text.
   * @param feature    The feature.
   * @param userLevels The user levels.
   */
  constructor(id: number, name: string, text: string, feature: Feature, userLevels: UserLevel[]) {
    super(id)

    this.name = name;
    this.text = text;
    this.feature = feature;
    this.userLevels = userLevels;
  }
}

/**
 * An edit object for a command.
 */
export class CommandEdit extends AbstractCommand {

    /**
     * Holds the version.
     */
    version: number;

    /**
     * Holds the counters.
     */
    counters: CommandCounterEdit[];

    /**
     * Holds the owner of this command.
     */
    owner: IdentifiedUser;


    /**
     * Holds if the command is public.
     */
    public: boolean;

    /**
     * The constructor.
     *
     * @param id          The id.
     * @param version     The version.
     * @param name        The name.
     * @param text        The text.
     * @param counters    The counters.
     * @param owner       The owner of this command.
     * @param feature     The feature.
     * @param userLevels  The user levels.
     * @param isPublic    true if the command is public, false otherwise.
     */
    constructor(id: number, version: number, name: string, text: string, counters: CommandCounterEdit[], owner: IdentifiedUser, feature: Feature, userLevels: UserLevel[], isPublic: boolean) {
        super(id, name, text, feature, userLevels);

        this.version = version;
        this.counters = counters;
        this.owner = owner;
        this.public = isPublic;
    }

    /**
     * Returns a copy of the provided command.
     *
     * @param command The command to copy.
     * @returns A copy of the provided commands.
     */
    public static copy(command: CommandEdit): CommandEdit {
      const counters: CommandCounterEdit[] = [];
      command.counters.forEach(cnt => counters.push(cnt));
      const userLevels:UserLevel[] = [];
      command.userLevels.forEach(ul => userLevels.push(ul));

      return new CommandEdit(command.id, command.version, command.name, command.text, counters, command.owner, command.feature, userLevels, command.public);
    }
}


/**
 * A command that is not owned by the user.
 */
export class NotOwnedCommandDisplay extends AbstractCommand implements IdentifiedNotOwnedCommand {

  /**
   * Holds the counters.
   */
  readonly counters: CommandCounterDisplay[];

  /**
   * Holds the name of the owner of this command.
   */
  readonly owner: string;

  /**
   * The constructor.
   *
   * @param id         The id.
   * @param name       The name.
   * @param text       The text.
   * @param counters   The counters.
   * @param owner      The owner.
   * @param feature    The feature.
   * @param userLevels The user levels.
   */
  constructor(id: number, name: string, text: string, counters: CommandCounterDisplay[], owner: string, feature: Feature, userLevels: UserLevel[]) {
    super(id, name, text, feature, userLevels)

    this.counters = counters;
    this.owner = owner;
  }

  /**
   * Returns a copy of the provided not owned command.
   *
   * @param command The not owned command to copy.
   * @returns A copy of the provided not owned command.
   */
  public static copy(command: NotOwnedCommandDisplay): NotOwnedCommandDisplay {
    const userLevels: UserLevel[] = [];
    command.userLevels.forEach(ul => userLevels.push(ul));
    return new NotOwnedCommandDisplay(command.id, command.name, command.text, command.counters, command.owner, command.feature, userLevels);
  }
}


/**
 * The identified entity for command.
 */
export interface IdentifiedCommand extends IdentifiedEntity {

}

/**
 * The identified entity for a not owned command.
 */
export interface IdentifiedNotOwnedCommand extends IdentifiedCommand {

}

/**
 * The versionable identified entity for command.
 */
export interface IdentifiedVersionableCommand extends IdentifiedCommand, Versionable {

}
