/**
 * Holds the path to the add image.
 */
export const BASE_IMG_PATH: string = "assets/img/";

/**
 * Holds the path to the add image.
 */
export const ADD_IMG: string = BASE_IMG_PATH + "add.svg";

/**
 * Holds the path to the add image.
 */
export const ADD_OUTER_IMG: string = BASE_IMG_PATH + "add-outer.svg";

/**
 * Holds the path to the accept image.
 */
export const ACCEPT_IMG: string = BASE_IMG_PATH + "accept.svg";

/**
 * Holds the path to the inactive accept image.
 */
export const ACCEPT_INACTIVE_IMG: string = BASE_IMG_PATH + "accept-inactive.svg";

/**
 * Holds the path to the edit image.
 */
export const EDIT_IMG: string = BASE_IMG_PATH + "edit.svg";

/**
 * Holds the path to the cancel image.
 */
export const CANCEL_IMG: string = BASE_IMG_PATH + "cancel.svg";

/**
 * Holds the path to the remove image.
 */
export const REMOVE_IMG: string = BASE_IMG_PATH + "remove.svg";

/**
 * Holds the path to the delete image.
 */
export const DELETE_IMG: string = BASE_IMG_PATH + "delete.svg";

/**
 * Holds the path to the magnifier image.
 */
export const MAGNIFIER_IMG: string = BASE_IMG_PATH + "magnifier.svg";

/**
 * Holds the path to the loading gif.
 */
export const LOADING_GIF: string = BASE_IMG_PATH + "loading.gif";

/**
 * Holds the path to the thumbs up img.
 */
export const THUMBS_UP: string = BASE_IMG_PATH + "thumbs-up.svg";

/**
 * Holds the path to the thumbs up filled img.
 */
export const THUMBS_UP_FILLED: string = BASE_IMG_PATH + "thumbs-up-filled.svg";

/**
 * Holds the path to the thumbs down img.
 */
export const THUMBS_DOWN: string = BASE_IMG_PATH + "thumbs-down.svg";

/**
 * Holds the path to the thumbs down filled img.
 */
export const THUMBS_DOWN_FILLED: string = BASE_IMG_PATH + "thumbs-down-filled.svg";