import { Injectable } from '@angular/core';
import { UserEdit, UserKey } from './user';
import { AuthenticationService } from '../authentication/authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { USER_URL } from '../generic/backend-urls';
import { firstValueFrom, Observer } from 'rxjs';
import { UserEditResponse } from './user-response';
import { NotOwnedCommandDisplay } from '../command/command';
import { InternalErrorException } from '../generic/errors';
import { NOT_ACCEPTABLE } from '../generic/http-status';

/**
 * A service for the users.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   */
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  /**
   * Saved the provided user.
   *
   * @param user            The user to save.
   * @param setWrongVersion A setter to use if the user had a wrong version.
   */
  save(user: UserEdit, setWrongVersion: (wrongVersion: boolean) => void): void {
    this.http.post(USER_URL + "/save", user)
      .subscribe(new SaveHandler(this.authenticationService, setWrongVersion));
  }

  /**
   * Adds the provided command to the user.
   *
   * @param command The command.
   */
  addCommand(command: NotOwnedCommandDisplay): void {
    const errorMessage = "An error occurred while trying to add the command " +
                         command.id +
                         " to the user " +
                         this.authenticationService.getUser().id;
    firstValueFrom<UserEdit>(this.http.post<UserEdit>(USER_URL + '/addCommand', command))
      .then(user => {
        this.authenticationService.refreshUser(user);
      })
      .catch((err: HttpErrorResponse) => {
        let status = Number(err.status);
        if (status == NOT_ACCEPTABLE) {
          this.authenticationService.reloadUser();
          return;
        }
        throw new InternalErrorException(errorMessage, err)
      });
  }

  /**
   * Returns a promise if the provided username already exists.
   *
   * @param username The username.
   * @returns A promise if the provided username already exists.
   */
  async checkNameExists(username: string): Promise<boolean> {
    return firstValueFrom<boolean>(this.http.post<boolean>(USER_URL + '/usernameExist',
                                   {user: new UserKey(this.authenticationService.getUser().id), username: username}));
  }

}

/**
 * The observer handling the response from saving the user.
 */
class SaveHandler implements Observer<UserEditResponse> {

  /**
   * The constructor.
   *
   * @param authenticationService The authentication service.
   * @param setWrongVersion       A setter to use if the user had a wrong version.
   */
  constructor(private authenticationService: AuthenticationService, private setWrongVersion: (wrongVersion: boolean) => void) {

  }

  next(userEditResponse: UserEditResponse) {
    this.authenticationService.refreshUser(userEditResponse.user, userEditResponse.token);
    this.setWrongVersion.call(this, false);
  }

  error(err: HttpErrorResponse) {
    let status = Number(err.status);
    if (status == NOT_ACCEPTABLE) {
      this.authenticationService.reloadUser();
      this.setWrongVersion.call(this, true);
      return;
    }
    throw new InternalErrorException("An error occurred while trying to save a user", err)
  }

  complete() {}

}
