import { booleanAttribute, Component, Input, OnInit } from '@angular/core';
import { UserEdit } from '../user/user';
import { AuthenticationService } from '../authentication/authentication.service';
import { Language } from '../generic/language';
import { Feature } from '../feature/feature';
import { AuthenticationException } from '../generic/errors';
import { ProfileService } from './profile.service';
import { LOADING_GIF } from '../generic/img-pathes';

/**
 * The component for the profile.
 */
@Component({
  selector: 'tu-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css',
  standalone: false
})
export class ProfileComponent implements OnInit {

  @Input({alias: "channelCreated", transform: booleanAttribute})
  channelCreated?: boolean;

  /**
   * Holds the gif to display when we are loading a feature.
   */
  loadingFeatureImg: string = LOADING_GIF;

  /**
   * Holds the default token. This will be displayed, before the user decides to show it.
   */
  private defaultToken: string = "**************";

  /**
   * Holds the user for the profile.
   */
  user: UserEdit;

  /**
   * Holds the token.
   */
  token: string = this.defaultToken;

  /**
   * Holds if the token is displayied.
   */
  isTokenDisplayed: boolean = false;

  /**
   * Holds a map containing the info for the features if they are logged-in.
   */
  featureLoggedIn: Map<Feature, boolean> = new Map<Feature, boolean>();

  /**
   * A message that might contain a failure message.
   */
  failureMessage: string = '';

  /**
   * Holds if the features are initializing.
   */
  initializingFeatures: boolean = true;

  /**
   * Holds if the features are currently loading.
   */
  loadingFeatures: Feature[] = [];

  /**
   * The constructor.
   *
   * @param authenticationService The authentication service.
   * @param profileService        The profile service.
   */
  constructor(private authenticationService: AuthenticationService, private profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    if (this.user == null) {
      throw new AuthenticationException("No user is logged in while someone was able to go to the profile page");
    }
    this.initFeatureLoggedIn();
    if (this.channelCreated) {
      this.failureMessage = "Der Kanal wurde für dicher erstellt."
    }
  }

  /**
   * Initializes the {@link featureLoggedIn}.
   */
  private initFeatureLoggedIn(): void {
    this.initializingFeatures = true;
    this.profileService.findLoggedInFeatures()
      .then(loggedIn => {
        let notLoggedIn = this.user.features;
        for (let feature of loggedIn) {
          this.featureLoggedIn.set(feature, true);
          notLoggedIn = notLoggedIn.filter(f => f != feature);
        }
        for (let feature of notLoggedIn) {
          this.featureLoggedIn.set(feature, false);
        }
        this.loadingFeatures = [];
        this.initializingFeatures = false;
      });
  }
  /**
   * Returns the value of the provided feature.
   *
   * @returns The value of the provided feature.
   */
  getFeatureValue(feature: Feature): string {
      return (Feature[feature] as string).toUpperCase();;
  }

  /**
   * Returns if the provided feature is loading, false otherwise, false otherwise.
   * 
   * @param feature The feature to check.
   * @returns true if the provided feature is loading, false otherwise.
   */
  isFeatureLoading(feature: Feature): boolean {
    return this.loadingFeatures.some(f => f == feature);
  }

  /**
   * Returns if the user is logged-in for the provided feature.
   *
   * @param feature The feature.
   * @returns true if the user is logged-in for the provided feature, false otherwise.
   */
  isLoggedIn(feature: Feature): boolean {
    let result = this.featureLoggedIn.get(feature);
    return result == undefined ? false : result;
  }

  /**
   * Logs the use in/out of the provided feature.
   *
   * @param feature The feature.
   */
  logInOut(feature: Feature) {
    this.loadingFeatures.push(feature);
    if (this.featureLoggedIn.get(feature)) {
      this.profileService.logout(feature)
        .then(r => {
          this.featureLoggedIn.set(feature, false);
          this.loadingFeatures = this.loadingFeatures.filter(f => f != feature);
        })
    } else {
      this.profileService.login(feature);
    }
  }

  /**
   * Returns the language.
   *
   * @returns The language.
   */
  getLanguage(): string {
    return Language[this.user.language];
  }

  /**
   * Changes the {@link token} to the real token of the {@link user}.
   */
  displayToken(): void {
    this.token = this.user.token;
    this.isTokenDisplayed = true;
  }

  /**
   * Changes the {@link token} to the {@link defaultToken} token.
   */
  hideToken(): void {
    this.token = this.defaultToken;
    this.isTokenDisplayed = false;
  }

  /**
   * Returns the name of the channel if the user has one.
   *
   * @returns The name of the channel if the user has one.
   */
  getChannelName(): string {
    if (this.hasChannel()) {
      return this.user.channel.name;
    } else {
      return null;
    }
  }

  /**
   * Returns if the user has a channel.
   *
   * @returns true if the user has a a channel, false otherwise.
   */
  hasChannel(): boolean {
    return this.user.channel != null;
  }

  /**
   * Returns if the links feature is active.
   *
   * @returns true if the links feature is active, false otherwise.
   */
  hasLinksFeature(): boolean {
    return this.authenticationService.hasFeature(Feature.TWITCH_LINKS);
  }

}
