import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DESC_IMG_PATH } from '../desc-basics';
import { AuthenticationService } from '../../authentication/authentication.service';
import { NgIf } from '@angular/common';

/**
 * The component for the description of the spotify feature.
 */
@Component({
    selector: 'tu-spotify-desc',
    standalone: true,
    imports: [RouterLink, NgIf],
    templateUrl: './spotify-desc.component.html'
})
export class SpotifyDescComponent {

  /**
   * Holds the path to the spotify create command image.
   */
  createCommand: string = DESC_IMG_PATH + "/spotify-create-command.jpg";

  /**
   * Holds the path to the spotify song command image.
   */
  executeCommand: string = DESC_IMG_PATH + "/spotify-song.jpg";

  isLoggedIn: boolean = false;
  
  /**
   * The constructor.
   *
   * @param authenticationService The authentication service.
   */
  constructor(private authenticationService: AuthenticationService) {

  }

  ngOnInit(): void {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
  }
}
