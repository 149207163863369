import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { AUTHENTICATION_URL } from '../generic/backend-urls';
import { LoginResponse } from './login-response';
import { LoginData } from './login-data';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '@angular/router';
import { I_AM_A_TEAPOT, NOT_ACCEPTABLE } from '../generic/http-status';

/**
 * A service for the login.
 */
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   * @param router                The router.
   */
  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private router: Router) { }

  /**
   * Logs in the user with the login data.
   *
   * @param loginData The login data.
   */
  login(loginData: LoginData): void {
    this.authenticationService.logout();
    let username = loginData.username;
    let password = loginData.password;
    firstValueFrom(this.http.post<LoginResponse>(AUTHENTICATION_URL + "/login", { username, password }))
      .then(this.handleLogin.bind(this))
      .catch(err => {
        this.handleLoginError(err, loginData)
      });
  }

  /**
   * Handles the login response.
   *
   * @param loginResponse The login response.
   */
  private handleLogin(loginResponse: LoginResponse): void {
    this.authenticationService.setLoggedIn(loginResponse);
    this.router.navigate(['profile']);
  }

  /**
   * Handles an error that occurred while login.
   *
   * @param err       The error.
   * @param loginData The login data when trying to login.
   */
  private handleLoginError(err: { status: number; }, loginData: LoginData): void {
    let status = err.status;
    if (status == NOT_ACCEPTABLE) {
        loginData.message = WRONG_DATA_MESSAGE;
    } else if(status == I_AM_A_TEAPOT) {
        loginData.message = ALREADY_LOGGED_IN_MESSAGE;
    } else {
        console.error("Error with status " + status + "  occurred on login", err);
        loginData.message = FAILURE_MESSAGE;
    }
  }

}

/* The messages for login failures */

/**
 * Holds the message when the login data is wrong.
 */
const WRONG_DATA_MESSAGE: string = "Falsche login daten.";

/**
 * Holds the message when the user is already logged in.
 */
const ALREADY_LOGGED_IN_MESSAGE: string = "Du hast dich bereits angemeldet.";

/**
 * Holds the message when a failzre occured that we don't handle yet.
 */
const FAILURE_MESSAGE: string = "Ein fehler ist beim Login aufgetreten. Bitte versuch es erneut.<br>\
Wenn es wieder schief geht, dann schau bitte in deinen E-Mails nach, ob ich dir etwas zu dem Problem geschrieben habe.<br>\
Ansonsten kannst du dich bei support@pinkpanth3r.de melde.";
