import { CounterEdit } from "../../counter/counter";
import { MathSign } from "./math-sign";
import { AbstractKey } from "../../generic/abstract-key";
import { IdentifiedEntity } from "../../generic/identified-entity";
import { Versionable } from "../../generic/versionable";
import { IdentifiedCommand } from "../command";

/**
 * The key for a command counter.
 */
export class CommandCounterKey extends AbstractKey implements IdentifiedCommandCounter {

  /**
   * The constructor.
   *
   * @param id The id.
   */
    constructor(id: number) {
        super(id);
    }
}

/**
 * The versionable key for a command counter.
 */
export class CommandCounterVersionableKey extends CommandCounterKey implements IdentifiedVersionableCommandCounter {

  /**
   * Holds the version.
   */
  readonly version: number;

  /**
   * The constructor.
   *
   * @param id The id.
   * @param version The version.
   */
  constructor(id: number, version: number) {
    super(id);

    this.version = version;
  }

}

/**
 * An edit object for a command counter.
 */
export class CommandCounterEdit extends CommandCounterVersionableKey {

    /**
     * Holds the command.
     */
    readonly command: IdentifiedCommand;

    /**
     * Holds the counter.
     */
    counter: CounterEdit;

    /**
     * Holds the value to add to the counter.
     */
    toAdd: number;

    /**
     * Holds the math sign for the counter.
     */
    mathSign: MathSign;

    /**
     * The constructor.
     *
     * @param id       The id.
     * @param version  The version.
     * @param toAdd    The value to add to the counter.
     * @param mathSign The math sign for the counter.
     * @param command  The command.
     * @param counter  The counter.
     */
    constructor(id: number, version: number, toAdd: number, mathSign: MathSign, command: IdentifiedCommand, counter: CounterEdit) {
        super(id, version);

        this.toAdd = toAdd;
        this.mathSign = mathSign;
        this.command = command;
        this.counter = counter;
    }

    /**
     * Returns a copy of the provided command counter.
     *
     * @param commandCounter The command counter to copy.
     * @returns A copy of the provided command counter.
     */
    public static copy(commandCounter: CommandCounterEdit): CommandCounterEdit {
        const counter = CounterEdit.copy(commandCounter.counter);
        return new CommandCounterEdit(commandCounter.id,
                             commandCounter.version,
                             commandCounter.toAdd,
                             commandCounter.mathSign,
                             commandCounter.command,
                             counter);
    }

}

/**
 * A display object for a command counter.
 */
export class CommandCounterDisplay extends CommandCounterVersionableKey {

    /**
     * Holds the command.
     */
    readonly command: IdentifiedCommand;

    /**
     * Holds the counter.
     */
    readonly counter: CounterEdit;

    /**
     * Holds the value to add to the counter.
     */
    readonly toAdd: number;

    /**
     * Holds the math sign for the counter.
     */
    readonly mathSign: MathSign;

    /**
     * The constructor.
     *
     * @param id       The id.
     * @param version  The version.
     * @param toAdd    The value to add to the counter.
     * @param mathSign The math sign for the counter.
     * @param command  The command.
     * @param counter  The counter.
     */
    constructor(id: number, version: number, toAdd: number, mathSign: MathSign, command: IdentifiedCommand, counter: CounterEdit) {
        super(id, version);

        this.toAdd = toAdd;
        this.mathSign = mathSign;
        this.command = command;
        this.counter = counter;
    }
}


/**
 * The identified entity for command counters.
 */
export interface IdentifiedCommandCounter extends IdentifiedEntity {

}

/**
 * The versionable identified entity for command counters.
 */
export interface IdentifiedVersionableCommandCounter extends IdentifiedCommandCounter, Versionable {

}
