import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {filter} from 'rxjs/operators'

/**
 * A service for handling the previous route.
 */
@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {

  /**
   * Holds the router.
   */
  private router = inject(Router);

  /**
   * The previous route url.
   */
  previousUrl: string = '';

  /**
   * The current route url.
   */
  currentUrl: string = '';

  /**
   * The constructor.
   */
  constructor() {
    this.currentUrl = this.router.url;
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    })
  }

}
