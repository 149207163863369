<h1>Befehle</h1>
Das ist quasi das Herzstück von den Twitch Utils.
Hier kannst du Befehle erstellen, welche du über den Twitch Bot oder die Links benutzen kannst.<br>
<br>
<img [src]="createCommand" class="img-border" alt="Befehl erstellen Beispiel"><br>
<br>
<span class="enumeration">1)</span> Der Name von dem Befehl.<br>
<span class="enumeration">2)</span> Der Text welcher zurückgegeben werden soll. Dabei ist es möglich eigene Parameter (&#123;1&#125;) oder counter ($&#123;moins&#125;) hinzuzufügen.
Wenn du mehr Parameter benutzen willst, dann kannst du diese einfach mit &#123;2&#125;, &#123;3&#125; etc. hinzufügen<br>
<span class="enumeration">3)</span> Hier kannst du den Befehl veröffentlichen. Das heißt, dass jeder andere ihn auch benutzen kann.<br>
<span class="enumeration">4)</span> Auswählen von dem Feature, für welches der Befehl benutzt werden soll.
Dabei stehen zur Auswahl: <a class="default-link" routerLink="/desc/links">Links</a> oder der <a class="default-link" routerLink="/desc/bot">Bot</a><br>
<span class="enumeration">5)</span> Die Ränge, welche diesen Befehl ausführen dürfen. Darunter gibt es: Ich, Moderator, VIP, Subscriber, Gast, Jeder<br>
<span class="enumeration">6)</span> Du kannst Counter zu Deinem Befehl hinzuzufügen. Dabei kannst du den Start Wert, das Zeichen (+, -, *, /) und den Wert, mit dem es angewendet werden soll, auswählen.<br>
<span class="enumeration">7)</span> Du kannst auch bereits bestehende Counter hier hinzufügen.<br>
<br>
Weitere geplante möglichkeiten siehst du unter <a class="default-link" routerLink="/whats-next">Was kommt noch?</a><br>
Wenn dir irgendwas einfällt, was du hier gerne dabei hättest, dann schreibe gerne eine E-Mail an features&#64;pinkpanth3r.de.
