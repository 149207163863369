
/**
 * An abstract class that can be used by standalone components.
 */
export abstract class AbstractStandaloneComponent {

    /**
     * Returns the value of the provided event for an input.
     *
     * @param $event The event.
     * @returns The value of the provided event.
     */
    getCurrentValueForInput($event: Event): string {
        return ($event.target as HTMLInputElement).value;
    }

    /**
     * Returns the value of the provided event for an input as a number.
     *
     * @param $event The event.
     * @returns The value of the provided event as a number.
     */
    getCurrentNumberValueForInput($event: Event): number {
        const value = ($event.target as HTMLInputElement).value;
        if (isNaN(+value)) {
            return 0;
        }
        return +value;
    }

    /**
     * Returns the value of the provided event for a select.
     *
     * @param $event The event.
     * @returns The value of the provided event.
     */
    getCurrentValueForSelect<E>($event: Event): E {
        const options: HTMLCollectionOf<HTMLOptionElement> = ($event.target as HTMLSelectElement).selectedOptions;
        return options.item(0).value as E;
    }

}
