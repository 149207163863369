import { Injectable } from '@angular/core';
import { CommandEdit, NotOwnedCommandDisplay } from '../command';
import { CommandsService } from '../commands.service';
import { CommandEditService } from '../edit/command-edit.service';

/**
 * A service for the command searches.
 */
@Injectable({
  providedIn: 'root'
})
export class CommandSearchService {

  /**
   * The constructor.
   *
   * @param commandsService       The commands service.
   * @param commandEditService    The command edit service.
   */
  constructor(private commandsService: CommandsService, private commandEditService: CommandEditService) { }

  /**
   * Adds the provided command to the user.
   *
   * @param command The command.
   */
  addCommand(command: NotOwnedCommandDisplay): void {
    this.commandsService.addToUser(command);
  }

  /**
   * Returns a failure message if one occurred.
   *
   * @param toValidate The command to validate.
   * @returns A failure message if one occurred.
   */
  isCommandValid(toValidate: NotOwnedCommandDisplay | CommandEdit): string {
    return this.commandEditService.isCommandValid(toValidate);
  }

}
