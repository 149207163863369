import { AbstractKey } from "../generic/abstract-key";
import { IdentifiedEntity } from "../generic/identified-entity";
import { Versionable } from "../generic/versionable";
import { IdentifiedUser } from "../user/user";
import { ChatFilterEdit } from "./filter/chat-filter";


/**
 * The key for a channel.
 */
export class ChannelKey extends AbstractKey implements IdentifiedChannel {

  /**
   * The constructor.
   *
   * @param id The id.
   */
    constructor(id: number) {
        super(id);
    }
}

/**
 * The versionable key for a channel.
 */
export class ChannelVersionableKey extends ChannelKey implements IdentifiedVersionableChannel {

    /**
     * Holds the version.
     */
    readonly version: number;

    /**
     * The constructor.
     *
     * @param id      The id.
     * @param version The version.
     */
    constructor(id: number, version: number) {
      super(id);

      this.version = version;
    }

  }

/**
 * An edit object for a channel.
 */
export class ChannelEdit extends ChannelVersionableKey {

    /**
     * Holds the name.
     */
    name: string;

    /**
     * Holds the filters.
     */
    filters: ChatFilterEdit[];

    /**
     * Holds the owner of the channel.
     */
    owner: IdentifiedUser;

    /**
     * The constructor.
     *
     * @param id               The id.
     * @param version          The version.
     * @param name             The name.
     * @param filters          The filters.
     * @param owner            The owner of this channel.
     */
    constructor(id: number,
                version: number,
                name: string,
                filters: ChatFilterEdit[],
                owner: IdentifiedUser) {
      super(id, version)

      this.name = name;
      this.filters = filters;
      this.owner = owner;
  }
}

/**
 * The versionable identified entity for channels.
 */
export interface IdentifiedVersionableChannel extends IdentifiedChannel, Versionable {

}


/**
 * The identified entity for channels.
 */
export interface IdentifiedChannel extends IdentifiedEntity {

}
