import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../authentication/authentication.service';
import { COMMAND_URL } from '../../generic/backend-urls';
import { Router } from '@angular/router';
import { CommandEdit, IdentifiedCommand, NotOwnedCommandDisplay } from '../command';
import { firstValueFrom } from 'rxjs';
import { UserEdit } from '../../user/user';
import { CommandCounterEdit } from '../counter/command-counter';
import { NAME_MAX_LENGTH, TEXT_MAX_LENGTH } from '../command-basics';

/**
 * A service for the editting of commands. This includes the creation.
 */
@Injectable({
  providedIn: 'root'
})
export class CommandEditService {

  /**
   * The constructor.
   *
   * @param http                  The http client.
   * @param authenticationService The authentication service.
   * @param router                The router.
   */
  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private router: Router) { }

  /**
   * Saves the provided command.
   *
   * @param command The command.
   */
  save(command: CommandEdit): Promise<UserEdit> {
    return firstValueFrom(this.http.post<UserEdit>(COMMAND_URL + "/save", command));
  }

  /**
   * Returns a promise for the reloaded version of the provided command.
   *
   * @param command The command to reload.
   * @returns The promise for the reloaded command.
   */
  async reloadCommand(command: IdentifiedCommand): Promise<CommandEdit> {
    const reloaded = await firstValueFrom<CommandEdit>(this.http.post<CommandEdit>(COMMAND_URL + '/find', command));
    if (this.commandHasChanged(reloaded)) {
      this.authenticationService.reloadUser();
    }
    return reloaded;
  }

  /**
   * Checks if the provided command has been edited.
   *
   * @param command The command.
   * @returns true if the command has been changed, false otherwise.
   */
  private commandHasChanged(command: CommandEdit): boolean {
    const commands: CommandEdit[] = this.authenticationService.getUser().ownedCommands;
    for (let currentCommand of commands) {
      if (currentCommand.id == command.id) {
        if (currentCommand.version != command.version) {
          return true;
        }
        return this.counterHasChanged(currentCommand.counters, command.counters)
      }
    }
    return false;
  }

  /**
   * Returns a failure message if one occurred.
   *
   * @param toValidate The command to validate.
   * @returns A failure message if one occurred.
   */
  isCommandValid(toValidate: CommandEdit | NotOwnedCommandDisplay): string {
    const nameToLong: boolean = toValidate.name.length > NAME_MAX_LENGTH;
    const textToLong: boolean = toValidate.text.length > TEXT_MAX_LENGTH;
    if (nameToLong && textToLong) {
      return "Der von dir angegebene Name und der Text sind leider zu lang. Bitte beschränke sie auf " + NAME_MAX_LENGTH + " und " + TEXT_MAX_LENGTH + " Zeichen.";
    }
    if (nameToLong) {
      return "Der von dir angegebene Name ist leider zu lang. Bitte beschränke ihn auf " + NAME_MAX_LENGTH + " Zeichen.";
    }
    if (textToLong) {
      return "Der von dir angegebene Text ist leider zu lang. Bitte beschränke ihn auf " + TEXT_MAX_LENGTH + " Zeichen.";
    }

    const user: UserEdit = this.authenticationService.getUser();
    for(let command of user.ownedCommands) {
      if (command.id != toValidate.id && command.name == toValidate.name && command.feature == toValidate.feature) {
        return "Du besitzt bereits einen Befehl mit diesem Namen.";
      }
    }
    for (let command of user.notOwnedCommands) {
      if (command.feature == toValidate.feature && command.name == toValidate.name) {
        return 'Du besitzt bereits einen Befehl von ' +
                command.owner +
                ' mit diesem Namen<br>' +
                '<span class="default-message">In vermutlich noch ferner Zukunft wird es möglich sein, ' +
                'einen eigenen Namen für den Befehl von jemand anders zu benutzen.</span>';
      }
    }
    return null;
  }

  /**
   * Checks if the provided command counters have been edited.
   *
   * @param currentCommandCounters The current command counters.
   * @param newCommandCounters     The new command counters.
   * @returns true if the command counters have been changed, false otherwise.
   */
  private counterHasChanged(currentCommandCounters: CommandCounterEdit[], newCommandCounters: CommandCounterEdit[]) {
    if (currentCommandCounters.length != newCommandCounters.length) {
      return true;
    }

    let missingCommandCounters: CommandCounterEdit[] = JSON.parse(JSON.stringify(newCommandCounters));
    for (let currentCommandCounter of currentCommandCounters) {
      for (let missingCounter of missingCommandCounters) {
        if (currentCommandCounter.id == missingCounter.id && currentCommandCounter.version != missingCounter.version) {
          return true;
        }
      }
      missingCommandCounters = missingCommandCounters.filter((counter) => counter.id != currentCommandCounter.id);
    }

    return false;
  }

  /**
   * Redirects the user to the provided redirect URL.
   *
   * @param redirectURL The URL to redirect to.
   * @param routeUrl    The URL to provided as routeUrl.
   * @param forCreate   true if it should be redirected for creating, false otherwise.
   */
  redirectTo(redirectURL: string, routeUrl: string, forCreate: boolean) {
    let redirectTo: string;
    if (redirectURL == null) {
      redirectTo = 'commands';
    } else {
      redirectTo = redirectURL
      routeUrl = redirectURL;
    }
    let withRouteUrlParam: boolean = routeUrl!= null;
    if (withRouteUrlParam && forCreate) {
      this.router.navigate([redirectTo], { queryParams: { routeURL: routeUrl, forCreate: forCreate}});
    } else if (withRouteUrlParam) {
      this.router.navigate([redirectTo], { queryParams: { routeURL: routeUrl}});
    } else if (forCreate) {
      this.router.navigate([redirectTo], { queryParams: { forCreate: forCreate}});
    } else {
      this.router.navigate([redirectTo]);
    }
  }
}
