import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

/**
 * A validator for a password.
 */
@Directive({
  selector: '[tuPasswordValidator]',
    providers: [
      {
        provide: NG_VALIDATORS,
        useExisting: PasswordValidator,
        multi: true,
      },
    ],
})
export class PasswordValidator implements Validator {

  /**
   * The input for the second password entry.
   */
  @Input({alias: "otherInput", required: true})
  otherInput: AbstractControl;

  /**
   * Holds the minlength for a password.
   */
  @Input("minlength")
  private minlength: number = 8;

  /**
   * Holds if an update was send to the {@link otherInput other input}.
   */
  private updateSend: boolean = false;

  validate(control: AbstractControl): ValidationErrors | null {
    const ownValue: string = control.value;
    const otherValue = this.otherInput.value;

    if (!ownValue || ownValue.trim() == "" || otherValue.trim() == "") {
      return {
        notBothFilled: true
      }
    }
    const notEqual: boolean = ownValue != otherValue;
    const wrongMinlength: boolean = ownValue.length < this.minlength;
    if (notEqual || wrongMinlength) {
      return {
        notEqual: notEqual,
        wrongMinlength: wrongMinlength
      }
    }
    if (!this.updateSend) {
      this.updateSend = true;
      this.otherInput.updateValueAndValidity();
    } else {
      this.updateSend = false;
    }
    return null;
  }
}
