import { Component } from '@angular/core';
import { DESC_IMG_PATH } from '../desc-basics';
import { RouterLink } from '@angular/router';
import { BOT_NAME } from '../../app.basics';

/**
 * The component for the description of the bot feature.
 */
@Component({
  selector: 'tu-bot-desc',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './bot-desc.component.html',
})
export class BotDescComponent {

  /**
   * Holds the name of the bot.
   */
  botName: string = BOT_NAME;

  /**
   * Holds the path to the create command image for the bot.
   */
  createCommand: string = DESC_IMG_PATH + "/bot-create-command.jpg";

  /**
   * Holds the path to the executed command image for the bot.
   */
  executedCommand: string = DESC_IMG_PATH + "/bot-moin.jpg";

}
