import { Injectable } from "@angular/core";
import { Feature } from "../feature/feature";
import { AuthenticationService } from "../authentication/authentication.service";

/**
 * A service for the profile.
 */
@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    /**
     * The constructor.
     *
     * @param authenticationService The authentication service.
     */
    constructor(private authenticationService: AuthenticationService) {

    }

    /**
     * Returns the promise for the logged-in features.
     *
     * @return The promise for the logged-in features.
     */
    findLoggedInFeatures(): Promise<Feature[]> {
        return this.authenticationService.findLoggedInFeatures();
    }

    /**
     * Logs out the user from the provided feature.
     *
     * @param feature The feature.
     * @returns The promise from the request to logout.
     */
    logout(feature: Feature): Promise<Object> {
        return this.authenticationService.logoutFeature(feature);
    }

    /**
     * Logs in the user to the provided feature.
     *
     * @param feature The feature.
     */
    login(feature: Feature): void {
        this.authenticationService.login(feature);
    }

}
