import { Component, OnInit } from '@angular/core';
import { ChannelEdit } from './channel';
import { AuthenticationService } from '../authentication/authentication.service';
import { AuthenticationException } from '../generic/errors';
import { UserEdit } from '../user/user';
import { CommandsComponent } from '../command/commands.component';

/**
 * The component for the channel.
 */
@Component({
  selector: 'tu-channel',
  standalone: true,
  templateUrl: './channel.component.html',
  imports: [CommandsComponent]
})
export class ChannelComponent implements OnInit {

  /**
   * Holds the channel to display.
   */
  channel: ChannelEdit;

  /**
   * The constructor.
   *
   * @param authenticationService The authentication service.
   */
  constructor(private authenticationService: AuthenticationService) {

  }

  ngOnInit(): void {
    let user = this.authenticationService.getUser();
    if (user == null) {
      throw new AuthenticationException("No user is logged in while someone was able to go to the channel page");
    }
    let channel = user.channel;
    if (channel == null) {
      throw new AuthenticationException("The logged in user (" + user.id + ") doesn't have a channel but was able to go to the channel page");
    }
    this.channel = channel;
    this.authenticationService.subscribeToUserChange((u: UserEdit) => {
      this.channel = u.channel;
    });
  }
}

