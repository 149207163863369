import { Component } from '@angular/core';

@Component({
  selector: 'tu-home',
  templateUrl: './home.component.html',
  standalone: false
})
export class HomeComponent {

}
