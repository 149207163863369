<h1>{{header != null ? header : defaultHeader}}</h1>
<div *ngIf="wrongVersion" class="error-message">
  Die Version von dem Befehl war leider nicht korrekt.<br>
  Bitte überprüfe, dass sich nichts Entscheidendes daran geändert hat. Dann versuch es erneut.
</div>
<form name="commandsForm" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
  <table class="default-table" style="width: 100%;">
    <tr>
      <td [attr.colspan]="!(forChannel || forLinks) ? 7 : 6" class="sub-th default-table-cell">
        <div class="row">
          <div class="column">Eigene Befehle</div>
          <div class="column">
            <img (click)="createCommand()" class="button-img" style="float: right;" alt="Erstellen" [src]="addImg">
          </div>
        </div>
      </td>
    </tr>
    <tr *ngIf="ownedCommands.length > 0">
      <th id="first-column"  class="default-table-header">Name</th>
      <th id="second-column" class="default-table-header">Text</th>
      <th class="default-table-header" style="width: 10px; padding: 0 10px 0 10px;">Zähler</th>
      <th class="default-table-header" style="width: 10px; padding: 0 10px 0 10px;">Ränge</th>
      <th class="default-table-header" style="padding: 0 10px 0 10px">Öffentlich</th>
      <th *ngIf="!(forChannel || forLinks)" class="default-table-header" style="width: 10px; padding: 0 10px 0 10px;">Feature</th>
      <th class="default-table-header"></th>
    </tr>
    <tr *ngFor='let command of ownedCommands'>
      <td class="default-table-cell">
        <div class="command-output">{{command.name}}</div>
      </td>
      <td class="default-table-cell" style="max-width: 100px;">
        <div class="command-output" style="word-wrap: break-word; overflow-wrap: break-word;">{{command.text}}</div>
      </td>
      <td class="default-table-cell">
        <div *ngFor="let commandCounter of command.counters" class="command-output">
          {{commandCounter.counter.name + ": " + commandCounter.counter.currentValue}}
        </div>
      </td>
      <td class="button-cell default-table-cell">
        <span *ngFor="let userLevel of command.userLevels">
          {{getUserLevelValue(userLevel)}}<br>
        </span>
      </td>
      <td class="button-cell default-table-cell">
        <input type="checkbox" [defaultChecked]="command.public" (change)="publicChanged($event, command)">
      </td>
      <td *ngIf="!(forChannel || forLinks)" class="button-cell default-table-cell">
        {{getFeatureValue(command.feature)}}
      </td>
      <td class="button-cell default-table-cell">
        <img [src]="editImg" (click)="editCommand(command)" *ngIf="!isDeletingCommand(command)" class="button-img-centered" alt="Bearbeiten">
        <img [src]="deleteImg" (click)="delete(command)" *ngIf="!isDeletingCommand(command) && !commandHasChanged(command) && command.id != -1" class="button-img-centered" alt="Löschen">
        <img [src]="deletingImg" *ngIf="isDeletingCommand(command)" class="button-img-centered" alt="Lösche">
      </td>
    </tr>
  </table>
  <tu-not-owned-commands *ngIf="forLinks" forLinks="true" (removeRequest)="removeCommand($event)"></tu-not-owned-commands>
  <tu-not-owned-commands *ngIf="forChannel" forChannel="true" (removeRequest)="removeCommand($event)"></tu-not-owned-commands>
  <tu-not-owned-commands *ngIf="!forLinks && !forChannel" forLinks="false" forChannel="false"(removeRequest)="removeCommand($event)"></tu-not-owned-commands>
</form>
