<h1>Twitch Bot</h1>
Wenn du meinen Bot benutzen willst, musst du mir, beim Login, das Recht geben, deinen Chat als Bot-Nutzer zu betreten und <a [href]="'https://www.twitch.tv/' + botName" class="default-link" target="_blank">{{botName}}</a> in deinem Kanal als Moderator hinzufügen.
Sobald du das getan hast, kannst du Befehle für deinen Kanal erstellen. Bei diesen kannst du zusätzliche parameter verwenden:
<ul>
    <li>
        <span class="enumeration">$&#123;USER&#125;</span>: Name von dem Benutzer, welcher diesen Befehl ausgeführt hat.<br>
        <span class="note">Es gibt noch weitere bei <a class="default-link" routerLink="/desc/spotify">Spotify</a> 
            Wenn du irgendwelche Vorschläge hast, dann schreib gerne eine E-Mail an features&#64;pinkpanth3r.de.</span>
    </li>
</ul>
Diese können alle 30 Sekunden auf deinem Kanal ausgeführt werden.<br>
<br>
<img [src]="createCommand" class="img-border" alt="Befehl erstellen Beispiel"><br>
<br>
Hier siehst du, wie der Befehlt ausgeführt wird:<br>
<img [src]="executedCommand" alt="Befehl ausführen Beispiel"><br>
<ul>
    <li>Es muss immer ein ! vor dem Befehl eingegeben werden</li>
    <li>
        Das <span style="color: deeppink;">&#123;1&#125;</span> wird durch das ersetzt, was nach dem Befehl eingegeben wurde.<br>
        <span class="note">Wenn mehrere Parameter verwendet werden, dann können diese durch ein Leerzeichen getrennt dahinter hinzugefügt werden.</span>
    </li>
    <li>Das <span style="color: deeppink;">$&#123;USER&#125;</span> wird durch denjenigen ersetzt, welcher den Befehlt abgeschickt hat.</li>
</ul>
Weitere geplante möglichkeiten siehst du unter <a class="default-link" routerLink="/whats-next" target="_blank">Was kommt noch?</a><br>
Wenn dir irgendwas einfällt, was du hier gerne dabei hättest, dann schreibe gerne eine E-Mail an features&#64;pinkpanth3r.de.
