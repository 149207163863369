import { IdentifiedEntity } from "../generic/identified-entity";
import { AbstractKey } from "../generic/abstract-key";
import { Versionable } from "../generic/versionable";
import { Language } from "../generic/language";
import { Feature } from "../feature/feature";
import { ChannelEdit } from "../channel/channel";
import { CommandEdit, NotOwnedCommandDisplay } from "../command/command";
import { CounterEdit } from "../counter/counter";

/**
 * The key for a user.
 */
export class UserKey extends AbstractKey implements IdentifiedUser {

}

/**
 * The versionable key for a user.
 */
export class UserVersionableKey extends UserKey implements IdentifiedVersionableUser {

  /**
   * Holds the version.
   */
  readonly version: number;

  /**
   * The constructor.
   *
   * @param id The id.
   * @param version The version.
   */
  constructor(id: number, version: number) {
    super(id);
    this.version = version;
  }

}

/**
 * An edit object for a user.
 */
export class UserEdit extends UserVersionableKey {

  /**
   * Holds the username.
   */
  username: string;

  /**
   * Holds the token.
   */
  token: string;

  /**
   * Holds the email.
   */
  email: string;

  /**
   * Holds the language.
   */
  language: Language;

  /**
   * Holds the feature.
   */
  features: Feature[];

  /**
   * Holds the commands owned by this user.
   */
  ownedCommands: CommandEdit[];

  /**
   * Holds the commands used but not owned by this user.
   */
  notOwnedCommands: NotOwnedCommandDisplay[];

  /**
   * Holds the counters of this user.
   */
  counters: CounterEdit[];

  /**
   * Holds the channel.
   */
  channel?: ChannelEdit;

  /**
   * The constructor.
   *
   * @param id               The id.
   * @param version          The version.
   * @param username         The username.
   * @param token            The token.
   * @param email            The email.
   * @param language         The language.
   * @param features         The features.
   * @param ownedCommands    The commands owned by this user.
   * @param notOwnedCommands The commands used by this user but not owned by him/her.
   * @param counters         The counters of this user. 
   * @param channel          The channel.
   */
  constructor(id: number,
              version: number,
              username: string,
              token: string,
              email: string,
              language: Language,
              features: Feature[],
              ownedCommands: CommandEdit[],
              notOwnedCommands: NotOwnedCommandDisplay[],
              counters: CounterEdit[],
              channel?: ChannelEdit) {
    super(id, version);

    this.username = username;
    this.token = token;
    this.email = email;
    this.language = language;
    this.features = features;
    this.ownedCommands = ownedCommands;
    this.notOwnedCommands = notOwnedCommands;
    this.counters = counters;
    this.channel = channel;
  }

  /**
   * Returns a copy of the provided user.
   *
   * @returns A copy of the provided user.
   */
  public static copy(user: UserEdit): UserEdit {
    const ownedCommands: CommandEdit[] = [];
    user.ownedCommands.forEach(cmd => ownedCommands.push(cmd));
    const notOwnedCommands: NotOwnedCommandDisplay[] = [];
    user.notOwnedCommands.forEach(cmd => notOwnedCommands.push(cmd));
    const counters: CounterEdit[] = [];
    user.counters.forEach(cnt => counters.push(cnt));
    return new UserEdit(user.id,
                        user.version,
                        user.username,
                        user.token,
                        user.email,
                        user.language,
                        user.features,
                        ownedCommands,
                        notOwnedCommands,
                        counters,
                        user.channel);
  }
}

/**
 * The identified entity for users.
 */
export interface IdentifiedUser extends IdentifiedEntity {

}

/**
 * The versionable identified entity for users.
 */
export interface IdentifiedVersionableUser extends IdentifiedUser, Versionable {

}
