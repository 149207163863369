<div id="header">
    PinkPanth3rs Twitch Utils
</div>
<div id="menu">
    <div class="menu-group">
        <a id="{{getCSSIdFor('/')}}" [routerLink]="''">Startseite</a>
    </div>  
    <div *ngIf="isLoggedIn()" class="menu-group">
        <a id="{{getCSSIdFor('/profile')}}" [routerLink]="'profile'">Profil</a>
        <a id="{{getCSSIdFor('/channel')}}" [routerLink]="'channel'" *ngIf="userHasChannel()">Kanal</a>
        <a id="{{getCSSIdFor('/links')}}" [routerLink]="'links'" *ngIf="userHasLinks()">Links</a>
        <a id="{{getCSSIdFor('/commands')}}" *ngIf="userHasChannel() && userHasLinks()" [routerLink]="'commands'">Befehle</a>
        <a id="{{getCSSIdFor('/counters')}}" [routerLink]="'counters'">Counters</a>
        <a id="{{getCSSIdFor('/settings')}}" [routerLink]="'settings'">Einstellungen</a>
    </div>
    <div class="menu-group">
        <span class="menu-header">Features</span><br>
        <a id="{{getCSSIdFor('/desc/commands')}}" [routerLink]="'desc/commands'">Befehle</a>
        <a id="{{getCSSIdFor('/desc/spotify')}}" [routerLink]="'desc/spotify'">Spotify</a>
        <a id="{{getCSSIdFor('/desc/bot')}}" [routerLink]="'desc/bot'">Twitch Bot</a>
        <a id="{{getCSSIdFor('/desc/links')}}" [routerLink]="'desc/links'">Links</a>
    </div>
    <div class="menu-group">
        <a id="{{getCSSIdFor('/whats-next')}}" [routerLink]="'whats-next'">Was kommt noch?</a>
        <a id="{{getCSSIdFor('/patches')}}" [routerLink]="'patches'" >Patches</a>
    </div>
    <div class="menu-group">
        <a id="{{getCSSIdFor('/login')}}" [routerLink]="'login'" *ngIf="!isLoggedIn()">Login</a>
        <a [routerLink]="''" (click)="logout()" *ngIf="isLoggedIn()">Logout</a>
    </div>
    <div class="default-message" style="margin-top: 25px;">
        Version: {{version}}
    </div>
</div>
<div id="content">
    <router-outlet></router-outlet>
</div>
