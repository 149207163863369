<h1>Password ändern</h1>
<form #f="ngForm" name="resetPasswordForm" (ngSubmit)="f.valid" novalidate>
      <table>
        <tr>
            <td style="width: 155px;">
                <div>
                    <label for="currentPw"><b>Derzeitiges Password:</b></label>
                </div>
            </td>
            <td style="width: 175px;">
                <input type="password" name="currentPw" #cPw="ngModel" [(ngModel)]="currentPw" (input)="onChange()" [disabled]="saving" style="width: 100%;" required />
            </td>
        </tr>
        <tr>
            <td style="width: 155px;">
                <div>
                    <label for="newPwOne"><b>Neues Password:</b></label>
                </div>
            </td>
            <td style="width: 175px;">
                <input type="password" name="newPwOne" #pwOne="ngModel" [(ngModel)]="newPwOne" (input)="onChange()" [disabled]="saving"
                  tuPasswordValidator [otherInput]="pwTwo.control" style="width: 100%;" required />
            </td>
        </tr>
        <tr>
            <td style="width: 155px;"></td>
            <td style="width: 175px;">
                <input type="password" name="newPwTwo" #pwTwo="ngModel" [(ngModel)]="newPwTwo" (input)="onChange()" [disabled]="saving"
                  tuPasswordValidator [otherInput]="pwOne.control" style="width: 100%;" required />
            </td>
        </tr>
    </table>
    @if (!saving) {
        <button (click)="save()" [disabled]="!f.valid || saveError != null">Speichern</button>
        <button (click)="cancel()">Abbrechen</button>
    } @else {
        <img [src]="savingGif" class="button-loading-img" alt="Speichere">
    }
    <div *ngIf="saveError != null" class="error-message">{{ saveError }}</div>
    <div *ngIf="(pwOne.invalid || pwTwo.invalid) && (pwOne.dirty || pwOne.touched || pwTwo.dirty || pwTwo.touched)">
        <div *ngIf="pwOne.errors?.['notEqual'] || pwTwo.errors?.['notEqual']" class="error-message">
            Die eingegebenen Passwörter sind nicht gleich.
        </div>
        <div *ngIf="pwOne.errors?.['wrongMinlength'] || pwTwo.errors?.['wrongMinlength']" class="error-message">
            Das eingegebene Password ist nicht lang genug. Bitte gib mindestens 8 Zeichen ein.
        </div>
    </div>
</form>
