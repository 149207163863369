import { Component } from '@angular/core';
import { LoginService } from './login.service';
import { LoginData } from './login-data';
import { LOADING_GIF } from '../generic/img-pathes';

/**
 * The component for the login.
 */
@Component({
    selector: 'tu-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.css',
    standalone: false
})
export class LoginComponent {

    /**
     * Holds the gif to display when we try to login in at the moment.
     */
    loggingInImg: string = LOADING_GIF;

    /**
     * Holds the login data.
     */
    loginData: LoginData = new LoginData();

    /**
     * Holds if we try to login at the moment.
     */
    isLoggingIn: boolean = false;

    /**
     * The constructor.
     *
     * @param loginService The login service.
     */
    constructor(private loginService: LoginService) {

    }

    /**
     * Logs in the user with the {@link loginData login data}.
     */
    login() {
        this.isLoggingIn = true;
        this.loginData.message = "";
        this.loginService.login(this.loginData);
    }

}
