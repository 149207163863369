/**
 * An enum of all possible user levels for commands.
 */
export enum UserLevel {

    /**
     * For the owner.
     */
    OWNER = 'Ich',
    
    /**
     * For the moderators.
     */
    MODERATOR = 'Moderator',
    
    /**
     * For the VIPs.
     */
    VIP = 'VIP',
    
    /**
     * For the regulars.
     */
    REGULAR = 'Gast',
    
    /**
     * For the subscribers.
     */
    SUBSCRIBER = 'Subscriber',
    
    /**
     * For everyone.
     */
    EVERYONE = 'Jeder',
}
