
/**
 * Holds the name of the bot.
 */
// for development
// export const BOT_NAME: string = "PinkPanth3rsTestBot";

// for live server
export const BOT_NAME: string = "PinkPanth3rBot";

/**
 * Holds the current version of the app.
 */
export const VERSION: string = "2025.1.4";
