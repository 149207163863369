import { Component, OnInit } from '@angular/core';
import { WhatsNextService } from './whats-next.service';
import { WhatsNextDisplay } from './whats-next';
import { LOADING_GIF, THUMBS_DOWN, THUMBS_DOWN_FILLED, THUMBS_UP, THUMBS_UP_FILLED } from '../generic/img-pathes';
import { WhatsNextVoteEdit } from './vote/whats-next-vote';
import { NgIf } from '@angular/common';

/**
 * The component for what is next.
 */
@Component({
  selector: 'tu-whats-next',
  standalone: true,
  imports: [NgIf],
  templateUrl: './whats-next.component.html',
  styleUrl: './whats-next.component.css',
})
export class WhatsNextComponent implements OnInit {

  /**
   * Holds the gif to display when we are loading the what's nexts.
   */
  loadingImg: string = LOADING_GIF;

  /**
   * Holds the path to the thumbs up img.
   */
  thumbsUpImg: string = THUMBS_UP;

  /**
   * Holds the path to the thumbs up filled img.
   */
  thumbsUpFilledImg: string = THUMBS_UP_FILLED;

  /**
   * Holds the path to the thumbs down img.
   */
  thumbsDownImg: string = THUMBS_DOWN;

  /**
   * Holds the path to the thumbs down filled img.
   */
  thumbsDownFilledImg: string = THUMBS_DOWN_FILLED;

  /**
   * Holds the what's nexts.
   */
  whatsNexts: WhatsNextDisplay[] = [];

  /**
   * Holds if the what's nexts are loading.
   */
  loading: boolean = true;

  /**
   * Holds the what's next votes of he current user.
   */
  private whatsNextVotes: WhatsNextVoteEdit[] = [];

  /**
   * The constructor.
   *
   * @param whatsNextService The what's next service.
   */
  constructor(private whatsNextService: WhatsNextService) {

  }

  ngOnInit(): void {
    this.whatsNextService.findWhatsNexts()
      .then(whatsNexts => {
        this.whatsNexts = whatsNexts.sort((wn1, wn2) => wn1.level - wn2.level);
        this.loading = false;
      });
    this.whatsNextService.findWhatsNextVotes()
      .then(whatsNextsVotes => {
        this.whatsNextVotes = whatsNextsVotes;
      });
  }

  /**
   * Returns if the user is currenly logged in.
   * 
   * @returns true if the user is logged in, false otherwise.
   */
  isLoggedIn(): boolean {
    return this.whatsNextService.isLoggedIn();
  }

  /**
   * Returns if the what's next is voted up.
   * 
   * @param whatsNext The what's next to check.
   * @returns true if the what's next is voted up, false otherwise.
   */
  isVotedUp(whatsNext: WhatsNextDisplay): boolean {
    const vote: WhatsNextVoteEdit = this.whatsNextVotes.find((vote) => vote.whatsNext.id == whatsNext.id);
    if (vote == null) {
      return false;
    }
    return vote.doNext;
  }

  /**
   * Returns if the what's next is voted down.
   * 
   * @param whatsNext The what's next to check.
   * @returns true if the what's next is voted down, false otherwise.
   */
  isVotedDown(whatsNext: WhatsNextDisplay): boolean {
    const vote: WhatsNextVoteEdit = this.whatsNextVotes.find((v) => v.whatsNext.id == whatsNext.id);
    if (vote == null) {
      return false;
    }
    return !vote.doNext;
  }

  /**
   * Unvotes the provided what's next.
   * 
   * @param whatsNext The what's next.
   */
  unvote(whatsNext: WhatsNextDisplay): void {
    const vote: WhatsNextVoteEdit = this.whatsNextVotes.find((v) => v.whatsNext.id == whatsNext.id);
    this.whatsNextService.deleteVote(vote)
      .then(r => {
        this.whatsNextVotes = this.whatsNextVotes.filter((v) => v.whatsNext.id != whatsNext.id);
      });
  }

  /**
   * Updates the vote for the provided what's next with the do next.
   * 
   * @param whatsNext The what's next.
   * @param doNext true if this what's next shell be done next, false otherwise.
   */
  updateVote(whatsNext: WhatsNextDisplay, doNext: boolean): void {
    let vote: WhatsNextVoteEdit = this.whatsNextVotes.find((v) => v.whatsNext.id == whatsNext.id);
    if (vote != null) {
      vote.doNext = doNext;
    } else {
      vote = this.whatsNextService.createVote(whatsNext, doNext);
    }
    this.whatsNextService.saveVote(vote)
      .then((whatsNextVote) => {
        this.whatsNextVotes =  this.whatsNextVotes.filter((wnv) => (wnv.whatsNext.id != whatsNextVote.whatsNext.id));
        this.whatsNextVotes.push(whatsNextVote);
      });
  }
}
