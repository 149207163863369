import { Component, inject } from '@angular/core';
import { AuthenticationService } from './authentication/authentication.service';
import { Router } from '@angular/router';
import { Feature } from './feature/feature';
import { PreviousRouteService } from './generic/previous-route.service';
import { VERSION } from './app.basics';

/**
 * The root component.
 */
@Component({
  selector: 'tu-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  standalone: false
})
export class AppComponent {

  /**
   * Holds the previous route service.
   */
  private previousRouteService = inject(PreviousRouteService);

  /**
   * Holds the authentication service.
   */
  private authenticationService = inject(AuthenticationService);

  /**
   * Holds the router.
   */
  private router = inject(Router);

  /**
   * Holds the current version of the app.
   */
  version: string = VERSION;

  /**
   * Returns the css id for the menu item.
   *
   * @param menuItem The menu item.
   * @returns The css id for the menu item.
   */
  getCSSIdFor(menuItem: string): string {
    let urlToCheck: string;
    if (this.checkPreviousUrl()) {
      urlToCheck = this.previousRouteService.previousUrl;
    } else {
      urlToCheck = this.router.url;
    }
    return urlToCheck == menuItem ? "menu-selected" : "";
  }

  /**
   * Returns if the previous URL should be checked.
   *
   * @returns true if the previous URL should be checked, false otherwise.
   */
  private checkPreviousUrl(): boolean {
    const currentUrl = this.previousRouteService.currentUrl;
    return currentUrl.startsWith("/commands/search") ||
           currentUrl.startsWith("/commands/create") ||
           currentUrl.startsWith("/commands/edit") ||
           currentUrl.startsWith("/settings/reset-password")
  }

  /**
   * Gets called to logout the user.
   */
  logout(): void {
    this.authenticationService.logout();
  }

  /**
   * Returns if the user is logged in.
   *
   * @returns true if the user is logged in, false otherwise.
   */
  isLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  /**
   * Returns if the user has a channel.
   *
   * @returns true if the user has a channel, false otherwise.
   */
  userHasChannel(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    return this.authenticationService.getUser().channel != null;
  }

  /**
   * Returns if the user has enabled the {@link Feature TWITCH_LINKS} feature.
   *
   * @returns true if the user has enabled the twitch links feature, false otherwise.
   */
  userHasLinks(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    return this.authenticationService.hasFeature(Feature.TWITCH_LINKS);
  }
}
